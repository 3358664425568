import React from 'react';
import { lib, api, redux } from '@tlsocial/common';
import 'swiper/css';
import { WebCarousel, MobileCarousel, Carousel, CarouselOrStack } from '@/components/shared/Carousel';
import { UniEventItem } from '../uni_event/UniEventItem';

const Item = props => {
  const { uniEvent, city, isHorizontal, ...extra } = props;
  return (
    <UniEventItem
      key={`${uniEvent.is_hosted ? 'proposal' : 'pool'}_${uniEvent.id}`}
      isHorizontal={isHorizontal}
      uniEvent={uniEvent}
      city={city}
    />
  );
};

export const CarouselUniEvents = props => {
  const { uniEvents, city } = props;

  return (
    <CarouselOrStack
      swiperId="uniEvents"
      items={uniEvents}
      renderItemMobile={(uniEvent, key) => <Item key={key} uniEvent={uniEvent} city={city} isHorizontal={true} />}
      renderItemWeb={(uniEvent, key) => <Item key={key} uniEvent={uniEvent} city={city} isHorizontal={false} />}
    />
  );
};

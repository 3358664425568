import { tags } from './rtkTags';
import { apiSlice } from './apiSlice';

export const apiAdmin = apiSlice.injectEndpoints({
  endpoints: build => ({
    adminCityAction: build.mutation({
      query: body => ({ url: `admin/action/city/${body.city_id}`, method: 'PUT', body }),
    }),
    adminProfileAction: build.mutation({
      query: body => ({ url: `admin/action/user/${body.profile_id}`, method: 'PUT', body }),
      invalidatesTags: (result, error, body) => [{ type: tags.ADMIN_PROFILE, id: body.profile_id }],
    }),
    adminSelfAction: build.mutation({
      query: body => ({ url: `admin/action/self`, method: 'PUT', body }),
      invalidatesTags: [tags.USER_DATA],
    }),
    adminClubAction: build.mutation({
      query: body => ({ url: `admin/action/club/${body.club_id}`, method: 'PUT', body }),
      invalidatesTags: (result, error, body) => [
        tags.ADMIN_CLUBS,
        { type: tags.ADMIN_LOGS, objType: 'club', objId: body.club_id },
      ],
    }),
    adminPoolAction: build.mutation({
      query: body => ({ url: `admin/action/pool/${body.pool_id}`, method: 'PUT', body }),
      invalidatesTags: (result, error, body) => [
        tags.ADMIN_POOLS_UPCOMING,
        tags.ADMIN_POOLS_PAST,
        tags.ADMIN_POOL,
        { type: tags.ADMIN_POOL_EVENTS, id: body.pool_id },
        { type: tags.ADMIN_LOGS, objType: 'pool', objId: body.pool_id },
      ],
    }),
    adminEventAction: build.mutation({
      query: body => ({ url: `admin/action/event/${body.event_id}`, method: 'PUT', body }),
      invalidatesTags: (result, error, body) => [
        tags.ADMIN_EVENTS_MATCHED,
        tags.ADMIN_POOL_EVENTS,
        tags.ADMIN_EVENT,
        { type: tags.ADMIN_LOGS, objType: 'event', objId: body.event_id },
      ],
    }),
    adminAttendeeAction: build.mutation({
      query: body => ({ url: `admin/action/attendee/${body.attendee_id}`, method: 'PUT', body }),
      invalidatesTags: (result, error, body) => [{ type: tags.ADMIN_EVENT, id: body.eventId }],
    }),
    adminRegistrationAction: build.mutation({
      query: body => ({ url: `admin/action/pool_registration/${body.registration_id}`, method: 'PUT', body }),
      invalidatesTags: (result, error, body) => [
        { type: tags.ADMIN_POOL_REGISTRATIONS, id: body.poolId },
        { type: tags.ADMIN_POOL_EVENTS, id: body.poolId },
      ],
    }),
    adminLocationAction: build.mutation({
      query: body => ({ url: `admin/action/location/${body.location_id}`, method: 'PUT', body }),
      invalidatesTags: [tags.ADMIN_ALL_LOCATIONS, tags.ADMIN_FEATURED_LOCATIONS],
    }),
    adminReservationAction: build.mutation({
      query: body => ({ url: `admin/action/reservation/${body.reservation_id}`, method: 'PUT', body }),
      invalidatesTags: [tags.ADMIN_RESERVATIONS, tags.ADMIN_POOL_RESERVATIONS],
    }),
    adminAppAction: build.mutation({
      query: body => ({ url: `admin/action/app`, method: 'PUT', body }),
      invalidatesTags: [
        tags.ADMIN_APPLICANTS_TO_APPROVE,
        tags.ADMIN_APPLICANTS_RECENT,
        tags.ADMIN_APPLICANTS_ACCEPTING,
      ],
    }),
  }),

  overrideExisting: true,
});

export const {
  useAdminCityActionMutation,
  useAdminProfileActionMutation,
  useAdminSelfActionMutation,
  useAdminClubActionMutation,
  useAdminPoolActionMutation,
  useAdminEventActionMutation,
  useAdminAttendeeActionMutation,
  useAdminRegistrationActionMutation,
  useAdminRemoveAttendeeMutation,
  useAdminLocationActionMutation,
  useAdminReservationActionMutation,
  useAdminAppActionMutation,
} = apiAdmin;

import { Box, Center, Flex, HStack, IconButton, Img, Show, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { MaxContainer, MaxContentContainer } from '@/components/shared/MaxContainer';
import { Modal } from '@/components/shared/Modals';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { ButtonsMenu, DropdownMenu } from '@/components/shared/Menus';
import { Link, LinkArea } from '@/components/shared/Links';
import Button from '@/components/shared/Button';
import { links } from '@/constants';
import { useTranslation } from 'react-i18next';
import Icon from '../shared/Icon';
import { lib } from '@tlsocial/common';
import { H1 } from '../shared/Headings';
import Photo from '../shared/Photo';
import { useTypeformLink } from '@/hooks/useTypeformLink';

const HomeFeature = props => {
  const { image, icon, title, body, reverse, children } = props;

  return (
    <MaxContentContainer py={8} bg={reverse ? 'light1' : 'white'}>
      <Stack
        direction={{ base: 'column', md: reverse ? 'row-reverse' : 'row' }}
        spacing={{ base: 10, md: 14 }}
        alignItems="center">
        {image && (
          <Center>
            <Photo
              src={image}
              h={{ base: undefined, md: '250px', lg: '300px' }}
              w={{ base: undefined, md: '350px', lg: '450px' }}
              rounded={10}
            />
          </Center>
        )}

        <Box flex={1} textAlign={{ base: 'center', md: 'left' }}>
          <H1 mb={3}>
            {title}
            {/* {icon && <Icon icon={icon} color="primary.500" ml={2} />} */}
          </H1>
          {body && <Text>{body}</Text>}
          {children}
        </Box>
      </Stack>
    </MaxContentContainer>
  );
};

const styles = {};

export default HomeFeature;

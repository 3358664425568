import { api, lib } from '@tlsocial/common';
import { addMinutes, compareAsc, isAfter } from 'date-fns';
import { useSelector } from 'react-redux';
import { useUniversalEvent } from './useUniversalEvent';

export const useEventLikeGuest = event => {
  const { track, ev } = lib.useAnalytics();

  const [updateEventLike, { isLoading }] = api.useUpdateEventLikeGuestMutation();

  if (!event) return null;

  const update = async (applying, apply_withdraw, waitlist, waitlist_withdraw, apply_answer) => {
    const result = await updateEventLike({
      event_id: event.id,
      applying,
      apply_withdraw,
      waitlist,
      waitlist_withdraw,
      apply_answer,
    });
    if (!result.error) {
      const params = { id: event.id, title: event.title };
      const hit = applying
        ? ev.event_apply_request
        : apply_withdraw
        ? ev.event_apply_request_cancel
        : waitlist
        ? ev.event_apply_waitlist
        : waitlist_withdraw
        ? ev.event_apply_waitlist_cancel
        : null;
      track(hit, params);
    }
    return result;
  };

  return {
    update,
    isLoading,
  };
};

export const useEventLikeHost = eventLikeInitial => {
  const { track, ev } = lib.useAnalytics();
  const eventLikesHost = useSelector(state => state.relations.eventLikesHost);
  const [updateEventLike, { isLoading }] = api.useUpdateEventLikeHostMutation();

  const eventRequest = {
    ...eventLikeInitial,
    status: eventLikesHost[eventLikeInitial.id]?.status || eventLikeInitial.status,
  };

  const update = async newInviting => {
    const result = await updateEventLike({
      event_apply_id: eventRequest.id,
      is_inviting: newInviting,
    });
    if (!result.error) {
      const params = { id: eventRequest.event_id, title: eventRequest.event_title };
      const hit = newInviting ? ev.event_apply_invite : ev.event_apply_invite_cancel;
      track(hit, params);
    }
    return result;
  };

  return {
    eventRequest,
    update,
    isLoading,
  };
};

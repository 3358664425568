import { useState, useEffect } from 'react';

export const useFetch = (url, dependencies) => {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(null);
  useEffect(() => {
    const asyncFunction = async () => {
      setLoading(true);
      const response = await fetch(url);
      const data = await response.json();
      setValue(data);
      setLoading(false);
    };
    asyncFunction();
  }, [dependencies, url]);
  return [value, loading];
};

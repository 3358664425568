import storage from 'redux-persist/lib/storage';
import Bugsnag from '@bugsnag/js';

export const reduxStorage = storage;
export const isAndroid = false;
export const bugsnagNotify = Bugsnag.notify;
export const bugsnagSetUser = Bugsnag.setUser;
export const POLLING_INTERVAL = undefined;
export const isProduction = process.env.NODE_ENV !== 'development';
export const isReactNative = false;

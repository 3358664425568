import { lib, redux } from '@tlsocial/common';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSocialTable } from './SocialTableContext';

const MyPosthogProvider = ({ children, initialFlags }) => {
  const dispatch = useDispatch();
  const reduxFlags = useSelector(state => state.local.reduxPosthogFlags);
  const isPosthogInitialized = useRef(false);
  const { isAdminDashboard } = useSocialTable();

  const postHogKey = isAdminDashboard ? lib.env.POSTHOG_PROJECT_ADMIN_API_KEY : lib.env.POSTHOG_PROJECT_GUEST_API_KEY;

  useEffect(() => {
    if (initialFlags) {
      // Save initialFlags for other pages that may not have them
      dispatch(redux.setReduxPosthogFlags(initialFlags));
    }
  }, [initialFlags]);

  useEffect(() => {
    if (!isPosthogInitialized.current) {
      // Initialize PostHog only on the client side
      // Bootstrapping https://posthog.com/docs/feature-flags/bootstrapping
      const distinctID = Cookies.get('st_distict_id');
      const featureFlags = initialFlags || reduxFlags;
      const bootstrap = distinctID && featureFlags ? { distinctID, featureFlags, isIdentifiedID: false } : {};
      console.log('PostHog initializing', bootstrap, distinctID, featureFlags);
      posthog.init(postHogKey, {
        api_host: '/ingest',
        ui_host: 'https://us.i.posthog.com',
        bootstrap,
        // person_profiles: 'identified_only',
        autocapture: false,
        capture_pageview: false,
        advanced_disable_feature_flags: bootstrap ? true : false,
        // disable_session_recording: true,
        loaded: posthog => {
          if (process.env.NODE_ENV === 'development') posthog.debug(); // debug mode in development
          const distinctId = posthog.get_distinct_id();
          // Cookie used in getServersideProps to get the flags
          document.cookie = `st_distict_id=${distinctId}; path=/; max-age=${60 * 60 * 24 * 365}`; // Expires in 1 year
          // console.log('PostHog loaded. Distinct ID:', distinctId);
          isPosthogInitialized.current = true;
        },
      });
    }
  }, []);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};

export default MyPosthogProvider;

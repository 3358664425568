import React from 'react';
import Head from 'next/head';
import { H1 } from './Headings';

const defaultTitle = 'SocialTable - Curated Dining Experiences';
const defaultImageLarge = 'https://socialtable.app/social.png';
const defaultImageSmall = 'https://socialtable.app/social-small.png';
const defaultDesc = 'We curate intimate 4-8 person dinners designed for meaningful connections and magical moments ✨';

const MyHelmet = ({
  title: customTitle,
  description: customDescription,
  image,
  isLargeImage,
  show,
  canonicalUrl,
  ...extraProps
}) => {
  const title = customTitle || defaultTitle;
  const description = customDescription || defaultDesc;
  const imageTwitter = image || (isLargeImage ? defaultImageLarge : defaultImageSmall);
  const imageOther = image || defaultImageLarge;

  return (
    <>
      {show && <H1>{title}</H1>}
      <Head>
        {title && <title>{title}</title>}
        {title && <meta property="og:title" content={title} key="og:title" />}
        {title && <meta name="twitter:title" content={title} key="twitter:title" />}
        {imageOther && <meta property="og:image" content={imageOther} key="og:image" />}
        {imageTwitter && <meta name="twitter:image" content={imageTwitter} key="twitter:image" />}
        {description && <meta property="og:description" content={description} key="og:description" />}
        {description && <meta name="twitter:description" content={description} key="twitter:description" />}
        {description && <meta name="description" content={description} key="description" />}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        <meta name="twitter:card" content={isLargeImage ? 'summary_large_image' : 'summary'} />
      </Head>
    </>
  );
};

export default MyHelmet;

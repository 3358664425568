import { useDispatch, useSelector } from 'react-redux';
import { lib, api, redux } from '@tlsocial/common';

export const useTopic = (topic, context, onTopicPress) => {
  const { trackTopic, ev } = lib.useAnalytics();
  const dispatch = useDispatch();
  const { d_userTopics = {} } = lib.useUserData();
  const [rateTopic] = api.useRateTopicMutation();
  const [addTopic] = api.useAddTopicMutation();

  if (!topic) return null;

  const levelSelected = d_userTopics[topic.id]?.level ?? lib.TOPIC_LEVELS.NONE;
  const isSelected = topic.id in d_userTopics;
  const isLoved = levelSelected === lib.TOPIC_LEVELS.LOVE;

  const memberCount = topic.u_all + (isSelected ? 1 : 0);

  const trackChange = level => {
    if (level === lib.TOPIC_LEVELS.NONE) trackTopic(topic, ev.topic_removed);
    else if (level === lib.TOPIC_LEVELS.LIKE) trackTopic(topic, ev.topic_liked);
    else if (level === lib.TOPIC_LEVELS.LOVE) trackTopic(topic, ev.topic_loved);
  };

  const handleRateTopic = (topic, level, context) => {
    rateTopic({ ...topic, level, context });
  };

  const onPressTopic = async levelPressed => {
    const level = levelPressed === levelSelected ? lib.TOPIC_LEVELS.NONE : levelPressed;

    if (topic.id) {
      handleRateTopic(topic, level, context);
    } else {
      const result = addTopic({ name: topic.name, level, context });
    }

    if (onTopicPress) onTopicPress(topic, level);
    trackChange(level);
  };

  return { topic, onPressTopic, levelSelected, isSelected, isLoved, memberCount };
};

import { Box, Center, Flex, HStack, IconButton, Img, Show, Stack, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { MaxContainer, MaxContentContainer } from '@/components/shared/MaxContainer';
import { Modal } from '@/components/shared/Modals';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { ButtonsMenu, DropdownMenu } from '@/components/shared/Menus';
import { Link, LinkArea } from '@/components/shared/Links';
import Button from '@/components/shared/Button';
import { links } from '@/constants';
import { useTranslation } from 'react-i18next';
import Icon from '../shared/Icon';
import { lib } from '@tlsocial/common';
import { H1 } from '../shared/Headings';
import { useTypeformLink } from '@/hooks/useTypeformLink';
import { useIsClient } from '@/hooks';

export const Countdown = props => {
  const { targetTime, numberProps, ...extra } = props;

  const { days, hours, minutes, seconds } = lib.useCountdown(targetTime);
  const isClient = useIsClient();

  if (!targetTime) return null;

  const renderNumber = (label, value) => {
    return (
      <Box
        bg={lib.colors.whiteAlpha400}
        rounded={5}
        minW={'65px'}
        py={3}
        borderWidth={1}
        borderColor={lib.colors.primary100}>
        <Text color={'black'} fontWeight="bold" fontSize={[22, 26]} align="center" {...numberProps}>
          {isClient ? value : ' '}
        </Text>
        <Text fontSize={[10, 11]} opacity={0.8} textTransform="uppercase" mt={1} align="center">
          {label}
        </Text>
      </Box>
    );
  };

  return (
    <HStack {...extra}>
      {renderNumber('days', days)}
      {renderNumber('hours', hours)}
      {renderNumber('minutes', minutes)}
      {renderNumber('seconds', seconds)}
    </HStack>
  );
};

import { api, lib } from '@tlsocial/common';

export const usePoolFromId = (poolId, city) => {
  const { pools = [], isLoading: isLoadingPools } = lib.usePools(city?.id);
  const poolFound = pools.find(pool => pool.id === poolId);
  const skipDirect = isLoadingPools || poolFound || !poolId;
  const { data: poolDirect, isLoading: isLoadingDirect } = api.useGetPoolQuery(poolId, {
    skip: skipDirect,
  });
  const pool = poolFound || poolDirect;
  const isLoading = isLoadingPools || isLoadingDirect;
  return { pool, isLoading };
};

export const useEventFromId = eventId => {
  const { data: d_events = {} } = api.apiEvents.endpoints.getEventsAttending.useQueryState();
  let event = d_events[eventId];

  const { data: eventDirect, isLoading } = api.useGetEventQuery({ eventId }, { skip: event || !eventId });

  if (!event) {
    event = eventDirect;
  }

  return { event, isLoading };
};

export const useClubFromId = (clubId, city) => {
  const { data, isLoading: isLoadingClubs } = api.useGetCityClubsQuery(city?.id, { skip: !city?.id });
  const clubs = data || [];

  const clubFound = clubs.find(c => c.id === clubId);
  const skipDirect = isLoadingClubs || clubFound || !clubId;
  const { data: clubDirect, isLoading: isLoadingDirect } = api.useGetClubQuery(clubId, {
    skip: skipDirect,
  });
  const club = clubFound || clubDirect;
  const isLoading = isLoadingClubs || isLoadingDirect;
  return { club, isLoading };
};

import { lib, redux } from '@tlsocial/common';
import Button from '@/components/shared/Button';
import { events } from '@/constants';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { useAsyncFunc } from './useAsync';
// import { invalidateTagsRTK } from 'redux/rtkUpdates';

const useFormGeneral = (initialData, fieldsChanged, onFieldChange, onFieldsChanged, reset) => {
  const [formError, setFormError] = useState();
  const [fieldErrors, setFieldErrors] = useState({});
  const isChanged = Object.values(fieldsChanged).length > 0;
  const data = {
    ...initialData,
    ...fieldsChanged,
  };

  const register = field => {
    const id = field;
    const value = data[field];
    const onChange = value => onFieldChange(field, value);
    const onFocus = () => {
      const { [id]: value, ...newErrors } = fieldErrors;
      setFieldErrors(newErrors);
    };
    const errors = fieldErrors[field];
    return { id, field, value, errors, onChange, onFocus };
  };

  return {
    data,
    fieldsChanged,
    isChanged,
    onFieldChange,
    onFieldsChanged,
    reset,
    register,
    fieldErrors,
    setFieldErrors,
    formError,
    setFormError,
  };
};

export const useReduxForm = (formKey, initialData) => {
  const dispatch = useDispatch();
  const reduxData = useSelector(state => state.forms[formKey]);
  const fieldsChanged = reduxData?.fieldsChanged || {};
  const savedInitial = reduxData?.initialData;
  const initialUse = savedInitial || initialData;

  useEffectOnce(() => {
    if (initialData) {
      dispatch(redux.setInitialData({ formKey, initialData }));
    }
  });

  const onFieldChange = (field, value) => {
    dispatch(redux.setField({ formKey, field, value }));
  };

  const onFieldsChanged = updatedFields => {
    dispatch(redux.setFields({ formKey, updatedFields }));
  };

  const reset = () => {
    dispatch(redux.resetForm({ formKey }));
  };

  return useFormGeneral(initialUse, fieldsChanged, onFieldChange, onFieldsChanged, reset);
};

export const useNormalForm = initialData => {
  const [fieldsChanged, setFieldsChanged] = useState({});

  const onFieldChange = (field, value) => {
    setFieldsChanged({
      ...fieldsChanged,
      [field]: value,
    });
  };

  const onFieldsChanged = updatedFields => {
    setFieldsChanged({
      ...fieldsChanged,
      ...updatedFields,
    });
  };

  const reset = () => {
    setFieldsChanged({});
  };

  return useFormGeneral(initialData, fieldsChanged, onFieldChange, onFieldsChanged, reset);
};

export const useApiForm = (apiFunc, initialData, successFunc, saveOnChange = false) => {
  // const { track, ev } = lib.useAnalytics();
  const dispatch = useDispatch();
  const form = useNormalForm(initialData, saveOnChange);

  const submitAsync = useAsyncFunc(async (extraData = {}) => {
    // If submitting a form we first clear any existing form errors
    form.setFormError(null);
    form.setFieldErrors({});
    // Now we dispatch the action
    console.log('test', form.data);
    const data = { ...form.data, ...extraData };
    console.log('test2', data, extraData);
    const result = await apiFunc(data);
    console.log({ result });
    if (result.error) {
      // if (result.payload.error_message) {
      //   form.setFormError(result.payload.error_message);
      // }
      if (result.error.data.field_errors) {
        form.setFieldErrors(result.error.data.field_errors);
        // if (!result.payload.error_message) {
        //   form.setFormError('Please correct the issues below');
        // }
      }
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    } else {
      // track(ev.submit_form, { type: apiFunc.typePrefix });
      if (successFunc) successFunc(result.data);
    }
  });

  const submit = submitAsync.run;
  const isLoading = submitAsync.isLoading;
  const registerSubmit = { isLoading, onClick: clickData => submit() };

  useEffect(() => {
    if (saveOnChange && form.isChanged) {
      submit();
    }
  }, [saveOnChange, form.fieldsChanged, form.isChanged]);

  return { isLoading, submit, registerSubmit, ...form };
};

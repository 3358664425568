import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAsyncFunc } from './useAsync';
import { useBreakpointValue } from '@chakra-ui/react';
import { MODALS } from '@/constants';
import { useRouter } from 'next/router';

// This one doesn't trigger re-renders because of useMemo
export const useSlugRedirect = (correctSlug, link) => {
  const router = useRouter();
  const { slug } = router.query;

  useEffect(() => {
    if (slug !== correctSlug) {
      router.replace(link);
    }
  }, [slug]);
};

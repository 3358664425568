import { lib, api, redux } from '@tlsocial/common';

export const usePools = cityId => {
  const { data, ...extra } = api.useGetPoolsQuery(cityId, {
    skip: !cityId,
    pollingInterval: lib.config.POLLING_INTERVAL,
  });

  const pools = data || [];

  return {
    pools,
    ...extra,
  };
};

import { api, lib } from '@tlsocial/common';

const useUniRegistration = uniEvent => {
  const { user } = lib.useUser();
  const { city } = lib.useUserCity();
  const skipPoolRegistrations = !uniEvent?.is_pool || !city?.id || !uniEvent;
  const { data: d_poolRegistrations = {} } = api.useGetPoolRegistrationsQuery(city?.id, {
    skip: skipPoolRegistrations,
  });

  const skipEventRegistrations = uniEvent?.is_pool || !city?.id || !uniEvent;
  const { data: d_eventRegistrations = {} } = api.useGetUpcomingHostedEventRegistrationsQuery(city?.id, {
    skip: skipEventRegistrations,
  });

  if (!uniEvent) return null;

  if (uniEvent.is_pool) {
    return d_poolRegistrations[uniEvent.id];
  } else {
    return d_eventRegistrations[uniEvent.id];
  }
};

export const useUniversalEvent = uniEvent => {
  const priceObj = lib.usePriceObj(uniEvent);
  const currentTime = lib.useCurrentTimeMinutes();

  const registrationNew = useUniRegistration(uniEvent);

  // For events (not pools) we have an attendeeCurrent property that is the same as registrationNew
  // TODO this is a bit messy
  const registration = registrationNew || uniEvent?.attendeeCurrent;

  if (!uniEvent) return null;

  const slug = lib.toSlug(uniEvent.title || '', uniEvent.id);
  const cityTime = uniEvent.time_event && lib.toZoned(uniEvent.time_event, uniEvent.city_timezone);
  const cityTimeEnd = uniEvent.time_event_end && lib.toZoned(uniEvent.time_event_end, uniEvent.city_timezone);
  const cityTimeStartString = cityTime && lib.fTimeOnly(cityTime);
  const cityTimeEndString = cityTimeEnd && lib.fTimeOnly(cityTimeEnd);
  const cityTimeString = cityTimeEndString ? `${cityTimeStartString} - ${cityTimeEndString}` : cityTimeStartString;
  const photoSizes = lib.getPhotoSizes(uniEvent);
  const imageSizes = lib.getImageSizes(uniEvent.image_url);
  const photoUrls = lib.getPhotoUrls(uniEvent);
  const primaryImageUrl = photoUrls.length > 0 && !uniEvent.is_hosted ? photoUrls[0] : uniEvent.image_url;
  const imagePrimary = lib.getImageSizes(primaryImageUrl);
  const isRegistered = registration?.is_registered;
  const registrationDeadline = uniEvent.is_pool ? uniEvent.time_matching : uniEvent.time_event;
  const isPassedRegistrationDeadline = lib.isAfter(currentTime, registrationDeadline);

  const registerText = isRegistered
    ? 'Update Registration'
    : uniEvent.is_pool
    ? 'Book My Seat'
    : priceObj.amount > 0
    ? 'Purchase Ticket'
    : 'RSVP';

  return {
    ...imageSizes,
    ...photoSizes,
    imagePrimary,
    slug,
    cityTime,
    cityTimeString,
    registration,
    isRegistered,
    priceObj,
    isPassedRegistrationDeadline,
    registerText,
  };
};

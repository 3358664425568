import { apiSlice } from './apiSlice';
import { updateLocationPicksRTK } from './rtkUpdates';
import { tags } from './rtkTags';

export const apiClubs = apiSlice.injectEndpoints({
  endpoints: build => ({
    getClub: build.query({
      query: id => `/clubs/${id}`,
    }),
    getCityClubs: build.query({
      query: cityId => `/clubs/city/${cityId}`,
      providesTags: [tags.CLUBS],
    }),
    getCommunities: build.query({
      query: cityId => `/clubs/city/${cityId}/communities`,
      providesTags: [tags.COMMUNITIES],
    }),
    getProfileClubs: build.query({
      query: profileId => `/clubs/profile/${profileId}`,
    }),
  }),
  overrideExisting: true,
});

export const { useGetClubQuery, useGetCityClubsQuery, useGetCommunitiesQuery, useGetProfileClubsQuery } = apiClubs;

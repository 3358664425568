import { lib, api, redux } from '@tlsocial/common';
import { isAfter } from 'date-fns';

const filterEventsUpcoming = now => {
  return event => {
    const eventTime = new Date(event.time_event);
    return isAfter(eventTime, new Date(now));
  };
};

export const useUpcomingEvents = cityId => {
  const currentTime = lib.useCurrentTimeMinutes();
  const { data = [], ...extra } = api.useGetEventsUpcomingQuery(cityId, {
    skip: !cityId,
    pollingInterval: lib.config.POLLING_INTERVAL,
  });

  const events = data.filter(filterEventsUpcoming(currentTime));
  return { events, ...extra };
};

export const useEventsHosting = () => {
  const { user } = lib.useUser();
  const skip = !user || !user.admin_hosting_privilege;
  const { data: d_events = {} } = api.useGetEventsHostingQuery(undefined, { skip });
  const events = Object.values(d_events);

  const displayStatuses = [lib.EVENT_STATUS.LIVE, lib.EVENT_STATUS.CANCELLED];

  const past = events
    .filter(p => displayStatuses.includes(p.event_status) && lib.isBefore(p.time_event))
    .sort(lib.sortEvents);
  const upcoming = events
    .filter(p => displayStatuses.includes(p.event_status) && lib.isAfter(p.time_event))
    .sort(lib.sortEvents);
  const drafts = events.filter(p => p.event_status === lib.EVENT_STATUS.HOST_DRAFT).sort(lib.sortEvents);

  return { past, upcoming, drafts };
};

import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import Icon from './Icon';
import { MagicLink } from './Links';
import { lib } from '@tlsocial/common';

export const IconRow = props => {
  return (
    <MagicLink {...props}>
      <Flex justifyContent="center">
        <Icon icon={props.icon} boxSize={'24px'} mr={4} color={lib.colors.primary600} opacity={0.8} />
        <Flex flex={1} flexDirection="column">
          {props.header && (
            <Text fontSize="16" fontWeight="bold" mb={0.5}>
              {props.header}
            </Text>
          )}
          {props.text && (
            <Text mb={0.5} fontSize={13} opacity={0.8} {...props.textStyle}>
              {props.text}
            </Text>
          )}
          {props.textComponent}
          {props.children}
        </Flex>
      </Flex>
    </MagicLink>
  );
};

// export const IconRow2 = props => {
//   return (
//     <ChakraLink href={props.url}>
//       <Row py={2} px={basic.padding}>
//         <ChakraIcon as={props.icon} boxSize={22} color="gray.500" mr={2} />
//         <Flex flex={1} flexDirection="column">
//           {(props.header || props.text || props.textComponent) && (
//             <Text fontSize="md" mb={0.5}>
//               {props.header && (
//                 <Text fontSize="md" mb={0.5}>
//                   {props.header}
//                 </Text>
//               )}
//               {props.header && <Text>{`    `}</Text>}
//               {props.text && (
//                 <Text fontSize="sm" opacity={0.8} {...props.textStyle}>
//                   {props.text}
//                 </Text>
//               )}
//               {props.textComponent}
//             </Text>
//           )}
//           {props.children}
//         </Flex>
//         <ChakraIcon as={FaChevronRight} boxSize={5} color="gray.500" />
//       </Row>
//     </ChakraLink>
//   );
// };

import { transformAppData, constructCityUpdatesFromAPI } from './apiConstruct';
import { apiSlice } from './apiSlice';
import { tags } from './rtkTags';
import { arrayToObject } from '../library';

export const apiApp = apiSlice.injectEndpoints({
  endpoints: build => ({
    getMinimumVersions: build.query({
      query: () => `/app/minimum_versions`,
    }),
    getAppData: build.query({
      query: () => `/app/data`,
      transformResponse: transformAppData,
    }),
    trackHit: build.mutation({
      query: body => ({ url: `app/track_hit`, method: 'PUT', body }),
    }),
    trackNotificationOpen: build.mutation({
      query: body => ({ url: `app/track_notification_open`, method: 'PUT', body }),
    }),
    joinCityWaitlist: build.mutation({ query: body => ({ url: '/app/join_waitlist', method: 'PUT', body }) }),
    testError: build.query({
      query: () => `/test/error`,
    }),
    streamCreateToken: build.mutation({
      query: () => ({ url: `/stream/create_token`, method: 'PUT' }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetMinimumVersionsQuery,
  useGetAppDataQuery,
  useTrackHitMutation,
  useTrackNotificationOpenMutation,
  useJoinCityWaitlistMutation,
  useTestErrorQuery,
  useStreamCreateTokenMutation,
} = apiApp;

import { useTranslation } from 'react-i18next';

const noDecimalCountries = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];

export const getDecimalAmount = (amount, currency) => {
  if (noDecimalCountries.includes(currency)) {
    return amount;
  } else {
    return amount / 100;
  }
};

export const getStripeAmount = (amount, currency) => {
  if (noDecimalCountries.includes(currency)) {
    return amount;
  } else {
    return amount * 100;
  }
};

export const useFormatPrice = () => {
  const { t } = useTranslation();
  return (amount, currency) => {
    const isNoDecimals = noDecimalCountries.includes(currency);
    const decimalAmount = getDecimalAmount(amount, currency);

    const decimalCount = decimalAmount >= 100 || isNoDecimals ? 0 : 2;
    const minimumFractionDigits = decimalCount;
    const maximumFractionDigits = decimalCount;
    // const maximumFractionDigits = 2;
    return t(`{{amount, currency, minimumFractionDigits, maximumFractionDigits}}`, {
      amount: decimalAmount,
      currency,
      minimumFractionDigits,
      maximumFractionDigits,
    });
  };
};

export const useFormatPriceFree = () => {
  const formatPrice = useFormatPrice();
  return (amount, currency) => {
    if (amount === 0) {
      return 'Free';
    }
    return formatPrice(amount, currency);
  };
};

export const usePriceObj = uniEvent => {
  const formatPrice = useFormatPrice();

  if (!uniEvent) return null;

  const objType = uniEvent.is_pool ? 'pool' : 'event';
  const objId = uniEvent.id;
  const label = uniEvent.title;
  const amount = uniEvent?.ticket_price;
  const currency = uniEvent?.ticket_currency;
  const formatted = formatPrice(amount, currency);
  const text = amount > 0 ? `${formatted}` : 'Free';
  const priceObj = { objType, objId, label, amount, currency, formatted, text };
  return priceObj;
};

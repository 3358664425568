import { transformAppData, transformCityData, constructCityUpdatesFromAPI } from './apiConstruct';
import { apiSlice } from './apiSlice';
import { tags } from './rtkTags';
import { arrayToObject } from '../library';

export const apiCity = apiSlice.injectEndpoints({
  endpoints: build => ({
    getCityFromKey: build.query({
      query: cityKey => `/cities/key/${cityKey}`,
      providesTags: [tags.CITY],
    }),
    getCity: build.query({
      query: id => `/cities/${id}`,
      providesTags: [tags.CITY],
    }),
    getClosestCity: build.query({
      query: ipAddress => `/cities/closest/${ipAddress || ''}`,
      providesTags: [tags.CITY],
    }),
    getActiveCities: build.query({ query: () => '/cities' }),
    getCityUpdates: build.query({
      query: cityId => `/cities/${cityId}/updates`,
      transformResponse: response => constructCityUpdatesFromAPI(response?.city_updates_data, []),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCityFromKeyQuery,
  useGetCityQuery,
  useGetClosestCityQuery,
  useGetActiveCitiesQuery,
  useGetCityUpdatesQuery,
} = apiCity;

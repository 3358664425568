import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { usePathname, useSearchParams } from 'next/navigation';
import { lib, redux } from '@tlsocial/common';
import { usePageview } from './usePageview';
import { usePostHog } from 'posthog-js/react';

export const useMain = (pageName, pageNameCity) => {
  useUtmKeys();
  usePageViewTracking(pageName, pageNameCity);
};

const usePageViewTracking = (pageName, pageNameCity) => {
  const posthog = usePostHog();
  const router = useRouter();

  const { state, ...routerProps } = router.query;
  const { cityKey, ...extra } = routerProps;

  useEffect(() => {
    const params = {
      page_name_city: pageNameCity,
      page_name: pageName,
      next_route: router.route,
      cityKey,
      ...extra,
    };
    posthog.capture('$pageview', params);
  }, [pageNameCity, pageName, router.route, cityKey, JSON.stringify(extra)]);
};

const useUtmKeys = () => {
  const dispatch = useDispatch();
  const searchParams = useSearchParams();
  const router = useRouter();

  useEffect(() => {
    // console.log('useMain', searchParams.keys());
    if (searchParams.keys().length === 0) return;
    const utm_keys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
    const utm = {};
    utm_keys.forEach(key => {
      const value = searchParams.get(key);
      // console.log('value', key, value);
      if (value) {
        utm[key] = value;
      }
    });
    if (Object.keys(utm).length > 0) {
      dispatch(redux.setUtm(utm));
    }
  }, [searchParams, dispatch]);
};

// // GET Location data based on IP
// useEffect(() => {
//   fetch('http://ip-api.com/json')
//     .then(response => response.json())
//     .then(data => {
//       dispatch(setAppValue({ key: 'ipData', value: data }));
//     });
// }, []);

// useEffect(() => {
//   if (cities && ipData) {
//     const nearestDistance = 100;
//     cities.forEach((city) => {
//       const distance = utils.coord_distance(ipData.lat, ipData.lon, city.lat, city.lng);
//       if (distance < nearestDistance) {
//         setNearestCity(city);
//       }
//     });
//   }
// }, [cities, ipData]);

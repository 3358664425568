import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  // livePoolStatus: {},
};

const cityLocalSlice = createSlice({
  name: 'cityLocal',
  initialState,
  reducers: {
    resetcityLocalState: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { resetcityLocalState } = cityLocalSlice.actions;

export default cityLocalSlice.reducer;

import React from 'react';
import NextLink from 'next/link';
import { getLinkProps } from '@/utils/nextUtils';
import { lib } from '@tlsocial/common';
import { Box } from '@chakra-ui/react';
// import { usePostHog } from 'posthog-js/react';

export const MyNextLink = props => {
  const { myClickEvent, ...extraProps } = props;
  // const posthog = usePostHog();
  const { track } = lib.useAnalytics();

  const handleClick = () => {
    if (myClickEvent) {
      console.log('My Custom event');
      track(myClickEvent);
    }
  };

  return (
    <Box onClick={handleClick}>
      <NextLink {...extraProps} />
    </Box>
  );
};

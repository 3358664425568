import { apiSlice } from './apiSlice';

export const apiSSR = apiSlice.injectEndpoints({
  endpoints: build => ({
    getLocationsSSR: build.query({
      query: () => `/ssr/locations`,
    }),
    getActiveCitiesSSR: build.query({
      query: () => `/ssr/cities/active`,
    }),
    getAllCitiesSSR: build.query({
      query: () => `/ssr/cities/all`,
    }),
  }),
  overrideExisting: true,
});

import { GENDER_POLICY } from '../libBackendConstants';

export const GENDER_POLICY_OPTIONS = [
  {
    value: GENDER_POLICY.ANY,
    label: 'Any',
  },
  {
    value: GENDER_POLICY.MALE_ONLY,
    label: 'Male Only',
  },
  {
    value: GENDER_POLICY.FEMALE_ONLY,
    label: 'Female Only',
  },
];

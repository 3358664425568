import { ButtonGroup, Divider, IconButton, Img, Link as ChakraLink, Stack, Text } from '@chakra-ui/react';
import { Link } from '@/components/shared/Links';
import { MaxContainer, MaxContentContainer } from '@/components/shared/MaxContainer';
import { ButtonsMenu } from '@/components/shared/Menus';
import { links } from '@/constants';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../shared/Icon';
import Photo from '../shared/Photo';
import { lib } from '@tlsocial/common';
import { AppStoreButtons } from '../shared/AppStoreLinks';

const menuButtonProps = {
  padding: 0,
  _hover: {
    bg: 'whiteAlpha.200',
  },
  _active: {
    bg: 'whiteAlpha.100',
  },
};

export const MainFooter = props => {
  const { city } = props;
  const { t, i18n } = useTranslation();
  const linkItems = [
    { label: 'Locations', to: city ? links.CITY_OTHER_CITIES(city) : links.CITIES },
    { label: 'How it works', to: city ? links.CITY_HOW(city) : links.HOW },
    { label: t('links.faq'), to: city ? links.CITY_FAQ(city) : links.FAQ },
    { label: t('links.etiquette'), to: city ? links.CITY_ETIQUETTE(city) : links.ETIQUETTE },
    // { label: t('links.about'), to: links.ABOUT },
  ];

  return (
    <MaxContentContainer>
      <Divider />
      <Stack justify="space-between" align={['center', 'center', 'center']} py={{ base: '12', md: '16' }} spacing="6">
        <Text fontSize={28} fontWeight="bold">
          SocialTable
        </Text>

        <ButtonGroup variant="ghost">
          <IconButton
            as="a"
            href="https://instagram.com/socialtable.app"
            target="_blank"
            aria-label="Instagram"
            icon={<Icon brand icon="instagram" fontSize="30" />}
          />
          <IconButton
            as="a"
            href="https://www.linkedin.com/company/socialtable-app"
            target="_blank"
            aria-label="LinkedIn"
            icon={<Icon brand icon="linkedin" fontSize="30" />}
          />
        </ButtonGroup>

        <AppStoreButtons mt={8} />

        <ButtonsMenu
          noShowActive
          spacing="5"
          items={linkItems}
          direction="column"
          align={['center', 'center', 'center']}
          buttonProps={{ variant: 'link', color: lib.colors.primary500, minWidth: 'none', fontSize: 16 }}
        />
      </Stack>
      <Divider />
      <Stack
        pt="8"
        pb="12"
        // justify="space-between"
        // direction={{ base: 'column-reverse', md: 'row' }}
        align="center"
        spacing={5}>
        <Text fontSize="sm" align={['center', 'center', 'center']}>
          © 2024 SocialTable. All rights reserved. &nbsp; &nbsp;
          <Link to={links.PRIVACY}>Privacy</Link>
          &nbsp; &nbsp;
          <Link to={links.TERMS}>Terms</Link>
        </Text>

        {/* <ButtonsMenu
            items={[
              { isActive: i18n.language === 'en', label: 'EN', onClick: () => i18n.changeLanguage('en') },
              { isActive: i18n.language === 'ja', label: 'JA', onClick: () => i18n.changeLanguage('ja') },
            ]}
            buttonProps={menuButtonProps}
          /> */}
      </Stack>
    </MaxContentContainer>
  );
};

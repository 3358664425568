export const slugify = str => {
  if (!str) return '';
  return str
    .trim()
    .toLowerCase()
    .replace(/[\W_]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

export const toSlug = (str, id) => {
  return `${slugify(str)}-${id}`;
};

export const idFromSlug = str => {
  if (str) {
    const parts = str.split('-');
    return parseInt(parts[parts.length - 1]);
  }
};

export const pluralize = count => {
  return count > 1 ? 's' : '';
};

export const titleCase = string => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

// export const toTitleCase = (str, lower = false) => {
//   return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
// };

export const toUpperFirst = str => {
  if (str) return str[0].toUpperCase() + str.slice(1);
};

export const substitutePlaceholders = (template, replacements) => {
  let result = template || '';

  for (let placeholder in replacements) {
    let replacement = replacements[placeholder];
    result = result.replace(new RegExp(placeholder, 'g'), replacement);
  }

  return result;
};

export const condenseWhitespace = str => {
  return str.replace(/\n|\r/g, ' ').trim();
};

export const templateReplace = (template, params) => {
  return template.replace(/\{(\w+)\}/g, (_, key) => params[key] || `{${key}}`);
};

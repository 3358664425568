import { tags } from './rtkTags';
import { arrayToObject, arrayToObjectValues } from '../library';
import { constructEventsFromAPI } from './apiConstruct';
import { apiSlice } from './apiSlice';

export const apiEvents = apiSlice.injectEndpoints({
  endpoints: build => ({
    getEventHostInvite: build.query({
      query: ({ eventId, code }) => `/events/${eventId}/host_invite/${code}`,
      transformResponse: response => {
        return constructEventsFromAPI(response.events_data, response.relations)[0];
      },
    }),
    getEvent: build.query({
      query: ({ eventId, code }) => {
        if (code) {
          return `/events/${eventId}?code=${code}`;
        } else {
          return `/events/${eventId}`;
        }
      },
      transformResponse: response => {
        return constructEventsFromAPI(response.events_data, response.relations)[0];
      },
    }),
    getProfileEvents: build.query({
      query: profileId => `/events/profile/${profileId}/attended`,
      transformResponse: response => {
        return constructEventsFromAPI(response.events_data, response.relations);
      },
    }),
    getRecentFeaturedEvents: build.query({
      query: cityId => `/events/city/${cityId}/featured_recently`,
      transformResponse: response => {
        return constructEventsFromAPI(response.events_data, response.relations);
      },
    }),
    getClubEvents: build.query({
      query: clubId => `/events/club/${clubId}/past`,
      transformResponse: response => {
        return constructEventsFromAPI(response.events_data, response.relations);
      },
    }),
    getEventsAttending: build.query({
      query: () => `/events/attending`,
      providesTags: [tags.EVENTS_ATTENDING],
      transformResponse: response => {
        const events = constructEventsFromAPI(response.events_data, response.relations);
        return {
          d_events: arrayToObject(events, 'id'),
        };
      },
    }),
    getEventsHosting: build.query({
      query: () => `/events/hosting`,
      transformResponse: response =>
        arrayToObject(constructEventsFromAPI(response.events_data, response.relations), 'id'),
      providesTags: [tags.EVENTS_HOSTING],
    }),
    getEventsUpcoming: build.query({
      query: cityId => `/events/city/${cityId}/upcoming_proposals`,
      transformResponse: response => constructEventsFromAPI(response.events_data, response.relations),
      providesTags: [tags.EVENTS_UPCOMING],
    }),
    getUpcomingHostedEventRegistrations: build.query({
      query: cityId => `/events/registrations/city/${cityId}`,
      transformResponse: regs => arrayToObject(regs, 'event_id'),
      providesTags: [tags.EVENT_REGISTRATIONS],
    }),
    rateExperience: build.mutation({
      query: body => ({ url: `events/${body.event_id}/rate_experience`, method: 'PUT', body }),
      invalidatesTags: [tags.EVENTS_ATTENDING],
    }),
    rateExperienceUsers: build.mutation({
      query: body => ({ url: `events/${body.event_id}/rate_experience_users`, method: 'PUT', body }),
    }),
    rateExperienceConnect: build.mutation({
      query: body => ({ url: `events/${body.event_id}/rate_experience_connect`, method: 'PUT', body }),
    }),
    cancelAttendance: build.mutation({
      query: body => ({ url: `events/${body.event_id}/cancel_attendance`, method: 'PUT', body }),
      invalidatesTags: [tags.EVENTS_ATTENDING, tags.EVENT_REGISTRATIONS, tags.EVENTS_HOSTING],
    }),
    hasSeenEvent: build.mutation({
      query: body => ({ url: `events/${body.event_id}/set_event_seen`, method: 'PUT', body }),
    }),
    editHostedEvent: build.mutation({
      query: body => ({ url: `/events/update_proposal`, method: 'POST', body }),
      invalidatesTags: [tags.EVENTS_HOSTING, tags.EVENTS_UPCOMING, tags.EVENTS_ATTENDING],
    }),
    updateEventRegistration: build.mutation({
      query: body => ({ url: `/events/${body.event_id}/proposal_update_registration`, method: 'PUT', body }),
    }),
    updateEventLikeGuest: build.mutation({
      query: body => ({ url: `/events/${body.event_id}/update_request`, method: 'PUT', body }),
    }),
    updateEventLikeHost: build.mutation({
      query: body => ({ url: `/event_requests/${body.event_apply_id}/update_invited`, method: 'PUT', body }),
    }),
    updateEventWaitlist: build.mutation({
      query: body => ({ url: `/events/${body.event_id}/update_waitlist`, method: 'PUT', body }),
    }),
    overrideExisting: true,
  }),
});

export const {
  useGetEventHostInviteQuery,
  useGetEventQuery,
  useGetProfileEventsQuery,
  useGetRecentFeaturedEventsQuery,
  useGetClubEventsQuery,
  useGetEventsAttendingQuery,
  useRateExperienceMutation,
  useRateExperienceUsersMutation,
  useRateExperienceConnectMutation,
  useCancelAttendanceMutation,
  useHasSeenEventMutation,
  useGetEventsHostingQuery,
  useGetEventsUpcomingQuery,
  useGetUpcomingHostedEventRegistrationsQuery,
  useEditHostedEventMutation,
  useUpdateEventRegistrationMutation,
  useUpdateEventLikeGuestMutation,
  useUpdateEventLikeHostMutation,
  useUpdateEventWaitlistMutation,
} = apiEvents;

import { undoPatchOnFail, updateUserTopicRTK, updateUserRTK } from './rtkUpdates';
import { setEditTopicsNeedsRefresh } from '../redux/localSlice';
import { apiSlice } from './apiSlice';
import { tags } from './rtkTags';

const onQueryRateTopic = async (topic, { dispatch, queryFulfilled }) => {
  // Optimistic update
  const patchResult = dispatch(updateUserTopicRTK(topic));
  undoPatchOnFail(patchResult, queryFulfilled);
};

export const apiTopics = apiSlice.injectEndpoints({
  endpoints: build => ({
    getTopicsSearch: build.query({
      query: ({ term, cityId }) => `/topics/search/${term}/${cityId}`,
    }),
    getTopicsPopular: build.query({
      query: cityId => `/topics/popular/${cityId || ''}`,
    }),
    getTopicsFeatured: build.query({
      query: cityId => `/topics/featured/${cityId || ''}`,
    }),
    rateTopic: build.mutation({
      query: body => ({ url: `topics/${body.id}/update_level`, method: 'PUT', body }),
      onQueryStarted: onQueryRateTopic,
      invalidatesTags: [tags.CLUBS],
    }),
    rateClub: build.mutation({
      query: body => ({ url: `topics/${body.id}/update_level`, method: 'PUT', body }),
      onQueryStarted: onQueryRateTopic,
      invalidatesTags: [tags.COMMUNITY_MEMBERS, tags.SUGGESTED, tags.CLUBS],
    }),
    addTopic: build.mutation({
      query: body => ({ url: `/topics/add`, method: 'PUT', body }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          const { data: topic } = await queryFulfilled;
          // Insert the new topic into the store
          dispatch(updateUserTopicRTK(topic));
          // Refresh the my interests screen
          dispatch(setEditTopicsNeedsRefresh(true));
        } catch (error) {}
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetTopicsSearchQuery,
  useGetTopicsPopularQuery,
  useGetTopicsFeaturedQuery,
  useRateTopicMutation,
  useRateClubMutation,
  useAddTopicMutation,
} = apiTopics;

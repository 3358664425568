import { Show } from '@chakra-ui/react';
import * as React from 'react';
import { useSocialTable } from '../main/SocialTableContext';

const ShowCrawlerFriendly = props => {
  const { isCrawler } = useSocialTable();
  const { children, ...extra } = props;

  if (isCrawler) {
    return children;
  } else {
    return <Show {...extra}>{children}</Show>;
  }
};

export default ShowCrawlerFriendly;

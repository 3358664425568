import React, { useEffect, useState } from 'react';
import { lib, api, redux } from '@tlsocial/common';
import { Box, Stack, Show, Hide } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useIsClient } from '@/hooks';
import { ReverseContainerMargin } from './MaxContainer';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const CustomSwiper = props => {
  const { swiperId, items, ...extra } = props;
  const storageKey = `swiperIndex_${swiperId}`;
  const savedIndex = parseInt(sessionStorage.getItem(storageKey), 10) || 0;

  const handleSlideChange = swiper => {
    sessionStorage.setItem(storageKey, swiper.activeIndex);
  };

  return (
    <Swiper initialSlide={savedIndex} onSlideChange={handleSlideChange} centeredSlides={true} {...extra}>
      {items.map((item, key) => (
        <SwiperSlide key={key}>{item}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export const MobileCarousel = props => {
  const { items, ...extra } = props;

  const isClient = useIsClient();

  if (isClient) {
    return (
      <Box alignSelf="stretch">
        <ReverseContainerMargin>
          <Box rounded={10}>
            <CustomSwiper
              items={items}
              style={{
                width: '100%',
                paddingLeft: '40px',
                paddingRight: '40px',
              }}
              spaceBetween={'20px'}
              slidesPerView={1}
              {...extra}
            />
          </Box>
        </ReverseContainerMargin>
      </Box>
    );
  }
};

export const WebCarousel = props => {
  const { items, ...extra } = props;

  const isClient = useIsClient();

  if (isClient) {
    return (
      <Box overflow="hidden" rounded={10} alignSelf="stretch">
        <CustomSwiper
          items={items}
          pagination={true}
          modules={[Pagination]}
          grabCursor={true}
          slidesPerView="auto"
          spaceBetween={'2px'}
          navigation={true}
          breakpoints={{
            768: {
              slidesPerView: 2,
              centeredSlides: false,
            },
            1000: {
              slidesPerView: 3,
              centeredSlides: true,
            },
          }}
          {...extra}
        />
      </Box>
    );
  }
};

export const Carousel = props => {
  const { items, renderItemWeb, renderItemMobile, ...extra } = props;

  return (
    <>
      <Show below="md">
        <MobileCarousel loop={true} items={items.map(renderItemMobile)} {...extra} />
      </Show>
      <Hide below="md">
        <WebCarousel loop={true} items={items.map(renderItemWeb)} {...extra} />
      </Hide>
    </>
  );
};

export const CarouselOrStack = props => {
  const { items, renderItemWeb, renderItemMobile, ...extra } = props;

  return (
    <>
      <Show below="md">
        <MobileCarousel
          items={items.map(renderItemMobile)}
          style={{
            width: '100%',
            paddingLeft: '20px',
            paddingRight: '60px',
          }}
          {...extra}
        />
      </Show>
      <Hide below="md">
        <Stack spacing={5} align="stretch">
          {items.map(renderItemWeb)}
        </Stack>
      </Hide>
    </>
  );
};

// import { lib, api, redux } from '@tlsocial/common';
import { env } from '../libEnvironment';
import { ev } from './analyticsEvents';
import { usePostHog } from 'posthog-js/react';

export const useAnalyticsPlatform = () => {
  const posthog = usePostHog();

  const track = (name, params) => {
    posthog?.capture(name, params);
    // mixpanel.track(name, params);
  };

  const trackPageview = url => {
    posthog?.capture('$pageview');
    // posthog?.capture('$pageview', {
    //   $current_url: url,
    // });
    // mixpanel.track_pageview({ page });
  };

  // const register = params => {
  //   mixpanel.register(params);
  // };

  const identifyUser = (userId, params) => {
    posthog?.identify(userId.toString(), params);
  };

  const clearUser = () => {
    posthog?.reset();
  };

  return {
    // register,
    track,
    trackPageview,
    identifyUser,
    clearUser,
    ev,
  };
};

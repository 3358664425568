import { env } from './libEnvironment';
import { EVENT_TYPE_DATA } from './libEventType';
import { images as platformImages } from '../platform/initPlatform';
import { substitutePlaceholders } from './libString';

export const images = platformImages;

export const getImageSize = (url, size) => {
  return substitutePlaceholders(url, { '{size}': size });
};

export const getImageSizes = url => {
  return {
    imageSmall: getImageSize(url, 'small'),
    imageMedium: getImageSize(url, 'medium'),
    imageLarge: getImageSize(url, 'large'),
    imageXL: getImageSize(url, 'xlarge'),
  };
};

export const getUserImageUrlSimple = (userId, size) => `${env.MEDIA_URL}/users/${userId}_${size}.jpg`;

export const getProfileImageSource = (profile, size) => {
  // console.log('aaaa', profile.image_url);
  if (profile.image_url) {
    return getImageSize(profile.image_url, size);
  } else {
    return images.blankProfile;
  }
};

export const getPhotoUrls = obj => {
  const photos = Object.values(obj.photos_json || {});
  return photos.map(photo => {
    return `${env.MEDIA_URL}/photos/${obj.model_name}/${obj.id}/${photo.key}_{size}.jpg`;
  });
};

export const getPhotoSizes = obj => {
  const urls = getPhotoUrls(obj);
  return {
    photosSmall: urls.map(url => getImageSize(url, 'small')),
    photosMedium: urls.map(url => getImageSize(url, 'medium')),
    photosLarge: urls.map(url => getImageSize(url, 'large')),
    photosXL: urls.map(url => getImageSize(url, 'xlarge')),
  };
};

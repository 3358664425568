import { lib, api, redux } from '@tlsocial/common';

// export const getTopicsOnProfileItem = (profile, user, topicSets) => {
//   if (profile.id === user.id) {
//     return [getTopicsLove(profile.user_topics).slice(0, 10), []];
//   } else {
//     const topicsMutualLove = getTopicsLove(profile.user_topics).filter(g => isTopicLove(g, topicSets));
//     const topicsOverlapping = profile.user_topics.filter(
//       g => isTopicSelected(g, topicSets) && !topicsMutualLove.find(gLove => gLove.id === g.id),
//     );
//     const topicsTheyLove = getTopicsLove(profile.user_topics)
//       .filter(g => !isTopicSelected(g, topicSets))
//       .slice(0, 10);
//     return [topicsMutualLove, [...topicsOverlapping, ...topicsTheyLove]];
//   }
// };

// export const getClubsOnProfile = (profileClubs, userTopics) => {

// }

export const getTopicsOnProfile = (profileTopics, userTopics) => {
  const topicsFiltered = profileTopics.filter(t => !t.is_for_club);
  return sortTopicsProfileScreen(topicsFiltered, userTopics);
};

export const sortMyTopics = topics => {
  return topics.sort((a, b) => {
    if (a.level !== b.level) {
      return a.level > b.level ? -1 : 1;
    } else {
      return a.name < b.name ? -1 : 1;
    }
  });
};

export const getTopicsLove = topics => {
  return topics.filter(g => g.level === lib.TOPIC_LEVELS.LOVE);
};

export const getTopicsLike = topics => {
  return topics.filter(g => g.level === lib.TOPIC_LEVELS.LIKE);
};

export const findTopic = (topic, userTopics) => {
  return userTopics.find(g => g.id === topic.id);
};

export const isTopicSelected = (topic, topicSets) => {
  return topicSets.selected.has(topic.id);
};

export const isTopicLove = (topic, topicSets) => {
  return topicSets.love.has(topic.id);
};

export const isTopicLike = (topic, topicSets) => {
  return topicSets.like.has(topic.id);
};

export const getTopicSelectionLevel = (topic, userTopics = []) => {
  const userTopic = findTopic(topic, userTopics);
  return userTopic ? userTopic.level : lib.TOPIC_LEVELS.NONE;
};

export const addTopic = (topics, newTopic) => {
  if (!findTopic(newTopic, topics)) {
    topics.push(newTopic);
    return sortTopicsByPopular(topics);
  }
  return topics;
};

export const updateUserTopics = (topics, newTopic) => {
  // console.log({ newTopic });
  // Remove new topic by id or name if exists
  const newTopics = topics.filter(g => g.id !== newTopic.id);
  // Add new topic if user likes or loves
  if (newTopic.level !== lib.TOPIC_LEVELS.NONE) {
    newTopics.push(newTopic);
  }
  // console.log(topics.length, newTopics.length);
  return newTopics;
};

export const sortTopicsByPopular = topics => {
  [...topics].sort((a, b) => (a.u_all > b.u_all ? -1 : 1));
  return topics;
};

export const sortTopicsProfileScreen = (topics, userTopics) => {
  return [...topics].sort((a, b) => {
    const aSelection = getTopicSelectionLevel(a, userTopics);
    const bSelection = getTopicSelectionLevel(b, userTopics);
    if (aSelection !== bSelection) {
      return aSelection > bSelection ? -1 : 1;
    } else {
      return a.name < b.name ? -1 : 1;
    }
  });
};

export const sortAttributesProfilesScreen = attributes => {
  return [...attributes].sort((a, b) => {
    if (a.isSelected !== b.isSelected) {
      return a.isSelected > b.isSelected ? -1 : 1;
    } else {
      return a.label < b.label ? -1 : 1;
    }
  });
};

import React from 'react';
import * as c from '@/constants/envConstants';
import { lib, api, redux } from '@tlsocial/common';
import { Box, Flex, GridItem, HStack, Image, SimpleGrid, Stack, Text, Wrap } from '@chakra-ui/react';
import { links, linksTo } from '@/constants';
import { LinkArea } from '@/components/shared/Links';
import Icon from '@/components/shared/Icon';
import LocationItem from './LocationItem';
import Button from '../shared/Button';
import { H3 } from '../shared/Headings';

const MAX_SHOW_LOCATIONS = 2;

const LocationsSidebar = props => {
  const { locations, city } = props;

  return (
    <Box>
      <Flex alignItems="center" mb={5}>
        <Box>
          {city && locations?.length > MAX_SHOW_LOCATIONS && (
            <Button to={links.CITY_RESTAURANTS(city)} variant="ghost" size="sm" label="See all" w="full" ml="auto" />
          )}
        </Box>
      </Flex>

      {/* {locations?.length > 0 && (
        <Wrap spacing={6}>
          {locations.slice(0, MAX_SHOW_LOCATIONS).map((location, i) => (
            <LocationItem key={location.id} location={location} />
          ))}
        </Wrap>
      )} */}

      <SimpleGrid columns={{ base: 1, sm: 2, md: 2, lg: 2 }} spacing={7}>
        {locations.slice(0, MAX_SHOW_LOCATIONS).map((location, i) => (
          <GridItem key={location.id}>
            <LocationItem isDarkBg location={location} />
          </GridItem>
        ))}
      </SimpleGrid>

      {city && locations?.length > MAX_SHOW_LOCATIONS && (
        <Button
          mt={6}
          to={links.CITY_RESTAURANTS(city)}
          variant="ghost"
          label={`See all ${locations?.length} Locations`}
          w="full"
          ml="auto"
        />
      )}
    </Box>
  );
};

export default LocationsSidebar;

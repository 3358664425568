import { sortRestaurants } from './apiConstruct';
import { TOPIC_LEVELS, MEET_RATING } from '../library';
import { apiSlice } from './apiSlice';

export const undoPatchOnFail = (patchResult, queryFulfilled) => {
  queryFulfilled.catch(() => patchResult.undo());
};

export const updateUserRTK = user => {
  return apiSlice.util.updateQueryData('getUser', undefined, state => {
    Object.assign(state, user);
  });
};

export const updateCardsRTK = paymentMethods => {
  return apiSlice.util.updateQueryData('getUserData', undefined, state => {
    state.paymentMethods = paymentMethods;
  });
};

export const updateUserTopicRTK = topic => {
  return apiSlice.util.updateQueryData('getUserData', undefined, state => {
    if (topic.level === TOPIC_LEVELS.NONE) {
      delete state.d_userTopics[topic.id];
    } else {
      state.d_userTopics[topic.id] = topic;
    }
  });
};

export const addBellRTK = bell => {
  return apiSlice.util.updateQueryData('getUserData', undefined, state => {
    state.bells = [bell, ...state.bells];
  });
};

// export const updateAttendeeRTK = attendee => {
//   return apiSlice.util.updateQueryData('getEventsAttending', undefined, state => {
//     const eventId = attendee.event_id;
//     state.d_events[eventId].attendeeCurrent = attendee;
//   });
// };

export const updateLocationPicksRTK = data => {
  return apiSlice.util.updateQueryData('getMyLocationPicks', data.location.city_id, state => {
    const newState = sortRestaurants(
      data.is_favorite ? [data.location, ...state] : state.filter(res => res.id !== data.location.id),
    );
    return newState;
  });
};

export const updateProfilesMeetStarRTK = (dispatch, cityId, profile, rating, prevRating) => {
  if (cityId === profile.city_id) {
    dispatch(updateProfilesMeetRTK(cityId, profile, rating, prevRating));
    dispatch(updateProfilesStarredRTK(cityId, profile, rating, prevRating));
  }
};

export const updateProfilesMeetRTK = (cityId, profile, rating, prevRating) => {
  console.log({ rating, prevRating });
  return apiSlice.util.updateQueryData('getProfilesMeet', cityId, state => {
    const profiles = state.profiles.filter(p => p.id !== profile.id);
    const prevMeet = prevRating >= MEET_RATING.MEET;
    const nowMeet = rating >= MEET_RATING.MEET;
    if (prevMeet && !nowMeet) {
      state.profiles = profiles;
      state.count--;
    } else if (nowMeet && !prevMeet) {
      state.profiles = [profile, ...profiles];
      state.count++;
    } else if (nowMeet && prevMeet) {
      state.profiles = [profile, ...profiles];
    }
  });
};

export const updateProfilesStarredRTK = (cityId, profile, rating, prevRating) => {
  return apiSlice.util.updateQueryData('getProfilesStarred', cityId, state => {
    const profiles = state.profiles.filter(p => p.id !== profile.id);
    const prevMeet = prevRating >= MEET_RATING.PRIORITY;
    const nowMeet = rating >= MEET_RATING.PRIORITY;
    if (prevMeet && !nowMeet) {
      state.profiles = profiles;
      state.count--;
    } else if (nowMeet && !prevMeet) {
      state.profiles = [profile, ...profiles];
      state.count++;
    } else if (nowMeet) {
      state.profiles = [profile, ...profiles];
    }
  });
};

import React from 'react';
import * as c from '@/constants/envConstants';
import { lib, api, redux } from '@tlsocial/common';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { linksTo } from '@/constants';
import { LinkArea } from '@/components/shared/Links';
import Icon from '@/components/shared/Icon';

const LocationItem = props => {
  const { location, isDarkBg } = props;
  const xLocation = lib.useLocation(location);

  // const bgColor = isDarkBg ? lib.colors.whiteAlpha200 : 'white';
  // const hoverColor = isDarkBg ? lib.colors.whiteAlpha300 : lib.colors.alpha50;

  return (
    <LinkArea
      {...linksTo.LOCATION(location)}
      h="full"
      bg={lib.colors.alpha50}
      hoverColor={lib.colors.alpha100}
      borderWidth={1}
      rounded="10"
      overflow="hidden">
      <Box h="150" w="full">
        {xLocation.imageLarge && <Image h="full" w="full" objectFit="cover" src={xLocation.imageLarge} alt="" />}
      </Box>
      <Box p={5}>
        <Flex alignItems={'flex-start'}>
          <Box flex={1}>
            <Box fontSize={17} fontWeight="bold" mb={1}>
              {location.name}
            </Box>
            <Box fontSize={13}>
              <Text whiteSpace="pre-wrap">{xLocation.cuisineDetails}</Text>
            </Box>
          </Box>
          <Flex mt="3px" alignItems={'center'}>
            {location.count_fav > 0 && (
              <Text fontSize={13} mr={1}>
                {location.count_fav}
              </Text>
            )}
            <Icon icon="heart" color="primary.500" />
          </Flex>
        </Flex>
        <Text mt={2} fontSize={15} opacity={0.8} noOfLines={2}>
          {location.desc}
        </Text>
      </Box>
    </LinkArea>
  );
};

export default LocationItem;

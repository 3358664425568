import React from 'react';
import { buildQueryString } from '@/utils';
import { useSelector } from 'react-redux';
import { useIsClient } from '@/hooks';

export const useTypeformLink = city => {
  let utm = useSelector(state => state.local.utm);

  if (!utm?.utm_source) {
    const utm_source = city ? `website-${city.name.toLowerCase()}` : 'website';
    utm = { ...utm, utm_source };
  }

  const utmParams = buildQueryString(utm);

  const baseUrl = `https://socialtable.typeform.com/apply`;
  const url = utmParams ? `${baseUrl}?${utmParams}` : baseUrl;

  return url;
};

export const uniqueById = arr => {
  const unique = arr.reduce((acc, current) => {
    if (!acc.some(item => item.id === current.id)) {
      acc.push(current);
    }
    return acc;
  }, []);
  return unique;
};

export const sortObjectsAlphabetically = (arr, sortFunc) => {
  return arr.sort((a, b) => {
    if (sortFunc(a) < sortFunc(b)) {
      return -1;
    }
    if (sortFunc(a) > sortFunc(b)) {
      return 1;
    }
    return 0;
  });
};

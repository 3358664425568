import React, { useEffect, useState } from 'react';
import MyHelmet from '@/components/shared/MyHelmet';
import { lib, api, redux } from '@tlsocial/common';
import Spinner from '@/components/shared/Spinner';
import { MaxContainer } from '@/components/shared/MaxContainer';
import { H1, H2, H3 } from '@/components/shared/Headings';
import ContentLoading from '@/components/shared/ContentLoading';
import { useRouter } from 'next/router';
import * as c from '@/constants';
import { IconRow } from '@/components/shared/IconRow';
import Button from '@/components/shared/Button';
import LocationItem from '@/components/locations/LocationItem';
import { Avatar, AvatarList } from '@/components/people/Avatar';
import HowItWorksContainer from '@/components/shared/HowItWorksContainer';
import { Box, Center, Flex, Text, Stack, StackDivider, Show, Wrap, HStack } from '@chakra-ui/react';
import { links } from '@/constants';
import { Link } from '@/components/shared/Links';
import PhotoHeader from '@/components/shared/PhotoHeader';
import ContentTemplate from '@/components/content/ContentTemplate';
import { getEventProps, getEventPaths } from '@/utils';
import LocationsSidebar from '@/components/locations/LocationsSidebar';
import { InviteBox } from '@/components/shared/OpenInviteBox';
import Photo from '@/components/shared/Photo';
import Icon from './Icon';

export const appleStoreUrl = 'https://apps.apple.com/us/app/socialtable/id1619902829';
export const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.tlsocial.app';

export const useOS = () => {
  const [os, setOS] = useState(null);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator?.userAgentData?.platform || window.navigator.platform;
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (iosPlatforms.indexOf(platform) !== -1) {
      setOS('ios');
    } else if (/Android/.test(userAgent)) {
      setOS('android');
    } else {
      setOS('other');
    }
  }, []);

  return os;
};

export const useStoreUrl = () => {
  const os = useOS();
  return os === 'android' ? playStoreUrl : os === 'ios' ? appleStoreUrl : null;
};

export const AppStoreButtons = props => {
  const { height = '40px', ...extraProps } = props;
  const os = useOS();
  return (
    <Wrap spacing={3} justify="center" {...extraProps}>
      {os !== 'android' && (
        <a href={appleStoreUrl}>
          <Photo src="/assets/img/app-apple2.png" h={height} alt="" />
        </a>
      )}
      {os !== 'ios' && (
        <a href={playStoreUrl}>
          <Photo src="/assets/img/app-android2.png" h={height} alt="" />
        </a>
      )}
    </Wrap>
  );
};

export const AppStoreIcons = props => {
  return (
    <HStack
      pt={5}
      spacing={4}
      align="baseline"
      divider={<StackDivider borderColor={lib.colors.primary900} opacity={0.5} />}>
      <Link py={2} color={lib.colors.primary900} href={appleStoreUrl}>
        <Icon brand solid icon="apple" fontSize="34px" />
      </Link>
      <Link py={2} color={lib.colors.primary900} href={playStoreUrl}>
        <Icon brand solid icon="google-play" fontSize="28px" />
      </Link>
    </HStack>
  );
};

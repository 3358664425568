import React from 'react';
import * as c from '@/constants';
import { lib, api, redux } from '@tlsocial/common';
import { Box, HStack, Img, Stack, Wrap } from '@chakra-ui/react';
import Squircle from '@/components/shared/Squircle';
import { Link } from '../shared/Links';
import Photo from '../shared/Photo';
import { images } from '@tlsocial/common/library';

// const SQUIRCLE_CORNER_MULTIPLIER = 0.3;

export const Avatar = props => {
  const { profile, profileId, size, mr, borderWidth, borderColor, ...extra } = props;

  const imgSize = size > 800 ? 'xlarge' : size > 300 ? 'large' : size > 150 ? 'medium' : 'small';
  const source = profile ? lib.getProfileImageSource(profile, imgSize) : lib.getUserImageUrlSimple(profileId, imgSize);

  return (
    <Box
      mr={mr}
      borderRadius={1000}
      minW={`${size}px`}
      overflow="hidden"
      borderWidth={borderWidth}
      borderColor={borderColor}>
      <Photo
        src={source}
        alt=""
        minW={`${size}px`}
        boxSize={`${size}px`}
        borderRadius={1000}
        fallbackSrc={images.blankProfile}
        {...extra}
      />
    </Box>
  );
};

export const AvatarList = props => {
  const { profiles, size = 60, noOverlap = false, bgColor, ...extra } = props;

  const marginRightPx = noOverlap ? 0 : -Math.round(size / 4);
  const spacing = noOverlap ? 1 : 0;

  const components = profiles.slice().map((profile, index) => (
    <Box key={profile.id} zIndex={profiles.length - index}>
      <Avatar
        profile={profile}
        size={size}
        mr={`${marginRightPx}px`}
        borderWidth={1}
        borderColor={bgColor || 'white'}
      />
    </Box>
  ));

  return (
    <Wrap spacing={spacing} direction="row" justify="flex-start" pr={`${-marginRightPx}px`} {...extra}>
      {components}
    </Wrap>
  );
};

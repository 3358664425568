export const HOSTING_MIN_HOURS_AFTER_MATCHING = 6;
export const MIN_INVITE_CODE_LENGTH = 3;

export const ATTRIBUTE_TYPES = {
  GOALS: 2,
  EXPERIENCE: 1,
  INTERESTS: 5,
  VALUES: 3,
  BELIEFS: 4,
};

export const ATTRIBUTE_SUB_TYPES = {
  GENERAL: 1,
  ACTIVITIES: 2,
  SPORTS: 3,
  PROFESSIONAL: 4,
};

export const ATTRIBUTE_LEVELS = {
  LEVEL_1: 1,
  LEVEL_2: 2,
  LEVEL_3: 3,
};

export const CITY_REGIONS = {
  NORTH_AMERICA: 1,
  LATIN_AMERICA: 2,
  EUROPE: 3,
  AFRICA: 4,
  ASIA: 5,
  OCEANIA: 6,
};

export const CITY_STATUS = {
  INACTIVE: 1,
  COMING_SOON: 2,
  ACTIVE: 3,
  PLANNED: 4,
};

export const CITY_REGION_NAMES = {
  [CITY_REGIONS.NORTH_AMERICA]: 'North America',
  [CITY_REGIONS.LATIN_AMERICA]: 'Latin America',
  [CITY_REGIONS.EUROPE]: 'Europe',
  [CITY_REGIONS.AFRICA]: 'Africa',
  [CITY_REGIONS.ASIA]: 'Asia',
  [CITY_REGIONS.OCEANIA]: 'Oceania',
};

export const MEMBER_TIER = {
  GUEST: 1,
  MEMBER: 2,
  HOST: 3,
  AMBASSADOR: 4,
};

export const CHANNEL_TYPES = {
  DIRECT: 'direct',
  EVENT: 'event',
  SUPPORT: 'support',
};

export const STREAM_USER_ROLES = {
  USER: 'user',
  CITY_MANAGER: 'socialtable_city_manager',
  ADMIN: 'socialtable_admin',
};

export const BASIC_PREFS = {
  NEVER: 1,
  OKAY: 2,
  PREFERRED: 3,
};

export const GENDER_MIX_CHOICES = {
  DONT_CARE: 0,
  PREFER_SAME: 1,
  REQUIRE_SAME: 2,
  PREFER_MIXED: 3,
  REQUIRE_MIXED: 4,
};

export const CHAT_TYPES = {
  DIRECT: 1,
  EVENT: 2,
  SUPPORT: 3,
};

export const CHAT_MESSAGE_TYPES = {
  EVENT_WELCOME: 'event_welcome',
  EVENT_REMINDER: 'event_reminder',
  EVENT_FEEDBACK_REQUESTED: 'event_feedback_requested',
  EVENT_CANCELLED: 'event_cancelled',
  RESERVATION_UPDATED: 'reservation_updated',
  NEW_ATTENDEE: 'new_attendee',
  NEW_CONNECTION: 'new_connection',
  NEW_SUPPORT_CHAT: 'new_concierge_chat',
  USER_FEEDBACK: 'user_feedback',
};

export const CHAT_MESSAGE_DISPLAY_SIMPLE = [CHAT_MESSAGE_TYPES.NEW_CONNECTION, CHAT_MESSAGE_TYPES.NEW_SUPPORT_CHAT];

export const BASE_LEVELS = {
  NONE: 0,
  LIKE: 1,
  LOVE: 2,
};

export const TOPIC_LEVELS = {
  NONE: 0,
  LIKE: 1,
  LOVE: 2,
};

export const GOAL_TYPES = {
  PERSONAL: 1,
  BUSINESS: 2,
  CAREER: 3,
  BUSINESS_GROWTH: 4,
};

export const GOAL_LEVELS = {
  NO: 1,
  YES: 2,
  DEFINITELY: 3,
};

export const ADMIN_STATUS = {
  NONE: 1,
  PAUSED: 2,
  BANNED: 3,
  WAITLISTED: 4,
  APPROVED: 5,
};

export const MEET_RATING = {
  PENDING: 0,
  BLOCKED: 1,
  SKIP: 2,
  MEET: 3,
  PRIORITY: 4,
};

export const EXPERIENCE_RATING = {
  AWFUL: 1,
  POOR: 2,
  NEUTRAL: 3,
  GOOD: 4,
  DELIGHTFUL: 5,
  NO_SHOW: 6,
  DID_NOT_TALK: 7,
};

export const ATTENDEE_STATUS = {
  PENDING: 0,
  ATTENDING: 1,
  CANCELLED: 3,
  REVIEWED: 4,
  NOT_THERE: 5,
  ADMIN_MOVED: 7,
  ADMIN_REMOVED: 8,
  DRAFT: 9,
};

export const ATTENDEE_JOIN_TYPE = {
  MATCHED: 1,
  ADMIN: 2,
  HOST: 3,
  INVITED: 4,
  APPLIED: 5,
  BOOKED: 6,
};

export const ATTENDEE_STATUS_NOT_THERE = [ATTENDEE_STATUS.CANCELLED, ATTENDEE_STATUS.NOT_THERE];

export const EVENT_STATUS = {
  ALGO_DRAFT: 1,
  LIVE: 2,
  CANCELLED: 3,
  HOST_DRAFT: 4,
  HOST_DELETED: 5,
  DONE: 6,
};

export const EVENT_TYPE = {
  DINNER: 1,
  HAPPY_HOUR: 2,
  BRUNCH: 3,
  // COFFEE: 4,
};

export const EVENT_REQUEST_STATUS = {
  REQUESTED: 1,
  INVITED: 2,
  GUEST_WITHDRAWN: 3,
  GUEST_DECLINED: 4,
  HOST_WITHDRAWN: 5,
  HOST_DECLINED: 6,
  REGISTERED: 7,
  WAITLIST_JOINED: 8,
  WAITLIST_WITHDRAWN: 9,
};

export const REGISTRATION_POLICY = {
  INVITE_ONLY: 1,
  MEMBERS_ONLY: 5,
  OPEN_TO_ALL: 6,
};

export const REQUEST_POLICY = {
  ANYONE: 1,
  MEMBERS_ONLY: 2,
  DISABLED: 3,
};

export const GENDER_POLICY = {
  ANY: 1,
  MALE_ONLY: 2,
  FEMALE_ONLY: 3,
};

export const HOST_TYPE = {
  HOST_ONLY: 1,
  HOST_PLUS_ONE: 2,
  HOST_PLUS_TWO: 3,
};

export const GENDER_CHOICES = {
  MALE: 'M',
  FEMALE: 'F',
  OTHER: 'O',
};

export const PLUS_ONE_CHOICES = {
  FRIEND_MALE: 1,
  FRIEND_FEMALE: 2,
  PARTNER_MALE: 3,
  PARTNER_FEMALE: 4,
};

export const ATTEND_WITH_FRIEND_CHOICES = {
  MEMBER: 1,
  NON_MEMBER: 2,
};

export const ADD_TOPIC_CONTEXT = {
  NONE: 0,
  ONBOARDING_FEATURED: 1,
  ONBOARDING_SEARCH: 2,
  SEARCH: 3,
  POPULAR: 4,
  PROFILE: 5,
  EVENT: 6,
  MY_TOPICS: 7,
  PROFILE_LIST: 8,
  EVENT_LIST: 9,
  ONBOARDING_MORE: 10,
  MY_CLUBS: 11,
  ALL_CLUBS: 12,
};

export const RATE_USER_CONTEXT = {
  SUGGESTED: 1,
  PROFILE: 2,
  PROFILE_ITEM: 3,
  SUGGESTED_UNDO: 4,
};

export const POOL_STATUS = {
  OPEN: 1,
  MATCHING: 2,
  MATCHED: 3,
  CANCELLED: 4,
  DRAFT: 5,
};

export const POOL_REGISTRATION_STATUS = {
  CONFIRMED: 1,
  CANCELLED: 2,
  ADMIN_REMOVED: 3,
  MATCHED: 4,
  NOT_MATCHED: 5,
  POOL_CANCELLED: 6,
  WAITLIST: 7,
  UNCONFIRMED: 8,
};

export const REGISTRATION_MATCH_STATUS = {
  PENDING: 0,
  SUCCESS: 1,
  FAILED: 2,
};

export const FIRESTORE_UPDATES = {
  USER: 'user',
  USER_EVENTS_HOSTING: 'user_events_hosting',
  USER_EVENTS_ATTENDING: 'user_events_attending',
  USER_POOL_REGISTRATIONS: 'user_pool_registrations',
  USER_EVENT_REGISTRATIONS: 'user_event_registrations',
  USER_CHATS: 'user_chats',
  USER_BELLS: 'user_bells',
  USER_PAIR_INVITES: 'user_pair_invites',
  CITY: 'city',
  CITY_POOLS_UPCOMING: 'city_pools',
  CITY_EVENTS_UPCOMING: 'city_events',
};

export const LOGS = {
  NOTIFICATION_PRESET: 'notification_preset',
  NOTIFICATION_CUSTOM: 'notification_custom',
};

export const NOTIFICATIONS = {
  TEST: 1,
  UPCOMING_1: 2,
  CONFIRM: 3,
  RATE_POSSIBLE_MATCHES: 4,
  MATCHING_FAIL: 5,
  MATCHING_SUCCESS: 6,
  RATE_EXPERIENCE: 7,
  ADMIN_INVITED: 9,
  ADMIN_PAUSED: 10,
  NEW_INVITES: 14,
  GRANT_HOSTING_PRIVILEGE: 15,
  ONBOARDED_INVITEE: 23,
  PAIR_INVITE_SEND: 24,
  PAIR_INVITE_ACCEPT: 25,
  PAIR_INVITE_DECLINE: 26,
  PAIR_INVITE_CANCEL: 27,
  ONBOARDED_FRIEND: 28,
  PAIR_INVITE_WITHDRAWN: 29,
  NEW_COMPLIMENTS: 31,
  INVITED: 33,
  UPCOMING_2: 34,
  CREDIT_EARNED: 35,
  PAIR_INVITE_EXPIRED: 36,
  ADDED_TO_EVENT: 37,
  CANCEL_MATCHDAY: 38,
  ADMIN_NEW_REGISTRATION: 39,
  CREDIT_GIVEN: 40,
  ADMIN_REG_CANCELLATION: 41,
  PAIR_INVITE_WARNING: 42,
  ADMIN_EVENT_CANCELLATION: 43,
  NEW_COHOST: 44,
  NEW_ATTENDEE: 45,
  EVENT_APPLY_REQUEST: 46,
  EVENT_APPLY_INVITED: 47,
  EVENT_APPLY_GUEST_DECLINED: 48,
  NEW_EVENT_POSTED: 49,
  CUSTOM: 50,
  NEW_VOUCH: 51,
  ONBOARDED_FRIEND_VOUCHED: 52,
};

export const BELLS = {
  BELL_TEST: 0,
  BELL_REFERRED_BY: 1,
  BELL_CONTACT_JOINED: 2,
  BELL_INVITEE_JOINED: 3,
  BELL_NEW_COMPLIMENTS: 4,
  BELL_APPLICATION_ACCEPTED: 5,
  BELL_REFERRALS_GRANTED: 6,
  BELL_HOSTING_GRANTED: 7,
  BELL_MATCHING_FAILED: 8,
  BELL_CREDIT_ON_JOIN: 9,
  BELL_CREDIT_EARNED: 10,
  BELL_CREDITS_GIVEN: 11,
};

export const BELL_MODAL_TYPES = [
  // BELLS.BELL_TEST,
  BELLS.BELL_REFERRED_BY,
  // BELLS.BELL_CONTACT_JOINED,
  BELLS.BELL_INVITEE_JOINED,
  BELLS.BELL_NEW_COMPLIMENTS,
  BELLS.BELL_APPLICATION_ACCEPTED,
  BELLS.BELL_REFERRALS_GRANTED,
  BELLS.BELL_HOSTING_GRANTED,
];

export const LANGUAGE_LEVELS = {
  LANG_0: 0,
  LANG_1: 1,
  LANG_2: 2,
  LANG_3: 3,
  LANG_4: 4,
  LANG_5: 5,
};

export const MUTUAL_SETTING = {
  INTRODUCE: 1,
  DISPLAY: 2,
  CHAT: 3,
  CHAT_STAR: 4,
};

export const FEATURE_STATUS = {
  ACTIVE: 1,
  ACCEPTED: 2,
  DECLINED: 3,
  CANCELLED: 4,
  EXPIRED: 6,
};

export const INVITE_TYPE = {
  PERSONAL: 1,
  PAIR: 2,
  HOST: 3,
  MULTI: 4,
  EVENT: 5,
  TYPEFORM: 6,
  PROSPECT: 7,
  APP_APPLY: 8,
  POOL: 10,
  USER: 11,
  CONTACT: 12,
};

export const REGISTER_ACTION = {
  PLATFORM_PAY: 'platform_pay',
  PAY_WITH_CREDITS: 'pay_with_credits',
  PAY_WITH_CARD: 'pay_with_card',
  REGISTER_FREE: 'register_free',
};

export const TICKET_PAYMENT_STATUS = {
  PAID_STRIPE: 'paid_stripe',
  PAID_CREDITS: 'paid_credits',
  CREDIT_GIVEN: 'credit_given',
  REFUNDED: 'refunded',
};

export const APPLICATION_STATUS = {
  NEW: 'new',
  WILL_INVITE: 'will_invite',
  MAYBE_LATER: 'maybe_later',
  PASSED: 'passed',
  INVITED: 'invited',
};

export const RESERVATION_STATUS = {
  PENDING: 0,
  BOOKED_AHEAD: 1,
  ALGO_DRAFT: 2,
  ASSIGNED: 3,
  CANCELLED: 4,
};

export const getKeyByValue = (object, value) => {
  if (value !== undefined) {
    return Object.keys(object)
      .find(key => object[key] === value)
      ?.toLowerCase();
  }
};

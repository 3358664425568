import { apiSlice } from './apiSlice';

export const apiPosthog = apiSlice.injectEndpoints({
  endpoints: build => ({
    featureEvaluation: build.query({
      query: distinctId => `/account/features/${distinctId}`,
    }),
  }),
});

export const { useFeatureEvaluationQuery } = apiPosthog;

import React from 'react';
import NextLink from 'next/link';
import { Box, Button as ChakraButton } from '@chakra-ui/react';
import { getLinkProps } from '@/utils/nextUtils';
import { lib } from '@tlsocial/common';
import { MyNextLink } from './MyNextLink';

const Button = props => {
  const { solid, variant, warning, to, state, href, label, confirm, target, myClickEvent, ...otherProps } = props;

  const myvariant = variant || (solid ? 'solid' : 'outline');

  const isSolid = myvariant === 'solid';

  const bg = myvariant === 'solid' ? lib.colors.primary500 : 'transparent';
  const hoverBg = props.hoverBg || myvariant === 'solid' ? lib.colors.primary500 : lib.colors.alpha100;
  const color = myvariant === 'solid' ? 'white' : lib.colors.primary500;
  const bgGradient = isSolid ? `linear(to-br, ${lib.colors.primary300}, ${lib.colors.primary600})` : null;

  const renderButton = () => {
    return (
      <ChakraButton
        // textTransform={props.variant !== 'link' && 'uppercase'}
        // fontWeight={props.variant !== 'link' && 'bold'}
        fontWeight="900"
        // as="a"
        variant={myvariant}
        // colorScheme={warning ? 'red' : 'primary'}
        bg={bg}
        bgGradient={bgGradient}
        _hover={{ bg: hoverBg }}
        color={color}
        px={props.size == 'sm' ? 4 : props.size == 'lg' ? 8 : 6}
        borderRadius={100}
        fontSize={props.size == 'sm' ? '14px' : props.size == 'lg' ? '18px' : '16px'}
        // letterSpacing={0.5}
        {...otherProps}>
        {label || props.children}
      </ChakraButton>
    );
  };

  if (to && !props.isDisabled) {
    return (
      <MyNextLink myClickEvent={myClickEvent} {...getLinkProps(to)}>
        {renderButton()}
      </MyNextLink>
    );
  } else if (href && !props.isDisabled) {
    return (
      <MyNextLink myClickEvent={myClickEvent} href={href} target={target}>
        {renderButton()}
      </MyNextLink>
    );
  } else {
    return renderButton();
  }
};

export const SelectButton = props => {
  const { isActive, label, labelActive, secondary, ...extraProps } = props;
  const variant = secondary ? 'outline' : isActive ? 'solid' : 'outline';
  const activeLabel = isActive ? labelActive : label;
  return <Button variant={variant} label={activeLabel} {...extraProps} />;
};

export const ButtonBox = props => {
  return <Button whiteSpace="normal" h="auto" w="full" py={3} variant="solid" {...props} />;
};

export default Button;

import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDoubleLeft } from '@fortawesome/pro-light-svg-icons/faAngleDoubleLeft';
import { faAngleDoubleRight } from '@fortawesome/pro-light-svg-icons/faAngleDoubleRight';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import { faArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowUp';
import { faArrowUp as faArrowUpSolid } from '@fortawesome/pro-solid-svg-icons/faArrowUp';
import { faArrowDown } from '@fortawesome/pro-light-svg-icons/faArrowDown';
import { faArrowDown as faArrowDownSolid } from '@fortawesome/pro-solid-svg-icons/faArrowDown';
import { faBell } from '@fortawesome/pro-light-svg-icons/faBell';
import { faBell as faBellSolid } from '@fortawesome/pro-solid-svg-icons/faBell';
import { faBells } from '@fortawesome/pro-light-svg-icons/faBells';
import { faBullseye } from '@fortawesome/pro-light-svg-icons/faBullseye';
import { faBullseyeArrow } from '@fortawesome/pro-light-svg-icons/faBullseyeArrow';
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { faCalendar as faCalendarSolid } from '@fortawesome/pro-solid-svg-icons/faCalendar';
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay';
import { faCaretDown } from '@fortawesome/pro-light-svg-icons/faCaretDown';
import { faCaretDown as faCaretDownSolid } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCamera } from '@fortawesome/pro-light-svg-icons/faCamera';
import { faCamera as faCameraSolid } from '@fortawesome/pro-solid-svg-icons/faCamera';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faCheck as faCheckSolid } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faCity } from '@fortawesome/pro-light-svg-icons/faCity';
import { faCity as faCitySolid } from '@fortawesome/pro-solid-svg-icons/faCity';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faClock as faClockRegular } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faClock as faClockSolid } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faCocktail } from '@fortawesome/pro-light-svg-icons/faCocktail';
import { faCocktail as faCocktailSolid } from '@fortawesome/pro-solid-svg-icons/faCocktail';
import { faCoffee } from '@fortawesome/pro-light-svg-icons/faCoffee';
import { faCoffee as faCoffeeSolid } from '@fortawesome/pro-solid-svg-icons/faCoffee';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faCogs } from '@fortawesome/pro-light-svg-icons/faCogs';
import { faComment } from '@fortawesome/pro-light-svg-icons/faComment';
import { faComment as faCommentSolid } from '@fortawesome/pro-solid-svg-icons/faComment';
import { faComments } from '@fortawesome/pro-light-svg-icons/faComments';
import { faComments as faCommentsSolid } from '@fortawesome/pro-solid-svg-icons/faComments';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons/faCreditCard';
import { faDiamondExclamation } from '@fortawesome/pro-light-svg-icons/faDiamondExclamation';
import { faDollarSign } from '@fortawesome/pro-light-svg-icons/faDollarSign';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons/faEllipsisV';
import { faEraser } from '@fortawesome/pro-light-svg-icons/faEraser';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faExclamationTriangle as faExclamationTriangleSolid } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faFaceGrinWide } from '@fortawesome/pro-light-svg-icons/faFaceGrinWide';
import { faFlag } from '@fortawesome/pro-light-svg-icons/faFlag';
import { faFlag as faFlagSolid } from '@fortawesome/pro-solid-svg-icons/faFlag';
import { faGift } from '@fortawesome/pro-light-svg-icons/faGift';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faHandshake } from '@fortawesome/pro-light-svg-icons/faHandshake';
import { faHandshake as faHandshakeSolid } from '@fortawesome/pro-solid-svg-icons/faHandshake';
import { faHatChef } from '@fortawesome/pro-light-svg-icons/faHatChef';
import { faHeart } from '@fortawesome/pro-light-svg-icons/faHeart';
import { faHeart as faHeartRegular } from '@fortawesome/pro-regular-svg-icons/faHeart';
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons/faHeart';
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome';
import { faHome as faHomeSolid } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faInfo } from '@fortawesome/pro-light-svg-icons/faInfo';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faLanguage } from '@fortawesome/pro-light-svg-icons/faLanguage';
import { faLeftLong } from '@fortawesome/pro-light-svg-icons/faLeftLong';
import { faLightbulb } from '@fortawesome/pro-light-svg-icons/faLightbulb';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faLocationArrow } from '@fortawesome/pro-light-svg-icons/faLocationArrow';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { faLock as faLockSolid } from '@fortawesome/pro-solid-svg-icons/faLock';
import { faMagic } from '@fortawesome/pro-light-svg-icons/faMagic';
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkerAlt';
import { faMapPin } from '@fortawesome/pro-light-svg-icons/faMapPin';
import { faMapPin as faMapPinSolid } from '@fortawesome/pro-solid-svg-icons/faMapPin';
import { faMessage } from '@fortawesome/pro-light-svg-icons/faMessage';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane';
import { faPaperPlane as faPaperPlaneSolid } from '@fortawesome/pro-solid-svg-icons/faPaperPlane';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faPen as faPenSolid } from '@fortawesome/pro-solid-svg-icons/faPen';
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import { faPlus as faPlusSolid } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion';
import { faQuestion as faQuestionSolid } from '@fortawesome/pro-solid-svg-icons/faQuestion';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { faQuestionCircle as faQuestionCircleSolid } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faInfoCircle as faInfoCircleSolid } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faSeedling } from '@fortawesome/pro-light-svg-icons/faSeedling';
import { faSliders } from '@fortawesome/pro-light-svg-icons/faSliders';
import { faSliders as faSlidersSolid } from '@fortawesome/pro-solid-svg-icons/faSliders';
import { faSmile } from '@fortawesome/pro-light-svg-icons/faSmile';
import { faSms } from '@fortawesome/pro-light-svg-icons/faSms';
import { faSparkles } from '@fortawesome/pro-light-svg-icons/faSparkles';
import { faSparkles as faSparklesSolid } from '@fortawesome/pro-solid-svg-icons/faSparkles';
import { faSparkle } from '@fortawesome/pro-light-svg-icons/faSparkle';
import { faSparkle as faSparkleSolid } from '@fortawesome/pro-solid-svg-icons/faSparkle';
import { faStar } from '@fortawesome/pro-light-svg-icons/faStar';
import { faStar as faStarRegular } from '@fortawesome/pro-regular-svg-icons/faStar';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faThumbsDown } from '@fortawesome/pro-light-svg-icons/faThumbsDown';
import { faThumbsDown as faThumbsDownSolid } from '@fortawesome/pro-solid-svg-icons/faThumbsDown';
import { faThumbsUp } from '@fortawesome/pro-light-svg-icons/faThumbsUp';
import { faThumbsUp as faThumbsUpSolid } from '@fortawesome/pro-solid-svg-icons/faThumbsUp';
import { faThumbsUp as faThumbsUpRegular } from '@fortawesome/pro-regular-svg-icons/faThumbsUp';
import { faTicket } from '@fortawesome/pro-light-svg-icons/faTicket';
import { faTicket as faTicketSolid } from '@fortawesome/pro-solid-svg-icons/faTicket';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faUpFromBracket } from '@fortawesome/pro-light-svg-icons/faUpFromBracket';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUser as faUserSolid } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUserCircle } from '@fortawesome/pro-light-svg-icons/faUserCircle';
import { faUserCircle as faUserCircleSolid } from '@fortawesome/pro-solid-svg-icons/faUserCircle';
import { faUserGroup } from '@fortawesome/pro-light-svg-icons/faUserGroup';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import { faUserPlus as faUserPlusSolid } from '@fortawesome/pro-solid-svg-icons/faUserPlus';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faUsers as faUsersSolid } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faUtensils } from '@fortawesome/pro-light-svg-icons/faUtensils';
import { faUtensils as faUtensilsSolid } from '@fortawesome/pro-solid-svg-icons/faUtensils';
import { faVenusMars } from '@fortawesome/pro-light-svg-icons/faVenusMars';
import { faWindowClose } from '@fortawesome/pro-light-svg-icons/faWindowClose';
import { faX } from '@fortawesome/pro-light-svg-icons/faX';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons/faXmarkCircle';
import { faXmarkCircle as faXmarkCircleSolid } from '@fortawesome/pro-solid-svg-icons/faXmarkCircle';
import { faCcAmex } from '@fortawesome/free-brands-svg-icons/faCcAmex';
import { faCcDinersClub } from '@fortawesome/free-brands-svg-icons/faCcDinersClub';
import { faCcDiscover } from '@fortawesome/free-brands-svg-icons/faCcDiscover';
import { faCcJcb } from '@fortawesome/free-brands-svg-icons/faCcJcb';
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons/faCcMastercard';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons/faCcVisa';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faBars as faBarsSolid } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faMegaphone } from '@fortawesome/pro-light-svg-icons/faMegaphone';
import { faMegaphone as faMegaphoneSolid } from '@fortawesome/pro-solid-svg-icons/faMegaphone';
import { faMicrophone as faMicrophoneSolid } from '@fortawesome/pro-solid-svg-icons/faMicrophone';
import { faPartyHorn } from '@fortawesome/pro-light-svg-icons/faPartyHorn';
import { faPartyHorn as faPartyHornSolid } from '@fortawesome/pro-solid-svg-icons/faPartyHorn';
import { faDatabase } from '@fortawesome/pro-solid-svg-icons/faDatabase';
import { faHammer } from '@fortawesome/pro-solid-svg-icons/faHammer';
import { faHourglass } from '@fortawesome/pro-light-svg-icons/faHourglass';
import { faHourglass as faHourglassSolid } from '@fortawesome/pro-solid-svg-icons/faHourglass';
import { faPeopleArrows } from '@fortawesome/pro-solid-svg-icons/faPeopleArrows';
import { faBan } from '@fortawesome/pro-solid-svg-icons/faBan';
import { faRightLeft } from '@fortawesome/pro-light-svg-icons/faRightLeft';
import { faDiamond } from '@fortawesome/pro-light-svg-icons/faDiamond';
import { faDiamond as faDiamondSolid } from '@fortawesome/pro-solid-svg-icons/faDiamond';
import { faAward as faAwardSolid } from '@fortawesome/pro-solid-svg-icons/faAward';
import { faBookmark as faBookmarkSolid } from '@fortawesome/pro-solid-svg-icons/faBookmark';
import { faCheckDouble } from '@fortawesome/pro-light-svg-icons/faCheckDouble';
import { faCheckDouble as faCheckDoubleSolid } from '@fortawesome/pro-solid-svg-icons/faCheckDouble';
import { faArrowRight as faArrowRightSolid } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faArrowLeft as faArrowLeftSolid } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faArrowRightLong as faArrowRightLongSolid } from '@fortawesome/pro-solid-svg-icons/faArrowRightLong';
import { faArrowLeftLong as faArrowLeftLongSolid } from '@fortawesome/pro-solid-svg-icons/faArrowLeftLong';
import { faUnlock as faUnlockSolid } from '@fortawesome/pro-solid-svg-icons/faUnlock';
import { faCoin as faCoinSolid } from '@fortawesome/pro-solid-svg-icons/faCoin';
import { faSquareCheck as faSquareCheckSolid } from '@fortawesome/pro-solid-svg-icons/faSquareCheck';
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons/faCircleCheck';
import { faCircleCheck as faCircleCheckSolid } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { faSquare as faSquareSolid } from '@fortawesome/pro-solid-svg-icons/faSquare';
import { faAvocado as faAvocadoSolid } from '@fortawesome/pro-solid-svg-icons/faAvocado';
import { faCalendarStar as faCalendarStarSolid } from '@fortawesome/pro-solid-svg-icons/faCalendarStar';
import { faFaceMeh as faFaceMehSolid } from '@fortawesome/pro-solid-svg-icons/faFaceMeh';
import { faFaceSmileBeam as faFaceSmileBeamSolid } from '@fortawesome/pro-solid-svg-icons/faFaceSmileBeam';
import { faFaceAwesome as faFaceAwesomeSolid } from '@fortawesome/pro-solid-svg-icons/faFaceAwesome';
import { faFaceGrinStars } from '@fortawesome/pro-light-svg-icons/faFaceGrinStars';
import { faFaceGrinStars as faFaceGrinStarsSolid } from '@fortawesome/pro-solid-svg-icons/faFaceGrinStars';
// stairs
import { faStairs as faStairsSolid } from '@fortawesome/pro-solid-svg-icons/faStairs';
// envelope
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faEnvelope as faEnvelopeSolid } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
// envelope-open
import { faEnvelopeOpen } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpen';
import { faEnvelopeOpen as faEnvelopeOpenSolid } from '@fortawesome/pro-solid-svg-icons/faEnvelopeOpen';
// mobile
import { faMobile as faMobileSolid } from '@fortawesome/pro-solid-svg-icons/faMobile';
// messages
import { faMessages as faMessagesSolid } from '@fortawesome/pro-solid-svg-icons/faMessages';
// pencil
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
// clone
import { faClone } from '@fortawesome/pro-light-svg-icons/faClone';
// comment-sms
import { faCommentSms } from '@fortawesome/pro-light-svg-icons/faCommentSms';
import { faCommentSms as faCommentSmsSolid } from '@fortawesome/pro-solid-svg-icons/faCommentSms';
// chevrons-left
import { faChevronsLeft as faChevronsLeftSolid } from '@fortawesome/pro-solid-svg-icons/faChevronsLeft';
// chevrons-right
import { faChevronsRight as faChevronsRightSolid } from '@fortawesome/pro-solid-svg-icons/faChevronsRight';
// chevron-down
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
// square-2
import { faSquare2 } from '@fortawesome/pro-light-svg-icons/faSquare2';
import { faSquare2 as faSquare2Solid } from '@fortawesome/pro-solid-svg-icons/faSquare2';
// square-3
import { faSquare3 } from '@fortawesome/pro-light-svg-icons/faSquare3';
import { faSquare3 as faSquare3Solid } from '@fortawesome/pro-solid-svg-icons/faSquare3';
// square-4
import { faSquare4 } from '@fortawesome/pro-light-svg-icons/faSquare4';
import { faSquare4 as faSquare4Solid } from '@fortawesome/pro-solid-svg-icons/faSquare4';
// arrow-up-right-dots
import { faArrowUpRightDots } from '@fortawesome/pro-light-svg-icons/faArrowUpRightDots';
// undo
import { faUndo } from '@fortawesome/pro-light-svg-icons/faUndo';
// face-zipper solid
import { faFaceZipper as faFaceZipperSolid } from '@fortawesome/pro-solid-svg-icons/faFaceZipper';
// eye-slash
import { faEyeSlash as faEyeSlashSolid } from '@fortawesome/pro-solid-svg-icons/faEyeSlash';
// flask-vial
import { faFlaskVial } from '@fortawesome/pro-light-svg-icons/faFlaskVial';
// cloud-question solid
import { faCloudQuestion as faCloudQuestionSolid } from '@fortawesome/pro-solid-svg-icons/faCloudQuestion';
// fire-flame
import { faFireFlame } from '@fortawesome/pro-light-svg-icons/faFireFlame';
// fire-flame solid
import { faFireFlame as faFireFlameSolid } from '@fortawesome/pro-solid-svg-icons/faFireFlame';
// wave-pulse
import { faWavePulse } from '@fortawesome/pro-light-svg-icons/faWavePulse';
// wave-pulse solid
import { faWavePulse as faWavePulseSolid } from '@fortawesome/pro-solid-svg-icons/faWavePulse';
// copy solid
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy';
// cc-apple-pay
import { faApplePay } from '@fortawesome/free-brands-svg-icons/faApplePay';
// cc-google-pay
import { faGooglePay } from '@fortawesome/free-brands-svg-icons/faGooglePay';
// circle-1 solid
import { faCircle1 } from '@fortawesome/pro-light-svg-icons/faCircle1';
import { faCircle1 as faCircle1Solid } from '@fortawesome/pro-solid-svg-icons/faCircle1';
// circle-2 solid
import { faCircle2 as faCircle2Solid } from '@fortawesome/pro-solid-svg-icons/faCircle2';
// circle-3 solid
import { faCircle3 as faCircle3Solid } from '@fortawesome/pro-solid-svg-icons/faCircle3';
// circle-4 solid
import { faCircle4 as faCircle4Solid } from '@fortawesome/pro-solid-svg-icons/faCircle4';
// circle-5 solid
import { faCircle5 as faCircle5Solid } from '@fortawesome/pro-solid-svg-icons/faCircle5';
// circle-6 solid
import { faCircle6 as faCircle6Solid } from '@fortawesome/pro-solid-svg-icons/faCircle6';
// external-link
import { faExternalLink } from '@fortawesome/pro-light-svg-icons/faExternalLink';
// external-link solid
import { faExternalLink as faExternalLinkSolid } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
// user-check
import { faUserCheck } from '@fortawesome/pro-light-svg-icons/faUserCheck';
import { faUserCheck as faUserCheckSolid } from '@fortawesome/pro-solid-svg-icons/faUserCheck';
import { faChair } from '@fortawesome/pro-light-svg-icons/faChair';
import { faChair as faChairSolid } from '@fortawesome/pro-solid-svg-icons/faChair';
// apple
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
// Android Play Store
import { faGooglePlay } from '@fortawesome/free-brands-svg-icons/faGooglePlay';
// balance-scale
import { faBalanceScale } from '@fortawesome/pro-light-svg-icons/faBalanceScale';
// spinner
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
import { faSpinner as faSpinnerSolid } from '@fortawesome/pro-solid-svg-icons/faSpinner';
// trophy
import { faTrophy } from '@fortawesome/pro-light-svg-icons/faTrophy';

library.add(
  faTrophy,
  faSpinner,
  faSpinnerSolid,
  faGooglePlay,
  faApple,
  faChair,
  faChairSolid,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowUpSolid,
  faArrowDown,
  faArrowDownSolid,
  faBell,
  faBellSolid,
  faBells,
  faBullseye,
  faBullseyeArrow,
  faCalendar,
  faCalendarSolid,
  faCalendarDay,
  faCamera,
  faCameraSolid,
  faCaretDown,
  faCaretDownSolid,
  faCheck,
  faCheckSolid,
  faChevronLeft,
  faChevronRight,
  faCity,
  faCitySolid,
  faClock,
  faClockRegular,
  faClockSolid,
  faCocktail,
  faCocktailSolid,
  faCoffee,
  faCoffeeSolid,
  faCog,
  faCogs,
  faComment,
  faCommentSolid,
  faComments,
  faCommentsSolid,
  faCreditCard,
  faDiamondExclamation,
  faDollarSign,
  faEllipsisV,
  faEraser,
  faExclamationCircle,
  faExclamationTriangle,
  faExclamationTriangleSolid,
  faEyeSlash,
  faFacebook,
  faFaceGrinWide,
  faFlag,
  faFlagSolid,
  faGift,
  faGlobe,
  faHandshake,
  faHandshakeSolid,
  faHatChef,
  faHeart,
  faHeartRegular,
  faHeartSolid,
  faHome,
  faHomeSolid,
  faInfo,
  faInfoCircle,
  faInstagram,
  faWhatsapp,
  faLanguage,
  faLeftLong,
  faLightbulb,
  faLink,
  faLinkedin,
  faLocationArrow,
  faLock,
  faLockSolid,
  faMagic,
  faMapMarkerAlt,
  faMapPin,
  faMapPinSolid,
  faMessage,
  faPaperPlane,
  faPaperPlaneSolid,
  faPen,
  faPenSolid,
  faPhone,
  faPlusSolid,
  faQuestion,
  faQuestionSolid,
  faQuestionCircle,
  faQuestionCircleSolid,
  faSearch,
  faSeedling,
  faSliders,
  faSlidersSolid,
  faSmile,
  faSms,
  faSparkles,
  faSparklesSolid,
  faSparkle,
  faSparkleSolid,
  faStar,
  faStarRegular,
  faStarSolid,
  faThumbsDown,
  faThumbsDownSolid,
  faThumbsUp,
  faThumbsUpSolid,
  faThumbsUpRegular,
  faTicket,
  faTicketSolid,
  faTwitter,
  faUpFromBracket,
  faUser,
  faUserSolid,
  faUserCircle,
  faUserCircleSolid,
  faUserGroup,
  faUserPlus,
  faUserPlusSolid,
  faUsers,
  faUsersSolid,
  faUtensils,
  faUtensilsSolid,
  faVenusMars,
  faWindowClose,
  faX,
  faXmarkCircle,
  faXmarkCircleSolid,
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
  faBars,
  faBarsSolid,
  faMegaphone,
  faMegaphoneSolid,
  faMicrophoneSolid,
  faPartyHorn,
  faPartyHornSolid,
  faDatabase,
  faHammer,
  faPeopleArrows,
  faBan,
  faRightLeft,
  faDiamond,
  faDiamondSolid,
  faAwardSolid,
  faBookmarkSolid,
  faCheckDouble,
  faCheckDoubleSolid,

  faArrowRightSolid,
  faArrowLeftSolid,
  faArrowRightLongSolid,
  faArrowLeftLongSolid,
  faUnlockSolid,
  faInfoCircleSolid,
  faCoinSolid,
  faHourglass,
  faHourglassSolid,
  faSquareCheckSolid,
  faSquareSolid,
  faCircle,
  faCircleCheck,
  faCircleCheckSolid,
  faAvocadoSolid,
  faCalendarStarSolid,
  faFaceMehSolid,
  faFaceSmileBeamSolid,
  faFaceAwesomeSolid,
  faFaceGrinStars,
  faFaceGrinStarsSolid,
  faStairsSolid,
  faEnvelope,
  faEnvelopeSolid,
  faEnvelopeOpen,
  faEnvelopeOpenSolid,
  faMobileSolid,
  faMessagesSolid,
  faPencil,
  faClone,
  faCommentSms,
  faCommentSmsSolid,
  faChevronsLeftSolid,
  faChevronsRightSolid,
  faChevronDown,
  faSquare2,
  faSquare3,
  faSquare4,
  faSquare2Solid,
  faSquare3Solid,
  faSquare4Solid,
  faArrowUpRightDots,
  faUndo,
  faFaceZipperSolid,
  faEyeSlashSolid,
  faFlaskVial,
  faCloudQuestionSolid,
  faFireFlame,
  faFireFlameSolid,
  faWavePulse,
  faWavePulseSolid,
  faCopy,
  faApplePay,
  faGooglePay,
  faCircle1,
  faCircle1Solid,
  faCircle2Solid,
  faCircle3Solid,
  faCircle4Solid,
  faCircle5Solid,
  faCircle6Solid,
  faExternalLink,
  faExternalLinkSolid,
  faUserCheck,
  faUserCheckSolid,
  faBalanceScale,
);

import React from 'react';
import MyHelmet from '@/components/shared/MyHelmet';
import { createStandaloneToast } from '@chakra-ui/react';
import chakraTheme from '../../theme';
import { lib } from '@tlsocial/common';
import { useMain } from '@/hooks';
import Pixels from './JavascriptPixels';

const { ToastContainer, toast } = createStandaloneToast({ theme: chakraTheme });

const AppMain = props => {
  const { pageName, pageNameCity } = props;
  useMain(pageName, pageNameCity);

  return (
    <>
      <MyHelmet />
      {props.children}
      <ToastContainer />
      <Pixels />
    </>
  );
};

export default AppMain;

import { lib } from '@tlsocial/common';

export const flags = {
  web_home_headline: 'web_home_headline',
  web_home_headline_emoji: 'web_home_headline_emoji',
  web_home_subheader: 'web_home_subheader',
  web_home_question: 'web_home_question',
  web_join_button: 'web_join_button',
  web_home_header_is_capitalized: 'web_home_header_is_capitalized',
  web_home_header_is_900: 'web_home_header_is_900',
  web_home_countdown: 'web_home_countdown',
  app_bottom_tab_show_avatar: 'app_bottom_tab_show_avatar',
  app_my_matches_page: 'app_my_matches_page',
};

const variantStrings = {
  [flags.web_home_headline]: {
    control: 'Curated Dining Experiences',
    test_group_1: 'Real World Social Experiences',
    test_group_2: 'Find Your Community at Our Table',
    test_group_3: 'Find Genuine Connection at Our Table',
    test_group_4: `Dinner with 5 Strangers`,
    test_group_5: `Every Thursday Evening in {cityName}, Strangers Meet at Our Table`,
    test_group_6: `Every Thursday Evening in {cityName}, Six Individuals Meet for a Unique Dining Experience`,
    test_group_7: `Every Thursday Evening in {cityName}, Six Strangers Meet for a Unique Dining Experience`,
    // test_group_7: `New to {cityName}? Meet 5 New People Each Week`,
    // test_group_7: `Meet like-minded people through curated 6-person dinners in {cityName}!`,
    test_group_8: `New to {cityName}? Find Your Community at Our Table`,
    test_group_9: `New to {cityName}? Meet Your New Besties at Our Table.`,
  },
  [flags.web_home_headline_emoji]: {
    control: '',
    test_group_1: '🤗',
    test_group_2: '✨',
    test_group_3: '🍽️',
  },
  [flags.web_home_subheader]: {
    control:
      "SocialTable curates six-person dinners designed to create lively conversation and genuine connection. You'll arrive as strangers, but leave as friends!",
    test_group_1:
      "SocialTable brings six individuals together for a unique dining experience — designed to facilitate genuine connection and lively conversation. You'll arrive as strangers, but leave as friends!",
    test_group_2: 'Sign up to meet 5 individuals for dinner, all matched by our algorithm.',
    test_group_3: 'Sign up join 5 guests, all matched by our algorithm, each week',
    test_group_4: 'Sign up join 5 guests, all matched by our algorithm, next Thursday',
  },
  [flags.web_home_question]: {
    control: '',
    test_group_1: 'Will you join us?',
    test_group_2: 'Are you in?',
    test_group_3: 'A new experience awaits',
    test_group_4: 'Your table awaits!',
    test_group_5: "Don't be shy!",
    test_group_6: 'Welcome to Social Table!',
  },
  [flags.web_join_button]: {
    control: 'Book My Seat',
    test_group_1: 'Join the Community',
    test_group_2: 'Register to Attend',
    test_group_3: 'Download the App',
  },
};

export const flagVariants = {
  web_home_countdown: {
    control: 'control',
    top: 'top',
    hidden: 'hidden',
  },
};

export const getPosthogVariantString = (featureKey, variant, replacements = {}) => {
  const variantDict = variantStrings[featureKey];
  const rawString = variantDict[variant] || variantDict.control;
  return lib.templateReplace(rawString, replacements);
};

export const getControlString = (featureKey, replacements = {}) => {
  const variantDict = variantStrings[featureKey];
  return lib.templateReplace(variantDict.control, replacements);
};

import { lib } from '@tlsocial/common';
import { compareAsc } from 'date-fns';

export const getEventThemes = obj => {
  let themes = [];
  if (obj) {
    if (obj.is_theme_social) themes.push('social');
    if (obj.is_theme_business) themes.push('business');
    if (obj.is_theme_topic) themes.push('topic');
  }
  return themes;
};

export const getEventProfilesHidden = (event, profiles) => {
  if (profiles) {
    if (event.is_attendees_revealed) {
      return profiles;
    } else {
      const countHidden = Math.max(0, event.attendee_count_original - profiles.length);
      const hiddenProfiles = [...Array(countHidden).keys()].map((d, i) => ({}));
      return [...event.profiles, ...hiddenProfiles];
    }
  }
};

export const getNextEventProfile = (event, user, profileId = null) => {
  const index = event.profilesAll.findIndex(u => u.id === profileId);
  const nextProfile = event.profilesAll.find((u, i) => i > index && u.id !== user.id);
  return nextProfile;
};

export const sortEvents = (e1, e2) => {
  const e1Time = e1.time_event ? new Date(e1.time_event) : null;
  const e2Time = e2.time_event ? new Date(e2.time_event) : null;

  if (e1Time === null && e2Time === null) return 0;
  if (e1Time === null) return 1;
  if (e2Time === null) return -1;
  return compareAsc(e1Time, e2Time);
};

export const getEventsByDate = events =>
  events.reduce((acc, event) => {
    const date = lib.toZoned(event.time_event, event.city_timezone).toDateString();
    (acc[date] = acc[date] || []).push(event);
    return acc;
  }, {});

export const userNeedsInvite = (uniEvent, user) => {
  const inviteOnly = uniEvent.registration_policy === lib.REGISTRATION_POLICY.INVITE_ONLY;
  const membersOnly = uniEvent.registration_policy === lib.REGISTRATION_POLICY.MEMBERS_ONLY;
  const canRegisterAsMember = user.is_member || (uniEvent.is_pool && user.has_guest_pool_trial_available);
  return inviteOnly || (membersOnly && !canRegisterAsMember);
};

export const mustBeMember = (uniEvent, user) => {
  return uniEvent.registration_policy === lib.REGISTRATION_POLICY.MEMBERS_ONLY && !user.is_member;
};

export const isGenderPolicyOkay = (uniEvent, user) => {
  if (uniEvent.gender_policy === lib.GENDER_POLICY.MALE_ONLY) {
    if (user.gender !== 'M') return false;
  }
  if (uniEvent.gender_policy === lib.GENDER_POLICY.FEMALE_ONLY) {
    if (user.gender !== 'F') return false;
  }
  return true;
};

export const getUniEventKey = uniEvent => {
  const key = uniEvent.is_pool ? `pool_${uniEvent.id}` : `event_${uniEvent.id}`;
  return key;
};

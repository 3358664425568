import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

export const getLinkProps = (pathname, href = null) => {
  if (href) {
    return { href };
  } else if (pathname) {
    const href = pathname;
    // return { href};
    return { href, as: pathname };
  } else {
    return {};
  }
};

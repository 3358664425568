import React from 'react';
import MyHelmet from '@/components/shared/MyHelmet';
import { lib, api, redux } from '@tlsocial/common';
import Spinner from '@/components/shared/Spinner';
import { MaxContainer, MaxContentContainer } from '@/components/shared/MaxContainer';
import { H1, H2, H3 } from '@/components/shared/Headings';
import ContentLoading from '@/components/shared/ContentLoading';
import { useRouter } from 'next/router';
import * as c from '@/constants';
import { IconRow } from '@/components/shared/IconRow';
import Button from '@/components/shared/Button';
import LocationItem from '@/components/locations/LocationItem';
import { Avatar, AvatarList } from '@/components/people/Avatar';
import HowItWorksContainer from '@/components/shared/HowItWorksContainer';
import { Box, Text, ButtonGroup, IconButton, Stack, StackDivider, Show, Wrap, HStack, Flex } from '@chakra-ui/react';
import { linksTo, links } from '@/constants';
import { Link } from '@/components/shared/Links';
import { getClubPaths, getClubProps } from '@/utils';
import LocationsSidebar from '@/components/locations/LocationsSidebar';
import { useSlugRedirect } from '@/hooks';

const PhotoHeader = props => {
  const { image } = props;

  if (!image) return null;

  const backgroundImage = image && `url(${image})`;
  return (
    <MaxContainer px={[0, 0, 8]}>
      <Box
        mt={[0, 0, 8]}
        bg={lib.colors.light1}
        borderRadius={[0, 0, 10]}
        bgSize="cover"
        bgPosition="center"
        bgImage={backgroundImage}>
        <Box w="100%" h={['200px', '250px', '350px']} justify="center"></Box>
      </Box>
    </MaxContainer>
  );
};

export default PhotoHeader;

import React, { useEffect } from 'react';
import { configurePlatform } from '../../configurePlatform';
// import dynamic from 'next/dynamic';
import App from './App';

// This must be called before App is imported
configurePlatform();

// // Accessing App component this way so that configurePlatform runs before App is imported
// const App = require('./App').default;

// const App = dynamic(() => import('./App'), {
//   ssr: false,
// });

const LoadPlatform = props => {
  return <App {...props} />;
};

export default LoadPlatform;

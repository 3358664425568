import { useRouter } from 'next/router';
import React, { useContext, useEffect, useMemo, useState } from 'react';

// This one doesn't trigger re-renders because of useMemo
export const usePageview = (onPageview, dependencies = []) => {
  const router = useRouter();
  useEffect(() => {
    router.events.on('routeChangeComplete', onPageview);
    return () => {
      router.events.off('routeChangeComplete', onPageview);
    };
  }, dependencies);
};

import { Box, Center, Flex, HStack, IconButton, Img, Show, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { MaxContainer } from '@/components/shared/MaxContainer';
import { Modal } from '@/components/shared/Modals';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { ButtonsMenu, DropdownMenu } from '@/components/shared/Menus';
import { Link, LinkArea } from '@/components/shared/Links';
import Button from '@/components/shared/Button';
import { links } from '@/constants';
import { useTranslation } from 'react-i18next';
import Icon from '../shared/Icon';
import { lib } from '@tlsocial/common';
import { useTypeformLink } from '@/hooks/useTypeformLink';
import { insertIf } from '@/utils';
import QRCode from 'react-qr-code';
import { useSocialTable } from './SocialTableContext';
import ShowCrawlerFriendly from '../shared/ShowCrawlerFriendly';
import Photo from '../shared/Photo';

const menuButtonProps = {
  _hover: {
    bg: lib.colors.alpha50,
  },
  _active: {
    bg: lib.colors.alpha100,
    // color: 'white',
  },
  color: lib.colors.alpha900,
  // color: 'white',
  fontWeight: 'bold',
  fontSize: 16,
  borderRadius: 20,
};

const MainHeader = props => {
  const { city } = props;
  const dispatch = useDispatch();

  const joinLink = city ? links.CITY_JOIN(city) : links.JOIN;

  const linksNoAuth = city
    ? [
        ...insertIf({ label: 'Upcoming', to: links.EVENTS(city) }, city.show_upcoming_on_web),
        // ...insertIf({ label: 'Communities', to: links.CLUBS(city) }, city.show_communities_on_web),
        // { label: 'Community', to: links.CITIES },
        // { label: 'Locations', to: links.LOCATIONS(city) },
        { label: 'How it works', to: links.CITY_HOW(city) },
        { label: 'FAQ', to: links.CITY_FAQ(city) },
        { label: 'Etiquette', to: links.CITY_ETIQUETTE(city) },
        // { label: 'Apply', href: typeformUrl },
        { label: 'Locations', to: links.CITY_OTHER_CITIES(city) },
        // { label: 'Host', to: links.HOST(city) },
      ]
    : [
        { label: 'How it works', to: links.HOW },
        { label: 'FAQ', to: links.FAQ },
        { label: 'Etiquette', to: links.ETIQUETTE },
        { label: 'Locations', to: links.CITIES },
        // { label: 'Apply', href: typeformUrl },
        // { label: 'Host a Dinner', to: links.HOST({ city_key: 'home' }) },
        // { label: t('links.about'), to: links.ABOUT },
      ];

  const rightLinks = [];

  const droplinks = [...linksNoAuth, { label: 'Join', to: joinLink, myClickEvent: lib.ev.web_click_button_join }];

  // const renderRightMenu = () => {
  //   return (
  //     <Box position="relative">
  //       <Text style={{ marginRight: 95, fontWeight: 'bold', fontSize: 14, color: lib.colors.whiteAlpha800 }}>
  //         Download the app!
  //       </Text>
  //       <Box position="absolute" style={{ right: 0, top: -10, backgroundColor: 'white' }}>
  //         <QRCode value={'https://socialtable.app.link/download'} size={80} />
  //       </Box>
  //     </Box>
  //   );
  // };

  const renderRightMenu = () => {
    return (
      <Box>
        <Button solid label="Join" to={joinLink} myClickEvent={lib.ev.web_click_button_join} />
      </Box>
    );
  };

  const renderLogo = () => {
    return (
      <Flex mr={4} align="flex-end">
        <Link to={city ? links.CITY(city.city_key) : links.HOME}>
          <Photo src={lib.images.textLogo} alt="SocialTable" w={['125px', '150px']} />
          {/* <Text fontSize={[22, 24]} fontWeight="900" opacity={1} mb={0}>
            SocialTable{' '}
            <Text as="span" fontSize={[22, 24]} opacity={1} fontWeight="normal">
              {city?.name}
            </Text>
          </Text> */}
        </Link>
        {/* {city && (
          <Link to={links.CITIES} ml={2} mb={0}>
            <Text fontSize={[20, 22]} color={lib.colors.primary300} opacity={0.8}>
              {city?.name}
            </Text>
          </Link>
        )} */}
      </Flex>
    );
  };

  // const renderCity = () => {
  //   return (
  //     <LinkArea
  //       to={links.LOCATIONS}
  //       borderWidth={1}
  //       borderColor="whiteAlpha.300"
  //       borderRadius={50}
  //       px={4}
  //       py={2}
  //       hoverColor="whiteAlpha.200">
  //       <HStack align="center">
  //         <Text fontSize={13} textTransform="uppercase" color={lib.colors.primary300}>
  //           {city.name}
  //         </Text>
  //         <Icon solid icon="caret-down" variant="ghost" fontSize="16" color={lib.colors.primary300} />
  //       </HStack>
  //     </LinkArea>
  //   );
  // };

  return (
    <Box position="sticky" top="0" zIndex="1000" boxShadow="sm" bg={'white'}>
      <MaxContainer py={[0, 6]} minH={['65px', '0px']} boxShadow="sm" alignItems="center">
        <Flex justify="space-between" alignItems="center" minH={['65px', '0px']}>
          <HStack spacing="4">{renderLogo()}</HStack>

          <HStack spacing="5">
            {/* <Show above="md">{city && renderCity()}</Show> */}

            <ShowCrawlerFriendly above="md">
              <ButtonsMenu items={linksNoAuth} buttonProps={menuButtonProps} mt={0} pl={2} />
            </ShowCrawlerFriendly>

            <Show below="md">
              <DropdownMenu items={droplinks} />
            </Show>

            <ShowCrawlerFriendly above="md">{renderRightMenu()}</ShowCrawlerFriendly>
          </HStack>
        </Flex>

        {/* <Show below="lg">
          <ButtonsMenu
            items={linksNoAuth}
            buttonProps={{ ...menuButtonProps, fontSize: 13 }}
            mt={3}
            // mx={-4}
            overflowX="auto"
            spacing={0}
          />
        </Show> */}
      </MaxContainer>
    </Box>
  );
};

export default MainHeader;

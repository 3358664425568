import { EVENT_TYPE } from './libBackendConstants';

export const POOL_PLANNED_DATA = {
  nameShort: 'Event',
  fieldName: 'all',
};

export const EVENT_TYPE_DATA = {
  [EVENT_TYPE.DINNER]: {
    eventType: EVENT_TYPE.DINNER,
    nameShort: 'dinner',
    eventName: 'Dinner',
    locationText: cityName => `A surprise restaurant in ${cityName}`,
    peopleText: 'Tables of 6 matched by SocialTable',
    venue: 'restaurant',
    fieldName: 'dinner',
    icon: 'utensils',
  },
  [EVENT_TYPE.HAPPY_HOUR]: {
    eventType: EVENT_TYPE.HAPPY_HOUR,
    nameShort: 'happy hour',
    eventName: 'happy hour',
    locationText: cityName => `A surprise bar in ${cityName}`,
    peopleText: 'Tables of 6 matched by SocialTable',
    venue: 'location',
    fieldName: 'happy',
    icon: 'utensils',
  },
  [EVENT_TYPE.BRUNCH]: {
    eventType: EVENT_TYPE.BRUNCH,
    nameShort: 'brunch',
    eventName: 'Brunch',
    locationText: cityName => `A surprise restaurant in ${cityName}`,
    peopleText: 'Tables of 6 matched by SocialTable',
    venue: 'restaurant',
    fieldName: 'brunch',
    icon: 'utensils',
  },
  // [EVENT_TYPE.COFFEE]: {
  //   eventType: EVENT_TYPE.COFFEE,
  //   nameShort: 'coffee',
  //   themes_type: EVENT_TYPE.BRUNCH,
  //   eventName: 'Coffee',
  //   locationText: cityName => 'A surprise coffee shop chosen based on your preferences and wishlist.',
  //   peopleText: "We'll match you for a 1:1 coffee meeting according to your preferences.",
  //   venue: 'coffee shop',
  //   fieldName: 'coffee',
  //   icon: 'coffee',
  // },
};

export const getEventTypeData = eventType => {
  return eventType ? EVENT_TYPE_DATA[eventType] : POOL_PLANNED_DATA;
};

import React, { useEffect } from 'react';
import MyHelmet from '@/components/shared/MyHelmet';
import { lib, api, redux } from '@tlsocial/common';
import Spinner from '@/components/shared/Spinner';
import { MaxContainer, MaxContentContainer } from '@/components/shared/MaxContainer';
import { H1, H2, H3 } from '@/components/shared/Headings';
import * as c from '@/constants';
import { Box, Text, Show, Wrap, HStack, Flex, Center, useClipboard, VStack, Stack } from '@chakra-ui/react';
import { linksTo, links } from '@/constants';
import { HoverArea, Link } from '@/components/shared/Links';
import Icon from '@/components/shared/Icon';
import QRCode from 'react-qr-code';
import Photo from '@/components/shared/Photo';
import { AppStoreButtons } from './AppStoreLinks';
import ContentLoading from './ContentLoading';

export const InviteBox = props => {
  const { invite, isLoading, ...extra } = props;
  const { onCopy, value, setValue, hasCopied } = useClipboard(invite?.code);

  const renderCode = () => {
    return (
      <HoverArea
        onClick={onCopy}
        bg={lib.colors.primary600}
        hoverColor={lib.colors.primary900}
        px={6}
        py={3}
        borderRadius={10}
        minW="150px"
        align="center">
        {/* <Text fontSize={14} textAlign="center" fontWeight="bold" opacity={0.4} mb={2}>
          Invite Code
        </Text> */}
        <Text textAlign="center" fontSize={24} fontFamily={'Monaco'} color={'white'} fontWeight="900">
          {invite?.code}
        </Text>
        <Icon icon="copy" fontSize={24} color={lib.colors.primary500} mt={2} />
        {hasCopied && (
          <Text textAlign="center" fontSize={14} color={lib.colors.primary500} mt={2}>
            Copied!
          </Text>
        )}
      </HoverArea>
    );
  };

  return (
    <VStack bg={lib.colors.primary300} rounded={10} color="white" p={8} spacing={5} {...extra}>
      <Text textAlign="center" fontSize={20} fontWeight={900}>
        Download the app and enter invite code:
      </Text>

      {renderCode()}

      <Show below="sm">
        <AppStoreButtons height="50px" justify="center" />
      </Show>

      {invite.branch_url && (
        <Show above="sm">
          <VStack spacing={6}>
            {/* <Text textAlign="center" opacity={1} fontSize={18}>
              Download the app:
            </Text> */}
            <QRCode value={invite.branch_url} size={110} fgColor={lib.colors.primary900} />
          </VStack>
        </Show>
      )}
    </VStack>
  );
};

export const InviteBox2 = props => {
  const { invite, isLoading, ...extra } = props;
  const { onCopy, value, setValue, hasCopied } = useClipboard(invite?.code);

  const { title, description } = lib.useInvite(invite);

  if (!isLoading && !invite) {
    return null;
  }

  const renderCode = () => {
    return (
      <HoverArea
        onClick={onCopy}
        bg={lib.colors.primary800}
        hoverColor={lib.colors.primary900}
        px={6}
        py={3}
        borderRadius={10}
        minW="150px"
        align="center">
        {/* <Text fontSize={12} textAlign="center" opacity={0.5} mb={2}>
          Invite Code
        </Text> */}
        <Text textAlign="center" fontSize={20} fontFamily={'Monaco'} color={'white'}>
          {invite?.code}
        </Text>
        <Icon icon="copy" fontSize={24} color={lib.colors.primary500} mt={2} />
        {hasCopied && (
          <Text textAlign="center" fontSize={14} color={lib.colors.primary500} mt={2}>
            Copied!
          </Text>
        )}
      </HoverArea>
    );
  };

  return (
    <Box rounded={10} bg={lib.colors.primary700} p={5} color="white" {...extra}>
      <ContentLoading isLoading={isLoading} hasContent={invite}>
        <VStack rounded={10} spacing={5} flex={1} align="flex-start">
          <Text fontSize={22} fontWeight="bold">
            {title}
          </Text>
          {description && (
            <Text fontSize={15} opacity={1}>
              {description}
            </Text>
          )}

          <Wrap spacing={5}>
            <Show above="sm">
              <VStack spacing={6}>
                {/* <Text textAlign="center" opacity={1} fontSize={18}>
              Download the app:
            </Text> */}
                {invite?.branch_url && <QRCode value={invite?.branch_url} size={110} fgColor={lib.colors.primary900} />}
              </VStack>
            </Show>

            {renderCode()}
          </Wrap>

          <Show below="sm">
            <AppStoreButtons height="50px" />
          </Show>
        </VStack>
      </ContentLoading>
    </Box>
  );
};

import { links } from '@/constants';
import { useEffect, useState } from 'react';
import { getIpAddress, getPosthogFlags, getUniEvents, isWebCrawler, nextGetCity } from '@/utils';
import BaseHomePage from '@/components/home/HomePage';
import { apiSlice } from '@tlsocial/common/api';
import { store } from '@tlsocial/common/redux';

const endpoints = apiSlice.endpoints;
const dispatch = store.dispatch;

const getCityHomepageProps = async city => {
  if (!city) return {};
  // const { data: locations } = await dispatch(endpoints.getFeaturedLocations.initiate(city.id));
  // const { data: dataAmbassadors } = await dispatch(endpoints.getAmbassadors.initiate(city.id));
  // const ambassadors = dataAmbassadors?.profiles || [];
  const uniEvents = await getUniEvents(city.id);
  return { city, uniEvents };
};

export const getHomeServerProps = async context => {
  const isCrawler = isWebCrawler(context);
  if (isCrawler) return { props: { isCrawler } };

  const ipAddress = getIpAddress(context.req);

  const cityPromise = dispatch(
    endpoints.getClosestCity.initiate(ipAddress, {
      forceRefetch: true, // This bypasses the cache only for this query
    }),
  );

  const flagsPromise = getPosthogFlags(context);

  // Wait for both promises to resolve
  const [flagsResult, cityResult] = await Promise.all([flagsPromise, cityPromise]);

  const initialFlags = flagsResult?.data || {};
  const city = cityResult?.data;

  const cityProps = await getCityHomepageProps(city);

  return { props: { initialFlags, isCrawler, ipAddress, ...cityProps } };
};

export const getCityServerProps = async context => {
  const isCrawler = isWebCrawler(context);
  const { data: initialFlags = {} } = await getPosthogFlags(context);
  const city = await nextGetCity(context.params);
  const cityProps = await getCityHomepageProps(city);
  return { props: { initialFlags, isCrawler, ...cityProps } };
};

import { useRouter } from 'next/dist/client/router';
import { api, lib, redux } from '@tlsocial/common';
import { useAdminCity } from './useAdminCity';

export const usePoolAdmin = poolId => {
  const { city } = useAdminCity();

  const { currentData: pool, error } = api.useAdminGetPoolQuery(poolId, { skip: !poolId });
  const isLoading = !pool && !error;

  return { city, pool, isLoading };
};

export const usePoolRouteAdmin = () => {
  const router = useRouter();
  const { poolId: poolIdString } = router.query;
  const poolId = poolIdString && Number(poolIdString);

  return usePoolAdmin(poolId);
};

export const useProfileAdmin = () => {
  const router = useRouter();
  const { profileId } = router.query;
  const { data: profile, ...extra } = api.useAdminGetProfileQuery(profileId, { skip: !profileId });
  return { profile, ...extra };
};

export const useEventAdmin = () => {
  const router = useRouter();
  const { eventId: eventIdString } = router.query;
  const eventId = eventIdString && Number(eventIdString);
  const { data, ...extra } = api.useAdminGetEventQuery(eventId, { skip: !eventId });
  const event = data?.events?.[0];
  return { event, ...data, ...extra };
};

export const usePoolPeopleAdmin = pool => {
  const {
    registrations,
    d_registrations,
    d_profiles: d_registrationProfiles,
    isLoading: isLoadingReg,
  } = usePoolRegistrationsAdmin(pool);
  const {
    attendees,
    d_attendees,
    d_profiles: d_attendeeProfiles,
    isLoading: isLoadingAttendees,
  } = usePoolEventsAdmin(pool);

  const isLoading = isLoadingReg || isLoadingAttendees;

  return {
    registrations,
    d_registrations,
    attendees,
    d_attendees,
    d_registrationProfiles,
    d_attendeeProfiles,
    isLoading,
  };
};

export const usePoolRegistrationsAdmin = pool => {
  const { data, isLoading } = api.useAdminGetPoolRegistrationsQuery(pool?.id, { skip: !pool });
  const { d_registrations, d_profiles } = data || {};
  const registrations = Object.values(d_registrations || {});
  return { registrations, d_registrations, d_profiles, isLoading };
};

export const usePoolEventsAdmin = pool => {
  const { data, isLoading } = api.useAdminGetPoolEventsQuery(pool?.id, { skip: !pool });
  const { events, d_attendees, d_profiles } = data || {};
  const attendees = Object.values(d_attendees || {});
  return { events, attendees, d_attendees, d_profiles, isLoading };
};

export const usePoolEventDataAdmin = (pool, eventId) => {
  const { events, d_attendees, d_profiles, isLoading } = usePoolEventsAdmin(pool);
  const event = events?.find(e => e.id === Number(eventId));
  const attendees = Object.values(d_attendees || {}).filter(a => a.event_id === event.id);
  // const profiles = attendees.map(a => d_profiles[a.user_id]);
  // const hosts = attendees.filter(a => a.is_host).map(a => d_profiles[a.user_id]);

  return { event, attendees, d_profiles, isLoading };
};

import { lib, api, redux } from '@tlsocial/common';

export const useTopicProfiles = (city, topicId, isSelected) => {
  const { user } = lib.useUser();
  const skip = !topicId || !city?.id;
  const { data, ...extra } = api.useGetTopicProfilesQuery({ cityId: city?.id, topicId }, { skip });
  const { profiles = [], count = 0 } = data || {};
  const allProfiles = isSelected ? [user, ...profiles] : profiles;

  const profileCount = count + (isSelected ? 1 : 0);

  return { profiles: allProfiles, profileCount, ...extra };
};

import { Box, Image } from '@chakra-ui/react';
import React from 'react';

const Photo = props => {
  const { ...extra } = props;
  // { uri: source } needed if it's an external url
  // const imageSource = typeof source === 'string' && source.startsWith('http') ? { uri: source } : source;
  return <Image objectFit="cover" {...extra} />;
};

export default Photo;

import { api, lib } from '@tlsocial/common';
import { isAfter, addMinutes } from 'date-fns';

const filterUpcomingOrActive = now => {
  return event => {
    // Remove events that are more than 3 hours old
    const timeRemove = addMinutes(new Date(event.time_event), 180);
    return isAfter(timeRemove, new Date(now));
  };
};

export const useEventsAttending = cityId => {
  const currentTime = lib.useCurrentTimeMinutes();
  const { pools = [], isLoading: isLoadingPools } = lib.usePools(cityId, { skip: !cityId });
  const { data: d_poolRegistrations = {} } = api.useGetPoolRegistrationsQuery(cityId, { skip: !cityId });
  const { data = {} } = api.useGetEventsAttendingQuery();
  const { d_events = {} } = data;
  const events = Object.values(d_events);

  const eventsReviewNeeded = events.filter(event => event.isReviewNeeded).sort(lib.sortEvents);
  const eventReviewNeeded = eventsReviewNeeded[0];

  const eventsAttending = events.filter(filterUpcomingOrActive(currentTime)).sort(lib.sortEvents);

  const poolsAttending = pools.filter(pool => d_poolRegistrations[pool.id]?.is_registered);

  const allAttending = [...eventsAttending, ...poolsAttending].sort(lib.sortEvents);

  return { eventReviewNeeded, allAttending, eventsAttending, poolsAttending };
};

export const useUpcoming = cityId => {
  const { user } = lib.useUser();
  const { pools: poolsUpcoming = [], isLoading: isLoadingPools } = lib.usePools(cityId);
  const { events = [], isLoading: isLoadingProposals } = lib.useUpcomingEvents(cityId);
  const { poolsAttending } = useEventsAttending(cityId);
  // const eventsAttendingIds = eventsAttending.map(e => e.id);
  const poolsAttendingIds = poolsAttending.map(p => p.id);
  const isLoading = isLoadingPools || isLoadingProposals;
  // const events = eventsUpcoming.filter(event => !eventsAttendingIds.includes(event.id));
  // const pools = poolsUpcoming.filter(pool => !poolsAttendingIds.includes(pool.id));

  const poolFeatured =
    (user.is_member || user.has_guest_pool_trial_available) &&
    poolsUpcoming.filter(
      pool =>
        pool.status === lib.POOL_STATUS.OPEN &&
        !pool.is_invite_only &&
        !poolsAttendingIds.includes(pool.id) &&
        lib.isGenderPolicyOkay(pool, user),
    )[0];

  const poolsFinal = poolsUpcoming.filter(pool => pool.id !== poolFeatured?.id);

  const allUpcoming = [...events, ...poolsFinal].sort(lib.sortEvents);

  return { allUpcoming, poolFeatured, isLoading };
};

export const useAttendingCount = cityId => {
  const { allAttending } = useEventsAttending(cityId);
  return allAttending.length;
};

import { apiSlice } from './apiSlice';
import { updateLocationPicksRTK } from './rtkUpdates';

export const apiLocations = apiSlice.injectEndpoints({
  endpoints: build => ({
    getLocation: build.query({ query: id => `/locations/${id}` }),
    getAllLocations: build.query({ query: cityId => `/locations/city/${cityId}/all` }),
    getFeaturedLocations: build.query({ query: cityId => `/locations/city/${cityId}/featured` }),
    getMyLocationPicks: build.query({ query: cityId => `/locations/city/${cityId}/my_picks` }),
    addLocationFromSearch: build.mutation({
      query: body => ({ url: `/locations/add_from_search`, method: 'PUT', body }),
      async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateLocationPicksRTK(data));
        } catch (error) {}
      },
    }),
    rateLocation: build.mutation({
      query: body => ({ url: `locations/${body.location_id}/rate`, method: 'PUT', body }),
      async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateLocationPicksRTK(data));
        } catch (error) {}
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetLocationQuery,
  useGetAllLocationsQuery,
  useGetFeaturedLocationsQuery,
  useGetMyLocationPicksQuery,
  useAddLocationFromSearchMutation,
  useRateLocationMutation,
} = apiLocations;

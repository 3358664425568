// import { RESULTS } from 'react-native-permissions';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  networkConnected: true,
  permissions: {
    notifications: null,
    location: null,
    photos: null,
    contacts: null,
    calendar: null,
  },
  granted: {
    notifications: false,
    location: false,
    photos: false,
    contacts: false,
    calendar: false,
  },
  needed: {
    notifications: false,
    location: false,
    photos: false,
    contacts: false,
    calendar: false,
  },
  deviceLat: null,
  deviceLng: null,
  navBack: false,
};

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setNetworkStatus: (state, action) => {
      state.networkConnected = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
      state.granted = {
        notifications: action.payload.notifications === 'granted',
        location: action.payload.location === 'granted',
        photos: action.payload.photos === 'granted' || action.payload.photos === 'limited',
        contacts: action.payload.contacts === 'granted',
        calendar: action.payload.calendar === 'granted',
      };
      state.needed = {
        notifications: action.payload.notifications !== 'granted',
        location: action.payload.location !== 'granted',
        photos: action.payload.photos !== 'granted' && action.payload.photos !== 'limited',
        contacts: action.payload.contacts !== 'granted',
        calendar: action.payload.calendar !== 'granted',
      };
    },
    setLocationCoords: (state, action) => {
      state.deviceLat = action.payload.deviceLat;
      state.deviceLng = action.payload.deviceLng;
    },
    setNavBack: (state, action) => {
      state.navBack = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setNetworkStatus,
  setPermissions,
  setLocationCoords,
  setActiveScreen,
  setLastAnalyticsEventTime,
  setNavBack,
} = deviceSlice.actions;

export default deviceSlice.reducer;

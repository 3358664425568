import { useSocialTable } from '@/components/main/SocialTableContext';
import { lib } from '@tlsocial/common';
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react';
import { useSelector } from 'react-redux';

const useGetVariant = featureKey => {
  const reduxFlags = useSelector(state => state.local.reduxPosthogFlags || {});
  const { initialFlags } = useSocialTable();
  const flags = initialFlags || reduxFlags;
  // const posthog = usePostHog();
  // console.log('useVariant', reduxFlags, initialFlags);
  // const isClient = typeof window !== 'undefined';
  // return isClient ? posthog.getFeatureFlag(featureKey) : initialFlags[featureKey];
  return flags[featureKey] || 'control';
};

export const useVariantString = (featureKey, replacements = {}) => {
  const variant = useGetVariant(featureKey);
  return lib.getPosthogVariantString(featureKey, variant, replacements);
};

export const useVariantBool = featureKey => {
  const variant = useGetVariant(featureKey);
  return variant === 'test';
};

export const useVariant = featureKey => {
  const variant = useGetVariant(featureKey);
  return variant;
};

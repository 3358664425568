import { lib } from '@tlsocial/common';
import { useAnalyticsPlatform } from './useAnalyticsPlatform';

export const useAnalytics = () => {
  const platform = useAnalyticsPlatform();

  const trackPool = (pool, eventName, params) => {
    const finalParams = { objId: pool.id, objName: pool.title, ...params };
    platform.track(eventName, finalParams);
  };

  const trackEvent = (event, eventName, params) => {
    const finalParams = { objId: event.id, objName: event.title, ...params };
    platform.track(eventName, finalParams);
  };

  const trackInvite = (code, eventName, params) => {
    const finalParams = { objId: code, objName: code, ...params };
    platform.track(eventName, finalParams);
  };

  const trackProfile = (profile, eventName, params) => {
    const finalParams = { objId: profile.id, objName: profile.full_name, ...params };
    platform.track(eventName, finalParams);
  };

  const trackLocation = (location, eventName, params) => {
    const finalParams = { objId: location.id, objName: location.name, ...params };
    platform.track(eventName, finalParams);
  };

  const trackTopic = (topic, eventName, params) => {
    const finalParams = { objId: topic.id, objName: topic.name, ...params };
    platform.track(eventName, finalParams);
  };

  const trackClub = (club, eventName, params) => {
    const finalParams = { objId: club.id, objName: club.name, ...params };
    platform.track(eventName, finalParams);
  };

  const getUserProperties = (user, city) => {
    return {
      id: user.id,
      name: user.full_name,
      email: user.email,
      phone: user.phone,
      city_id: user.city_id,
      city_key: city?.city_key,
      gender: user.gender,
      dob: user.dob,
      age: lib.getAge(user),
      time_created: user.time_created,
      is_staff: user.is_staff,
    };
  };

  const identifyUser = (user, city) => {
    if (user && (!user.city_id || city)) {
      const userProperties = getUserProperties(user, city);
      platform.identifyUser(user.id, userProperties);
    }
  };

  return {
    ...platform,
    trackPool,
    trackEvent,
    trackInvite,
    trackProfile,
    trackLocation,
    trackTopic,
    trackClub,
    identifyUser,
  };
};

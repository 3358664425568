const currencySymbol = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  CAD: '$',
  AUD: '$',
  THB: '฿',
  IDR: '$',
  SGD: '$',
  KRW: '₩',
};

export const citySymbol = city => {
  return currencySymbol[city?.city_currency] || '$';
};

import { Box } from '@chakra-ui/react';
import React from 'react';
import Spinner from './Spinner';
import Button from '@/components/shared/Button';
import { useIsFullWidth } from '@/hooks';

export const ItemPagination = props => {
  const { pagination, Component, noResultsText, skipNone, forceShowComponent, ...extraProps } = props;
  // const isDesktop = useIsFullWidth('md');
  // const Component2 = isDesktop && DesktopComponent ? DesktopComponent : Component;
  const showComponent = pagination.visibleItems.length > 0 || forceShowComponent;

  if (pagination.noResults && !forceShowComponent) {
    return !skipNone && <Box>{noResultsText || 'No results'}</Box>;
  } else {
    return (
      <>
        {showComponent && <Component items={pagination.visibleItems} {...extraProps} />}
        <Box mt={3}>
          {pagination.isLoading ? (
            <Spinner />
          ) : (
            !pagination.reachedTheEnd && <Button onClick={pagination.loadMore} label="Load more..." variant="ghost" />
          )}
        </Box>
      </>
    );
  }
};

const ContentLoading = props => {
  const { isLoading, empty, renderContent, children, hasContent, ...extraProps } = props;

  const hasContent2 = Array.isArray(hasContent) ? hasContent.length !== 0 : Boolean(hasContent);

  const renderInner = () => {
    if (isLoading) {
      return <Spinner />;
    } else if (!hasContent2) {
      return <Box>{empty || 'Nothing yet'}</Box>;
    } else {
      return (
        <>
          {renderContent && renderContent()}
          {children}
        </>
      );
    }
  };

  return <Box {...extraProps}>{renderInner()}</Box>;
};

export default ContentLoading;

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAsyncFunc } from './useAsync';
import { useBreakpointValue } from '@chakra-ui/react';
import { MODALS } from '@/constants';
import { BaseModal, ConfirmModal, InvitedModal } from '@/components/shared/Modals';

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [options, setOptions] = useState({});

  const open = useCallback((type, options) => {
    setModalType(type);
    setOptions(options);
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
    setModalType(null);
    setOptions({});
  }, []);

  const renderModalType = () => {
    switch (modalType) {
      case MODALS.ADMIN_INVITED:
        return <InvitedModal onClose={close} options={options} />;
      case MODALS.CONFIRM:
        return <ConfirmModal onClose={close} options={options} />;
      default:
        return null;
    }
  };

  const render = () => {
    return (
      <BaseModal isOpen={isOpen} onClose={close} title={options?.title} body={options?.body}>
        {renderModalType()}
      </BaseModal>
    );
  };

  return { open, close, render };
};

export const useConfirmModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState({});
  const [confirmAction, setConfirmAction] = useState(() => () => {});

  const open = (options, action) => {
    setConfirmAction(() => action);
    setOptions({ ...options, title: options?.title || 'Are you sure?' });
    setIsOpen(true);
  };

  const close = useCallback(() => {
    if (!isLoading) {
      setIsOpen(false);
      setOptions({});
      setConfirmAction(() => () => {});
    }
  }, []);

  const onConfirm = async () => {
    setIsLoading(true);
    await confirmAction();
    setIsLoading(false);
    close();
  };

  const render = () => {
    return (
      <BaseModal isOpen={isOpen} onClose={close} title={options?.title} body={options?.body}>
        <ConfirmModal onClose={close} options={{ ...options, onConfirm, isLoading }} />
      </BaseModal>
    );
  };

  return { open, render };
};

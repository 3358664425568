import { lib, api, redux } from '@tlsocial/common';

export const BUGSNAG_KEY = 'ab2543ac86e8fca273879fb11d16035d';

const devConfig = {
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyB3dV08NTXTResqemzedlMKlWhZRMZbxJI',
    authDomain: 'tl-social-test.firebaseapp.com',
    projectId: 'tl-social-test',
    storageBucket: 'tl-social-test.appspot.com',
    messagingSenderId: '794328026707',
    appId: '1:794328026707:web:be5d38168cead262d640a6',
    measurementId: 'G-573DPZ5JW8',
  },
};

const prodConfig = {
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyALsSLhPvaEEwTCM3JO7tvkPW-47AIclGo',
    authDomain: 'tl-social-prod.firebaseapp.com',
    projectId: 'tl-social-prod',
    storageBucket: 'tl-social-prod.appspot.com',
    messagingSenderId: '236317850924',
    appId: '1:236317850924:web:9613eeadf9e3a5412d8089',
    measurementId: 'G-BYZTXYJSMN',
  },
};

export const env = {
  ...lib.env,
  ...(lib.env.IS_PRODUCTION ? prodConfig : devConfig),
};

export const DJANGO_ADMIN_URL = lib.env.IS_PRODUCTION ? 'https://chef.socialtable.app' : 'http://localhost:8000';

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { chakra } from '@chakra-ui/react';

const Icon = props => {
  const ChakraIcon = chakra(FontAwesomeIcon);

  const { icon, brand, solid, regular, fontSize = "1em", ...extra } = props;
  const type = brand ? 'fab' : regular ? 'far' : solid ? 'fas' : 'fal';
  const icon2 = [type, icon];

  return <ChakraIcon icon={icon2} fontSize={fontSize} {...extra} />;
};

export default Icon;

export const tags = {
  CITY: 'CITY',
  PROFILE_VOUCHES_RECEIVED: 'PROFILE_VOUCHES_RECEIVED',
  POOLS_UPCOMING: 'POOLS_UPCOMING',
  POOL_REGISTRATIONS: 'POOL_REGISTRATIONS',
  EVENTS_UPCOMING: 'EVENTS_UPCOMING',
  EVENTS_ATTENDING: 'EVENTS_ATTENDING',
  EVENTS_HOSTING: 'EVENTS_HOSTING',
  EVENT_REGISTRATIONS: 'EVENT_REGISTRATIONS',
  CLUBS: 'CLUBS',
  COMMUNITIES: 'COMMUNITIES',
  COMMUNITY_MEMBERS: 'COMMUNITY_MEMBERS',
  INVITES_USER_REFERRAL: 'INVITES_USER_REFERRAL',
  INVITES_PAIR: 'INVITES_PAIR',
  INVITES_HOST: 'INVITES_HOST',
  INVITES_EVENT: 'INVITES_EVENT',
  INVITE: 'INVITE',
  USER: 'USER',
  USER_DATA: 'USER_DATA',
  USER_ATTRIBUTES: 'USER_ATTRIBUTES',
  CURRENCY_PREFS: 'CURRENCY_PREFS',
  MY_PAYMENT_METHODS: 'MY_PAYMENT_METHODS',
  MY_APPRECIATIONS: 'USER_APPRECIATIONS',
  MY_BELLS: 'MY_BELLS',
  MY_CHATS: 'MY_CHATS',
  SUGGESTED: 'SUGGESTED',
  MOST_SIMILAR: 'MOST_COMPATIBLE',
  CONTACTS: 'CONTACTS',
  CONTACT_VOUCHES: 'CONTACT_VOUCHES',
  USER_VOUCHES: 'USER_VOUCHES',
  ADMIN_PROFILE: 'ADMIN_PROFILE',
  ADMIN_MEMBERS_RECENT: 'ADMIN_MEMBERS_RECENT',
  ADMIN_EVENTS_MATCHED: 'ADMIN_EVENTS_MATCHED',
  ADMIN_EVENTS_UPCOMING: 'ADMIN_EVENTS_HOSTED_UPCOMING',
  ADMIN_EVENTS_PAST: 'ADMIN_EVENTS_HOSTED_PAST',
  ADMIN_EVENT: 'ADMIN_EVENT',
  ADMIN_POOLS_UPCOMING: 'ADMIN_POOLS_UPCOMING',
  ADMIN_POOLS_PAST: 'ADMIN_POOLS_PAST',
  ADMIN_POOLS: 'ADMIN_POOLS',
  ADMIN_CLUBS: 'ADMIN_CLUBS',
  ADMIN_LOGS: 'ADMIN_LOGS',
  ADMIN_ALL_LOCATIONS: 'ADMIN_ALL_LOCATIONS',
  ADMIN_FEATURED_LOCATIONS: 'ADMIN_FEATURED_LOCATIONS',
  ADMIN_RESERVATIONS: 'ADMIN_RESERVATIONS',
  ADMIN_POOL: 'ADMIN_POOL',
  ADMIN_POOL_REGISTRATIONS: 'ADMIN_POOL_REGISTRATIONS',
  ADMIN_POOL_EVENTS: 'ADMIN_POOL_EVENTS',
  ADMIN_POOL_LOCATIONS_DATA: 'ADMIN_POOL_LOCATIONS_DATA',
  ADMIN_EVENT_LOCATIONS_DATA: 'ADMIN_EVENT_LOCATIONS_DATA',
  ADMIN_POOL_RESERVATIONS: 'ADMIN_POOL_RESERVATIONS',
  ADMIN_APPLICANTS_RECENT: 'ADMIN_APPLICANTS_RECENT',
  ADMIN_APPLICANTS_TO_APPROVE: 'ADMIN_APPLICANTS_QUEUED',
  ADMIN_APPLICANTS_ACCEPTING: 'ADMIN_APPLICANTS_ACCEPTING',
};

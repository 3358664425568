import { lib, api, redux } from '@tlsocial/common';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useUser } from './useUser';
import { useEffect } from 'react';

export const useInvite = invite => {
  if (!invite) {
    return {};
  }

  const defaultTitle =
    invite.invite_type === lib.INVITE_TYPE.USER
      ? `${invite.fromName} invited you to SocialTable!`
      : invite.invite_type === lib.INVITE_TYPE.CONTACT
      ? `${invite.fromName} invited you to SocialTable!`
      : invite.invite_type === lib.INVITE_TYPE.PAIR
      ? `${invite.fromName} invited you to attend an event together!`
      : invite.invite_type === lib.INVITE_TYPE.HOST
      ? `${invite.fromName} invited you to co-host an event!`
      : invite.invite_type === lib.INVITE_TYPE.EVENT
      ? `${invite.fromName} invited you to an event!`
      : invite.invite_type === lib.INVITE_TYPE.POOL
      ? `You're invited!`
      : invite.invite_type === lib.INVITE_TYPE.MULTI
      ? "You're invited!"
      : invite.has_referral
      ? `${invite.fromName} invited you to SocialTable!`
      : "You're invited!";

  const defaultDescription =
    invite.invite_type === lib.INVITE_TYPE.USER
      ? ``
      : invite.invite_type === lib.INVITE_TYPE.CONTACT
      ? ``
      : invite.invite_type === lib.INVITE_TYPE.PAIR
      ? `You'll be registering as a pair to get matched for a curated 4-8 person dinner. SocialTable curates intimate dinners designed for meaningful connections and magical moments!`
      : invite.invite_type === lib.INVITE_TYPE.HOST
      ? 'Download SocialTable and enter the code to accept.'
      : invite.invite_type === lib.INVITE_TYPE.EVENT
      ? `Download SocialTable and enter the code to join in!`
      : invite.invite_type === lib.INVITE_TYPE.POOL
      ? `Download SocialTable and enter the code to join the pool!`
      : invite.invite_type === lib.INVITE_TYPE.MULTI
      ? "You're invited!"
      : invite.has_referral
      ? 'SocialTable curates intimate 4-8 person dinners designed for meaningful connections and magical moments! ✨'
      : "You're invited!";

  const title = invite?.title || defaultTitle;
  const description = invite?.description || defaultDescription;

  return { title, description };
};

export const useInviteLastOpened = () => {
  const inviteLastOpened = useSelector(state => state.local.inviteLastOpened);
  return lib.useInviteCode(inviteLastOpened);
};

export const useInviteEvent = eventId => {
  const { invite } = useInviteLastOpened();
  if (eventId && invite && invite.invite_type === lib.INVITE_TYPE.EVENT && invite.event_id === eventId) {
    return invite;
  }
};

export const useInvitePool = poolId => {
  const { invite } = useInviteLastOpened();
  if (poolId && invite && invite.invite_type === lib.INVITE_TYPE.POOL && invite.pool_id === poolId) {
    return invite;
  }
};

export const useResetInviteCodes = () => {
  const dispatch = useDispatch();
  // const inviteLastOpened = useSelector(state => state.local.inviteLastOpened);
  // const pairInviteCodeAccepting = useSelector(state => state.local.pairInviteCodeAccepting);
  const resetInviteCodes = code => {
    dispatch(redux.setInviteCodeOpened(null));
    dispatch(redux.setPairInviteCodeAccepting(null));
    // if (code === inviteLastOpened) {
    //   dispatch(redux.setInviteCodeOpened(null));
    // }
    // if (code === pairInviteCodeAccepting) {
    //   dispatch(redux.setPairInviteCodeAccepting(null));
    // }
  };
  return resetInviteCodes;
};

export const useInviteCode = code => {
  const { user } = useUser();
  const dispatch = useDispatch();
  const resetInviteCodes = useResetInviteCodes();

  // console.log('useInviteCode', code);

  // const localCode = useSelector(state => state.local.inviteCode);
  // const code = inviteCode || localCode;
  const { data: invites, isError, isLoading, isUninitialized, ...extra } = api.useGetInviteQuery(code, { skip: !code });

  const isLoadingInvite = isLoading || isUninitialized;

  useEffect(() => {
    if (isError) {
      resetInviteCodes(code);
    }
  }, [isError]);

  // console.log('useInviteCode', { code, invites, isError });

  const invite = code && invites?.[0];

  return {
    invite,
    isLoadingInvite,
    ...extra,
  };
};

import { MaxContainer } from '@/components/shared/MaxContainer';
import React from 'react';
import MainHeader from './MainHeader';
import { links, gs } from '@/constants';
import { MainFooter } from './MainFooter';
import { Box, Flex } from '@chakra-ui/react';
import { lib } from '@tlsocial/common';

const PageTemplate = props => {
  const { city } = props.pageProps;

  return (
    <Flex minHeight="100vh" direction="column">
      <MainHeader city={city} />
      <Box flex={1} bg={'white'} overflowY="auto">
        {props.children}
      </Box>
      <MainFooter city={city} />
    </Flex>
  );
};
export default PageTemplate;

export const getTemplate = (page, pageProps) => <PageTemplate pageProps={pageProps}>{page}</PageTemplate>;

import { updateUserRTK } from './rtkUpdates';
import { apiSlice } from './apiSlice';
import { tags } from './rtkTags';
import { transformUserData } from './apiConstruct';
import { lib, redux } from '@tlsocial/common';

const onQueryReplaceUser = async (dataIn, { dispatch, queryFulfilled }) => {
  try {
    const { data } = await queryFulfilled;
    dispatch(updateUserRTK(data.user));
  } catch (error) {}
};

const onQueryChangeCity = async (dataIn, { dispatch, queryFulfilled }) => {
  try {
    const { data } = await queryFulfilled;
    dispatch(updateUserRTK(data.user));
    // Reset all local city state
    dispatch(redux.resetSuggestedState());
    dispatch(redux.resetcityLocalState());
  } catch (error) {}
};

export const apiAccount = apiSlice.injectEndpoints({
  endpoints: build => ({
    getUser: build.query({
      query: () => `/account/current_user`,
      providesTags: [tags.USER],
    }),
    getUserData: build.query({
      query: () => `/account/current_user_data`,
      transformResponse: transformUserData,
      providesTags: [tags.USER_DATA, tags.MY_PAYMENT_METHODS, tags.MY_APPRECIATIONS, tags.MY_BELLS],
    }),
    getAttributes: build.query({
      query: () => `/account/attributes`,
      providesTags: [tags.USER_ATTRIBUTES],
    }),
    getCurrencyPrefs: build.query({
      query: cityId => `/account/currency_prefs/${cityId}`,
      providesTags: [tags.CURRENCY_PREFS],
    }),
    updateCurrentUser: build.mutation({
      query: body => ({ url: `/account/update_current_user`, method: 'PUT', body }),
      onQueryStarted: onQueryReplaceUser,
      invalidatesTags: [tags.CLUBS],
    }),
    updateLastActive: build.mutation({
      query: () => ({ url: `/account/update_last_active`, method: 'PUT' }),
    }),
    changeCity: build.mutation({
      query: body => ({ url: `/account/change_city`, method: 'PUT', body }),
      onQueryStarted: onQueryChangeCity,
    }),
    changeCityCurrentLocation: build.mutation({
      query: body => ({ url: `/account/change_city_current_location`, method: 'PUT', body }),
      onQueryStarted: onQueryChangeCity,
    }),
    uploadPhoto: build.mutation({
      query: body => ({ url: `/account/upload_photo`, method: 'POST', body }),
      onQueryStarted: onQueryReplaceUser,
    }),
    setOnboardingDone: build.mutation({
      query: () => ({ url: `/account/set_onboarding_done`, method: 'PUT' }),
      onQueryStarted: onQueryReplaceUser,
    }),
    openSupportChat: build.mutation({
      query: () => ({ url: `/account/open_support_chat`, method: 'PUT' }),
      onQueryStarted: onQueryReplaceUser,
    }),
    setPlatformPayPrimary: build.mutation({
      query: body => ({ url: `/account/set_platform_pay_primary`, method: 'PUT', body }),
      onQueryStarted: onQueryReplaceUser,
    }),
    updateUserCuisine: build.mutation({
      query: body => ({ url: `/account/update_user_cuisine`, method: 'PUT', body }),
      async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            apiSlice.util.updateQueryData('getUserData', undefined, state => {
              state.userCuisine = lib.arrayToObjectValues(data.user_cuisine, 'cuisine_id', 'level');
            }),
          );
        } catch (error) {}
      },
    }),
    updateUserAttributes: build.mutation({
      query: body => ({ url: `/account/update_user_attributes`, method: 'PUT', body }),
      invalidatesTags: [tags.USER_ATTRIBUTES, tags.USER],
      // async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
      //   try {
      //     const { data } = await queryFulfilled;
      //     dispatch(
      //       apiSlice.util.updateQueryData('getUserData', undefined, state => {
      //         state.userGoals = lib.arrayToObjectValues(data.user_goals, 'goal_id', 'level');
      //       }),
      //     );
      //   } catch (error) {}
      // },
    }),
    updateEventPrefsBase: build.mutation({
      query: body => ({ url: `/account/update_event_prefs_base`, method: 'PUT', body }),
      async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateUserRTK(data.user));
          dispatch(
            apiSlice.util.updateQueryData('getUserData', undefined, state => {
              state.eventPrefs = data.event_prefs_base;
            }),
          );
        } catch (error) {}
      },
    }),
    updateCurrencyPrefs: build.mutation({
      query: body => ({ url: `/account/update_currency_prefs`, method: 'PUT', body }),
      invalidatesTags: [tags.CURRENCY_PREFS],
    }),
    updateUserHoods: build.mutation({
      query: body => ({ url: `/account/update_user_hoods`, method: 'PUT', body }),
    }),
    updateUserPermissions: build.mutation({
      query: body => ({ url: `/account/update_user_permissions`, method: 'PUT', body }),
    }),
    unsubscribe: build.mutation({
      query: body => ({ url: `/account/email_unsubscribe`, method: 'PUT', body }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetUserDataQuery,
  useGetUserQuery,
  useGetAttributesQuery,
  useGetCurrencyPrefsQuery,
  useUpdateCurrentUserMutation,
  useUpdateLastActiveMutation,
  useChangeCityCurrentLocationMutation,
  useChangeCityMutation,
  useUploadPhotoMutation,
  useSetOnboardingDoneMutation,
  useOpenSupportChatMutation,
  useSetPlatformPayPrimaryMutation,
  useUpdateUserCuisineMutation,
  useUpdateUserAttributesMutation,
  useUpdateEventPrefsBaseMutation,
  useUpdateCurrencyPrefsMutation,
  useUpdateUserHoodsMutation,
  useUpdateUserPermissionsMutation,
  useUnsubscribeMutation,
} = apiAccount;

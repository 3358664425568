import { useDispatch, useSelector } from 'react-redux';
import { lib, api, redux } from '@tlsocial/common';

export const useCityUpdate = update => {
  const imageSizes = lib.getImageSizes(update.image_url);

  return {
    update,
    ...imageSizes,
  };
};

const NEW = 1;
const INVITE_SENT = 4;
const INVITE_OPENED = 5;
const LOGGED_IN = 6;
const ONBOARDED = 7;
const PROFILE_COMPLETED = 8;
const REGISTERED_FOR_EVENT = 9;
const REFERRED_MEMBER = 10;

export const getUserJourneyStep = (profile, invite) => {
  let step = null;
  if (profile?.stats_invited > 0) {
    step = REFERRED_MEMBER;
  } else if (profile?.is_registered_first_event) {
    step = REGISTERED_FOR_EVENT;
  } else if (profile?.is_profile_completed) {
    step = PROFILE_COMPLETED;
  } else if (profile?.is_onboarded) {
    step = ONBOARDED;
  } else if (profile) {
    step = LOGGED_IN;
  } else if (invite?.click_count > 0) {
    step = INVITE_OPENED;
  } else if (invite) {
    step = INVITE_SENT;
  } else {
    step = NEW;
  }
  return step;
};

import { lib } from '@tlsocial/common';
import { apiSlice } from '@tlsocial/common/api';
import { store } from '@tlsocial/common/redux';
import { mergePoolsAndEvents } from '../utils/eventUtils';
import { serialize } from 'cookie';

const endpoints = apiSlice.endpoints;
const dispatch = store.dispatch;

export const nextGetCity = async params => {
  const cityKey = params?.cityKey;
  if (cityKey) {
    const { data: city = null } = await dispatch(endpoints.getCityFromKey.initiate(cityKey));
    return city;
  }
  return null;
};

export const getUniEvents = async cityId => {
  const { data: pools } = await dispatch(endpoints.getPools.initiate(cityId));
  const { data: events } = await dispatch(endpoints.getEventsUpcoming.initiate(cityId));
  const uniEvents = mergePoolsAndEvents(pools, events);
  return uniEvents;
};

export const getPosthogFlags = context => {
  if (isWebCrawler(context)) return;

  const COOKIE_NAME = 'st_distict_id';
  let distinctId = context.req.cookies[COOKIE_NAME];
  if (!distinctId) {
    distinctId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    context.res.setHeader('Set-Cookie', serialize(COOKIE_NAME, distinctId, { path: '/', httpOnly: false }));
  }

  const flagsPromise = dispatch(endpoints.featureEvaluation.initiate(distinctId, { forceRefetch: true }));
  return flagsPromise;
};

export const getIpAddress = req => {
  const forwarded = req.headers['x-forwarded-for'];
  const ip = typeof forwarded === 'string' ? forwarded.split(/, /)[0] : req.socket.remoteAddress;
  return ip;
};

export const isWebCrawler = context => {
  const userAgent = context.req.headers['user-agent'] || '';
  const searchEngineBots = [
    'googlebot',
    'bingbot',
    'baiduspider',
    'yandexbot',
    'duckduckbot',
    'adsbot-google',
    'apis-google',
    'duplexweb-google',
    'feedfetcher-google',
    'google favicon',
    'google web preview',
    'google-read-aloud',
    'googleweblight',
    'mediapartners-google',
    'storebot-google',
    'ahrefsbot',
    'applebot',
    'bingpreview',
    'bot.htm',
    'bot.php',
    'crawler',
    'facebookexternal',
    'facebookcatalog',
    'gptbot',
    'hubspot',
    'linkedinbot',
    'mj12bot',
    'petalbot',
    'pinterest',
    'prerender',
    'rogerbot',
    'screaming frog',
    'semrushbot',
    'sitebulb',
    'twitterbot',
    'yahoo! slurp',
    'whatsapp',
    'instagram',
    'snapchat',
    'tiktokbot',
    'facebookexternalhit',
    'pinterestbot',
    'slackbot-linkexpanding',
    'telegrambot',
    'discordbot',
    'applenewsbot',
    'cloudflare-alwaysonline',
  ];

  return searchEngineBots.some(bot => userAgent.toLowerCase().includes(bot));
};

import { lib, api, redux } from '@tlsocial/common';
import { useRouter } from 'next/router';

export const useWebCity = () => {
  const router = useRouter();
  const { cityKey } = router.query;
  const { data: city, ...extra } = api.useGetCityFromKeyQuery(cityKey, { skip: !cityKey });

  const xCity = lib.useCity(city);

  return { ...xCity, ...extra };
};

export const useClosestCity = () => {
  const { data: city, ...extraClosest } = api.useGetClosestCityQuery();

  // const skipDefault = extraClosest.isLoading || extraClosest.isFetching || !defaultCityId;
  // const { data: cityDefault, ...extraDefault } = api.useGetCityQuery(defaultCityId, { skip: skipDefault });
  // const city = cityClosest?.id ? cityClosest : cityDefault;
  // const isLoading = extraClosest.isLoading || extraDefault.isLoading;
  const isLoading = extraClosest.isLoading;

  const xCity = lib.useCity(city);
  return { ...xCity, isLoading };
};

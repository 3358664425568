import { useDispatch, useSelector } from 'react-redux';
import { lib, api, redux } from '@tlsocial/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const getLocationDetailLine = (city, location, cuisine) => {
  if (cuisine) {
    const symbol = lib.citySymbol(city);
    const activeCuisine = lib.getLocationCuisine(location, cuisine);
    const cuisineLine = activeCuisine.join(', ');
    const dollarsText = location.price && symbol.repeat(location.price);
    if (dollarsText && cuisineLine) {
      return `${dollarsText} ${' '}•${' '} ${cuisineLine}`;
    } else {
      return dollarsText || cuisineLine;
    }
  }
  return null;
};

export const useLocation = location => {
  const { user } = lib.useUser();
  const { city } = lib.useCityId(location.city_id);
  const { trackLocation, ev } = lib.useAnalytics();
  const { t } = useTranslation();
  const [rateLocation] = api.useRateLocationMutation();
  const skip = !user?.id || !city?.id;
  const { data: picks = [] } = api.useGetMyLocationPicksQuery(city?.id, { skip });
  const optimisticLocation = useSelector(state => state.relations.optimisticLocation);

  const { data: { cuisine } = {}, isLoading: isLoadingAppData } = api.useGetAppDataQuery();

  const imageSizes = lib.getImageSizes(location.image_url);

  // Use the value in optimistic state if exists
  const isSelected = optimisticLocation[location.id] ?? Boolean(picks?.find(r => r.id === location.id));
  const membersCountText = t(`location.memberCount`, { count: location.count_fav });

  const cuisineDetails = getLocationDetailLine(city, location, cuisine);

  const toggleFavorite = () => {
    const newSelected = !isSelected;
    rateLocation({ location_id: location.id, is_favorite: newSelected });
    trackLocation(location, newSelected ? ev.location_liked : ev.location_unliked);
  };

  return {
    location,
    isSelected,
    membersCountText,
    toggleFavorite,
    ...imageSizes,
    cuisineDetails,
  };
};

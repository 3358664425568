import { lib } from '@tlsocial/common';
import { apiSlice } from '@tlsocial/common/api';
import { store } from '@tlsocial/common/redux';
import { getUniEvents, nextGetCity } from './nextServerUtils';

const NEXTJS_REVALIDATION_SECONDS = 3600;

const endpoints = apiSlice.endpoints;
const dispatch = store.dispatch;

export async function getEmptyProps() {
  return { props: {} };
}

export async function getLocationProps({ params }) {
  // const id = parseInt(params.id);
  const id = parseInt(params.slug.match(/[0-9]+$/));
  const { data: location } = await dispatch(endpoints.getLocation.initiate(id));
  const { data: city } = await dispatch(endpoints.getCity.initiate(location.city_id));
  const { data: { profiles } = {} } = await dispatch(endpoints.getLocationProfiles.initiate(location.id));
  await Promise.all(dispatch(apiSlice.util.getRunningQueriesThunk()));
  const props = { location, city, profiles };
  return { props, revalidate: NEXTJS_REVALIDATION_SECONDS }; // NextJs will re-generate the page when a request comes in after x seconds
}

export async function getCitiesProps({ params }) {
  const city = await nextGetCity(params);
  const { data: cities } = await dispatch(endpoints.getActiveCities.initiate());
  await Promise.all(dispatch(apiSlice.util.getRunningQueriesThunk()));
  return { props: { city, cities }, revalidate: NEXTJS_REVALIDATION_SECONDS };
}

export async function getCityOnlyProps({ params }) {
  const city = await nextGetCity(params);
  await Promise.all(dispatch(apiSlice.util.getRunningQueriesThunk()));
  return { props: { city }, revalidate: NEXTJS_REVALIDATION_SECONDS };
}

export async function getLocationsProps({ params }) {
  const city = await nextGetCity(params);
  const { data: locations } = await dispatch(endpoints.getFeaturedLocations.initiate(city.id));
  await Promise.all(dispatch(apiSlice.util.getRunningQueriesThunk()));
  const props = { city, locations };
  return { props, revalidate: NEXTJS_REVALIDATION_SECONDS };
}

export async function getUpcomingProps({ params }) {
  const city = await nextGetCity(params);
  const uniEvents = await getUniEvents(city.id);
  await Promise.all(dispatch(apiSlice.util.getRunningQueriesThunk()));
  const props = { city, uniEvents };
  return { props, revalidate: NEXTJS_REVALIDATION_SECONDS };
}

export async function getPoolProps({ params }) {
  const city = await nextGetCity(params);
  console.log('Static Generation - params:', params);
  const poolId = lib.idFromSlug(params.slug);
  console.log('Static Generation - poolId:', poolId);
  const { data: pool } = await dispatch(endpoints.getPool.initiate(poolId));
  // let club = null;
  // if (pool.club_id) {
  //   const { data: dataClub } = await dispatch(endpoints.getClub.initiate(pool.club_id));
  //   club = dataClub;
  // }
  let partner = null;
  if (pool.partner_id) {
    const { data: profilesData } = await dispatch(endpoints.getProfile.initiate(pool.partner_id));
    partner = profilesData?.profiles?.[0];
  }
  // console.log('partner', partner);
  const { data: locations } = await dispatch(endpoints.getFeaturedLocations.initiate(city.id));
  // const { data: dataPrevGuests } = await dispatch(endpoints.getPrevGuests.initiate({ poolId: pool.id }));
  // const profiles = dataPrevGuests?.profiles || [];
  await Promise.all(dispatch(apiSlice.util.getRunningQueriesThunk()));
  const props = { pool, city, locations, partner };
  return { props, revalidate: NEXTJS_REVALIDATION_SECONDS }; // NextJs will re-generate the page when a request comes in after x seconds
}

export async function getEventProps({ params }) {
  const city = await nextGetCity(params);
  const eventId = lib.idFromSlug(params.slug);
  const { data: event = null } = await dispatch(endpoints.getEvent.initiate({ eventId }));
  const { data: locations } = await dispatch(endpoints.getFeaturedLocations.initiate(city.id));
  const props = { city, event, locations };
  return { props, revalidate: NEXTJS_REVALIDATION_SECONDS }; // NextJs will re-generate the page when a request comes in after x seconds
}

export async function getClubsProps({ params }) {
  const city = await nextGetCity(params);
  const { data: clubs } = await dispatch(endpoints.getCommunities.initiate(city.id));
  await Promise.all(dispatch(apiSlice.util.getRunningQueriesThunk()));
  const props = { city, clubs };
  return { props, revalidate: NEXTJS_REVALIDATION_SECONDS }; // NextJs will re-generate the page when a request comes in after x seconds
}

export async function getClubProps({ params }) {
  const city = await nextGetCity(params);
  const clubId = lib.idFromSlug(params.slug);
  const { data: club } = await dispatch(endpoints.getClub.initiate(clubId));
  const topicId = club.topic_id;

  let props = { city, club };
  if (city) {
    const { data: membersData } = await dispatch(endpoints.getTopicProfiles.initiate({ cityId: city.id, topicId }));
    const { data: locations } = await dispatch(endpoints.getFeaturedLocations.initiate(city.id));
    const uniEventsCity = await getUniEvents(city.id);
    const uniEvents = uniEventsCity.filter(u => u.club?.id === clubId);
    props = { ...props, membersData, locations, uniEvents };
  }
  await Promise.all(dispatch(apiSlice.util.getRunningQueriesThunk()));
  return { props, revalidate: NEXTJS_REVALIDATION_SECONDS }; // NextJs will re-generate the page when a request comes in after x seconds
}

export async function getInviteProps({ params }) {
  const { inviteCode } = params;

  const { data: invites } = await dispatch(endpoints.getInvite.initiate(inviteCode));
  const invite = invites?.[0] || null;

  let event = null;
  let pool = null;

  if (invite?.event_id) {
    const eventData = await dispatch(endpoints.getEvent.initiate({ eventId: invite?.event_id, code: inviteCode }));
    event = eventData.data || null;
  }
  if (invite?.pool_id) {
    const poolData = await dispatch(endpoints.getPool.initiate(invite?.pool_id));
    pool = poolData.data || null;
  }

  await Promise.all(dispatch(apiSlice.util.getRunningQueriesThunk()));
  const props = { invite, pool, event };
  return { props, revalidate: NEXTJS_REVALIDATION_SECONDS };
}

import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {
  format,
  isBefore as isBeforeDateFns,
  isAfter as isAfterDateFns,
  isEqual,
  compareDesc,
  formatDistanceToNowStrict,
  addDays,
  differenceInCalendarDays,
} from 'date-fns';

export const dateTimeFormat1 = 'p - EEEE, MMM d'; // 7:00 PM - March 31, 2023
export const dateTimeFormat2 = 'p - PPPP'; // 7:00 PM - Friday, March 31st, 2023
export const dateTimeFormat3 = 'EEEE, MMM d — p'; // March 31, 2023
export const dateTimeFormat4 = 'PPPP - p';
export const dateFormat = 'EEEE, MMMM d';
export const timeFormat = 'p';

export const fromZoned = (date, timezone) => {
  return zonedTimeToUtc(date, timezone);
};

export const toZoned = (date, timezone) => {
  if (date && timezone) {
    return utcToZonedTime(new Date(date), timezone);
  }
};

export const toZoned1 = (date, timezone) => {
  return fTime1(toZoned(date, timezone));
};

export const toZoned2 = (date, timezone) => {
  return fTime2(toZoned(date, timezone));
};

export const toZoned3 = (date, timezone) => {
  return fTime3(toZoned(date, timezone));
};

export const toZoned4 = (date, timezone) => {
  return fTime4(toZoned(date, timezone));
};

export const fTime = (date, customFormat) => {
  if (date && customFormat) {
    return format(new Date(date), customFormat);
  }
  return '';
};

export const fTime1 = date => {
  return fTime(date, dateTimeFormat1);
};

export const fTime2 = date => {
  return fTime(date, dateTimeFormat2);
};

export const fTime3 = date => {
  return fTime(date, dateTimeFormat3);
};

export const fTime4 = date => {
  return fTime(date, dateTimeFormat4);
};

export const fDate = date => {
  return fTime(date, dateFormat);
};

export const fTimeOnly = date => {
  return fTime(date, timeFormat);
};

export const isBefore = (date, referenceTime) => {
  const refTime = referenceTime ? new Date(referenceTime) : new Date();
  return isBeforeDateFns(new Date(date), refTime);
};

export const isBeforeOrEqual = (date, referenceTime) => {
  const refTime = referenceTime ? new Date(referenceTime) : new Date();
  return isBeforeDateFns(new Date(date), refTime) || isEqual(new Date(date), refTime);
};

export const isAfter = (date, referenceTime) => {
  const refTime = referenceTime ? new Date(referenceTime) : new Date();
  return isAfterDateFns(new Date(date), refTime);
};

export const fromNow = (date, addSuffix = true) => {
  if (date) return formatDistanceToNowStrict(new Date(date), { addSuffix });
};

export const formatFlexible = (date, cityTime, customFormat) => {
  if (date) {
    return isBefore(date) ? fromNow(date) : format(cityTime, customFormat || dateTimeFormat2);
  } else {
    return 'Date TBD';
  }
};

export const getTimestamp = () => {
  return Date.now();
};

export const sortDateProp = dateProp => (obj1, obj2) => {
  const time1 = obj1[dateProp] ? new Date(obj1[dateProp]) : null;
  const time2 = obj2[dateProp] ? new Date(obj2[dateProp]) : null;

  if (time1 === null && time2 === null) return 0;
  if (time1 === null) return 1;
  if (time2 === null) return -1;
  return compareDesc(time1, time2);
};

export const getUpcomingDateOrDate = (targetDate, timezone) => {
  // Get todays date in the timezone
  const today = toZoned(new Date(), timezone);
  // Get the number of days it currently is before target date using date-fns
  const daysBefore = differenceInCalendarDays(targetDate, today);

  // console.log({ daysBefore, targetDate, today, timezone });

  const isInEvening = targetDate.getHours() >= 18;

  // If the eventDate is yesterday or before (relative to today in the timezone)
  if (daysBefore < -1) {
    // return 'past';
    return 'are you in?';
  } else if (daysBefore === -1) {
    // return 'yesterday';
    return 'are you in?';
  } else if (daysBefore === 0) {
    return isInEvening ? 'are you in for tonight?' : 'are you in today?';
  } else if (daysBefore === 1) {
    return isInEvening ? 'are you in for tomorrow night?' : 'are you in tomorrow?';
  } else if (daysBefore < 7) {
    const weekday = format(targetDate, 'EEEE');
    return isInEvening ? `are you in for ${weekday} night?` : `are you in for ${weekday}?`;
  } else if (daysBefore < 14) {
    const weekday = format(targetDate, 'EEEE');
    return isInEvening ? `are you in next ${weekday} night?` : `are you in next ${weekday}?`;
  } else {
    return 'are you in?';
    // return format(targetDate, 'MMMM d');
  }
};

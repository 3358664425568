import React from 'react';
import { lib, api, redux } from '@tlsocial/common';
import {
  Box,
  Flex,
  GridItem,
  SimpleGrid,
  Stack,
  VStack,
  StackDivider,
  Text,
  Link,
  Wrap,
  Show,
  Center,
} from '@chakra-ui/react';
import MyHelmet from '@/components/shared/MyHelmet';
import { H1, H2, H3 } from '@/components/shared/Headings';
import { useRouter } from 'next/router';
import Button from '@/components/shared/Button';
import { links } from '@/constants';
import { HomeBanner } from '@/components/home/HomeBanner';
import 'swiper/css';
import { MaxContainer, MaxContentContainer } from '@/components/shared/MaxContainer';
import { UniEventItems } from '../uni_event/UniEventItem';
import { CarouselMembers } from './CarouselMembers';
import { CarouselTestimonials } from './CarouselTestimonials';
import { CarouselUniEvents } from './CarouselObjects';
import HomeFeatures from './HomeFeatures';
import { usePostHog } from 'posthog-js/react';
import { ButtonJoin, GlowButton } from '../shared/Buttons';

const BaseHomePage = props => {
  const router = useRouter();
  const posthog = usePostHog();

  const { city, ipAddress, locations, ambassadors, uniEvents = [] } = props;

  const nextEventTime = uniEvents.find(u => u.is_pool)?.time_matching;

  const xCity = lib.useCity(city);

  const title = city
    ? `SocialTable ${city.name} - Curated Dining Experiences`
    : 'SocialTable - Curated Dining Experiences';

  const renderUpcoming = () => {
    const MAX_ITEMS = 3;

    if (uniEvents?.length > 0) {
      return (
        <Box>
          <H1 align="center" mb={10}>
            Upcoming in {city.name}
          </H1>

          <CarouselUniEvents uniEvents={uniEvents} city={city} />

          {/* <Show above="sm">
            <UniEventItems uniEvents={uniEvents} city={city} />
            {uniEvents.length > MAX_ITEMS && <Button variant="ghost" label="More" to={links.EVENTS(city)} />}
          </Show> */}

          {/* <UniEventGrid items={upcomingEvents} city={city} /> */}
        </Box>
      );
    }
  };

  const renderPhotos = () => (
    <Box>
      {/* <H1 mb={10} align="center">
        A Curated Community
      </H1> */}
      <CarouselMembers />
    </Box>
  );

  const renderTestimonials = () => (
    <Stack spacing={8} align="center">
      <H1 align="center" maxW={700} px={3}>
        Member Experiences
      </H1>
      <CarouselTestimonials />
      <ButtonJoin glow city={city} />
    </Stack>
  );

  return (
    <>
      <MyHelmet title={title} image={xCity.imageXL} />
      <HomeBanner city={city} nextEventTime={nextEventTime} />
      <MaxContentContainer>
        <VStack spacing={'60px'} align="stretch">
          {renderUpcoming()}
          {renderPhotos()}
          {renderTestimonials()}
        </VStack>
      </MaxContentContainer>
      {/* {city && <HomeCity city={city} uniEvents={uniEvents} locations={locations} ambassadors={ambassadors} />} */}
      <HomeFeatures city={city} />
      
      {/* <Text>IP: {ipAddress}</Text> */}
    </>
  );
};

export default BaseHomePage;

// CityScreen.getTemplate = getTemplate;

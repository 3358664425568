import { lib, api, redux } from '@tlsocial/common';

export const useUserAttributeTypeData = () => {
  const { d_typeAttributes } = lib.useUserAttributes();
  const d_attributeTypeComplete = Object.values(lib.ATTRIBUTE_TYPES).reduce((acc, type) => {
    const attributes = d_typeAttributes[type] || [];
    acc[type] = {
      isComplete: attributes.some(a => a.level),
      selectedCount: attributes.filter(a => a.level === lib.ATTRIBUTE_LEVELS.LEVEL_3).length,
    };
    return acc;
  }, {});
  return d_attributeTypeComplete;
};

export const useUserAttributes = () => {
  const { data, isLoading } = api.useGetAttributesQuery();
  const { attributes = [], user_attributes: userAttributes } = data || {};

  const d_userAttributes = lib.arrayToObjectValues(userAttributes, 'attribute_id', 'level');

  const attributesWithLevels = attributes.map(a => ({
    ...a,
    level: d_userAttributes[a.id],
  }));

  const d_typeAttributes = lib.arrayToObjectList(attributesWithLevels, 'type');

  return { d_typeAttributes, d_userAttributes, isLoading };
};

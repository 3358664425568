import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAsyncFunc } from './useAsync';
import { MODALS } from '@/constants';
import { useSelector } from 'react-redux';
import { useBreakpointValue } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useCopyToClipboard } from 'react-use';
import { useToast } from '@chakra-ui/react';

export const useURLParams = () => {
  const router = useRouter();

  const add = newParams => {
    router.push({ pathname: router.pathname, query: { ...router.query, ...newParams } });
  };
  const remove = param => {
    let query = { ...router.query };
    delete query[param];
    router.push({ pathname: router.pathname, query });
  };
  const set = query => {
    return router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, ...query },
      },
      undefined,
      { scroll: false },
    );
  };
  return { set, add, remove, params: router.query };
};

export const useIsClient = () => {
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  return isClient;
};

export const useCopyText = () => {
  const [state, copyToClipboard] = useCopyToClipboard();
  const toast = useToast();
  return text => {
    if (text) {
      copyToClipboard(text);
      toast({
        title: 'Copied:',
        description: text,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }
  };
};

import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from '../api';

const initialState = {
  profileRelations: {},
  eventLikesHost: {},
  eventLikesGuest: {},
  optimisticVouch: {},
  optimisticProfile: {},
  optimisticLocation: {},
};

const setProfileRelation = (state, { payload }) => {
  console.log('setProfileRelation', { payload });
  const relation = payload.relation;
  const userToId = relation.user_to_id;
  state.profileRelations[userToId] = relation;
};

const setProfileRelations = (state, { payload }) => {
  console.log('setProfileRelations', { payload });
  const relations = payload?.relations || [];
  relations.forEach(relation => {
    const userToId = relation.user_to_id;
    state.profileRelations[userToId] = relation;
  });
};

const setOptimisticVouch = (state, action) => {
  const { user_to_id, is_vouching } = action.meta.arg.originalArgs;
  state.optimisticVouch[user_to_id] = is_vouching;
};

const reverseOptimisticVouch = (state, action) => {
  const { user_to_id } = action.meta.arg.originalArgs;
  state.optimisticVouch[user_to_id] = null;
};

const setProfileOptimistic = (state, action) => {
  const { user_to_id, rating } = action.meta.arg.originalArgs;
  state.optimisticProfile[user_to_id] = rating;
};
const reverseProfileOptimistic = (state, action) => {
  const { user_to_id } = action.meta.arg.originalArgs;
  state.optimisticProfile[user_to_id] = null;
};

const relationsSlice = createSlice({
  name: 'relations',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(apiSlice.endpoints.updateVouch.matchFulfilled, setProfileRelation)
      .addMatcher(apiSlice.endpoints.rateUser.matchFulfilled, setProfileRelation)
      .addMatcher(apiSlice.endpoints.rateExperience.matchFulfilled, setProfileRelations)
      .addMatcher(apiSlice.endpoints.updateEventLikeGuest.matchFulfilled, (state, { payload }) => {
        const eventRequest = payload;
        if (eventRequest) {
          state.eventLikesGuest[eventRequest.event_id] = eventRequest;
        }
      })
      .addMatcher(apiSlice.endpoints.updateEventLikeHost.matchFulfilled, (state, { payload }) => {
        const eventRequest = payload;
        if (eventRequest) {
          state.eventLikesHost[eventRequest.id] = eventRequest;
        }
      })
      .addMatcher(apiSlice.endpoints.updateVouch.matchPending, setOptimisticVouch)
      .addMatcher(apiSlice.endpoints.updateVouch.matchRejected, reverseOptimisticVouch)
      .addMatcher(apiSlice.endpoints.rateUser.matchPending, setProfileOptimistic)
      .addMatcher(apiSlice.endpoints.rateUser.matchRejected, reverseProfileOptimistic)
      .addMatcher(apiSlice.endpoints.rateLocation.matchPending, (state, action) => {
        const { location_id, is_favorite } = action.meta.arg.originalArgs;
        state.optimisticLocation[location_id] = is_favorite;
      })
      .addMatcher(apiSlice.endpoints.rateLocation.matchRejected, (state, action) => {
        const { location_id } = action.meta.arg.originalArgs;
        state.optimisticLocation[location_id] = null;
      });
  },
});

export default relationsSlice.reducer;

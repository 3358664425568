import { redux } from '@tlsocial/common';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns';

export const useCurrentTimeSeconds = (seconds = 1) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, seconds * 1000);

    return () => clearInterval(timer);
  }, []);

  return currentTime;
};

export const useCurrentTimeMinutes = () => {
  return useCurrentTimeSeconds(60);
};

export const useCountdown = targetDate => {
  const currentTime = useCurrentTimeSeconds();
  const target = new Date(targetDate);
  if (currentTime && target) {
    if (currentTime > target) {
      return { days: '0', hours: '0', minutes: '0', seconds: '0' };
    }
    const days = differenceInDays(target, currentTime);
    const hours = differenceInHours(target, currentTime) % 24;
    const minutes = differenceInMinutes(target, currentTime) % 60;
    const seconds = differenceInSeconds(target, currentTime) % 60;
    return { days, hours, minutes, seconds };
  }
};

export const useCountdownText = targetDate => {
  const currentTime = useCurrentTimeSeconds();
  const { days, hours, minutes, seconds } = useCountdown(targetDate);
  if (days) {
    if (days > 0) {
      return `${days} days, ${hours} hours`;
    } else {
      return `${hours} : ${minutes < 10 ? '0' + minutes : minutes} : ${seconds < 10 ? '0' + seconds : seconds}`;
    }
  }
};

export const useWithin24Hours = targetDate => {
  const currentTime = useCurrentTimeMinutes();
  const target = new Date(targetDate);
  if (currentTime && target) {
    return differenceInHours(target, currentTime) < 24;
  }
};

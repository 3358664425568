import { transformProfiles } from './apiConstruct';
import { loadSuggested } from '../redux/citySuggestedSlice';
import { apiSlice } from './apiSlice';
import { tags } from './rtkTags';

export const apiUsers = apiSlice.injectEndpoints({
  endpoints: build => ({
    getProfilesMeet: build.query({
      query: cityId => `/users/city/${cityId}/meet`,
      transformResponse: transformProfiles,
    }),
    getProfilesStarred: build.query({
      query: cityId => `/users/city/${cityId}/starred`,
      transformResponse: transformProfiles,
    }),
    getSuggested: build.query({
      query: cityId => `/users/city/${cityId}/suggested`,
      transformResponse: transformProfiles,
      providesTags: [tags.SUGGESTED],
      async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadSuggested(data.profiles));
        } catch (error) {}
      },
    }),
    getMostSimilar: build.query({
      query: cityId => `/users/city/${cityId}/similar`,
      transformResponse: transformProfiles,
      providesTags: [tags.MOST_SIMILAR],
    }),
    getCityMembers: build.query({
      query: cityId => `/users/city/${cityId}/members`,
      transformResponse: transformProfiles,
      providesTags: [tags.COMMUNITY_MEMBERS],
    }),
    getTopicProfiles: build.query({
      query: ({ cityId, topicId }) => `/users/city/${cityId}/topic/${topicId}`,
      transformResponse: transformProfiles,
    }),
    // getClubProfiles: build.query({

    // },
    getProfileMet: build.query({
      query: profileId => `/users/profile/${profileId}/met`,
      transformResponse: transformProfiles,
    }),
    getProfileInvited: build.query({
      query: profileId => `/users/profile/${profileId}/invited`,
      transformResponse: transformProfiles,
    }),
    getVouchesReceived: build.query({
      query: profileId => `/users/profile/${profileId}/vouches_received`,
      transformResponse: transformProfiles,
      providesTags: (result, error, profileId) => [{ type: tags.PROFILE_VOUCHES_RECEIVED, id: profileId }],
    }),
    getLocationProfiles: build.query({
      query: locationId => `/users/location/${locationId}/wishlist`,
      transformResponse: transformProfiles,
    }),
    // getPrevGuests: build.query({
    //   query: ({ cityId, poolId }) => `/users/pool/${poolId}/prev_attendees`,
    //   transformResponse: transformProfiles,
    // }),
    getPoolRegistrants: build.query({
      query: poolId => `/users/pool/${poolId}/registrants`,
      transformResponse: transformProfiles,
    }),
    getEventApplicants: build.query({
      query: eventId => `/users/event/${eventId}/applicants`,
      transformResponse: transformProfiles,
    }),
    getProfile: build.query({
      query: id => `/users/${id}`,
      transformResponse: transformProfiles,
    }),
    getAmbassadors: build.query({
      query: id => `/users/city/${id}/ambassadors`,
      transformResponse: transformProfiles,
    }),
    rateUser: build.mutation({
      query: body => ({ url: `users/${body.user_to_id}/rate`, method: 'PUT', body }),
    }),
    reportUser: build.mutation({
      query: body => ({ url: `users/${body.user_to_id}/report`, method: 'PUT', body }),
    }),
    removeConnection: build.mutation({
      query: body => ({ url: `users/${body.user_to_id}/remove_connection`, method: 'PUT', body }),
    }),
    updateVouch: build.mutation({
      query: body => ({ url: `users/${body.user_to_id}/update_vouch`, method: 'PUT', body }),
      invalidatesTags: (result, error, body) => [
        tags.USER_VOUCHES,
        { type: tags.PROFILE_VOUCHES_RECEIVED, id: body.user_to_id },
      ],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetProfilesMeetQuery,
  useGetProfilesStarredQuery,
  useGetSuggestedQuery,
  useGetMostSimilarQuery,
  useGetCityMembersQuery,
  useGetTopicProfilesQuery,
  useGetClubProfilesQuery,
  useGetLocationProfilesQuery,
  useGetPrevGuestsQuery,
  useGetPoolRegistrantsQuery,
  useGetEventApplicantsQuery,
  useGetProfileQuery,
  useGetProfileMetQuery,
  useGetProfileInvitedQuery,
  useGetVouchesReceivedQuery,
  useRateUserMutation,
  useRemoveConnectionMutation,
  useReportUserMutation,
  useUpdateVouchMutation,
} = apiUsers;

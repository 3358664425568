import React from 'react';
import { lib, api, redux } from '@tlsocial/common';
import { Box, Flex, GridItem, SimpleGrid, Stack, VStack, StackDivider, Text, Link, Wrap, Show } from '@chakra-ui/react';
import 'swiper/css';
import { WebCarousel, MobileCarousel, Carousel } from '@/components/shared/Carousel';

const testimonials = [
  {
    name: 'Diana',
    city: 'Austin',
    text: 'Really great matching!!! Good group. Loved that y’all put people in similar stages of life together. I really enjoyed it! Other apps are awkward because you have to create the plans and it’s like a date… SocialTable group setting is so much more approachable',
  },
  {
    name: 'Hayley',
    city: 'Austin',
    text: 'While I was a bit apprehensive to meet up with people I had never met before, but I was immediately relieved when I showed up to dinner and was greeted by five super friendly, smiling faces. Despite our age differences, I really enjoyed our conversations and the restaurant chosen for us was a total vibe. 10/10 experience and will definitely be using SocialTable again!',
  },
  {
    name: 'Daniel',
    city: 'Austin',
    text: 'Great experience! The dinner lasted three hours and I only had to leave because of pre-existing plans.',
  },
  {
    name: 'Carlee',
    city: 'Nashville',
    text: 'I have had such amazing experiences with SocialTable! I was so nervous at first to dine with strangers, but they matched my group perfectly. I’ve become great friends with several girls that I probably would have never met if it wasn’t for SocialTable. I love that I can leave all the planning to them, and show up knowing that I will be surrounded by like minded people. I can’t wait for more dinners!',
  },
  {
    name: 'Juilette',
    city: 'Nashville',
    text: 'I have had a fantastic experience with my Social Table dinners! Moving to Nashville, I knew no one and had very limited ability to make friends outside of work, so Social Table made it super easy to meet new people who aligned with my interests and life philosophy, and I’ve made some incredible friends through the app. I couldn’t recommend it more to folks new to Nashville or looking for new friends over yummy meals.',
  },
  {
    name: 'Marouane',
    city: 'Seoul',
    text: 'The Founders & C-Suite dinner was one of the most fruitful dinner gatherings here in Korea for me ‘til now! Met some amazing people, made great connections, and most importantly, it led to potential biz opportunities! Ended up having a business meeting with two of them afterward to discuss further.',
  },
  {
    name: 'Alex',
    city: 'Austin',
    text: 'No notes. It was a great experience! I had at least one thing in common with everyone there. They were all definitely my vibe and we went to get ice cream after. I am so looking forward to doing more of these!',
  },
  {
    name: 'Charlie',
    city: 'Austin',
    text: 'It went really well. The place was loud with people but only made us speak up louder. Good laughter and we bonded quick with the lead’s organized questions to stimulate conversation.',
  },
  {
    name: 'Bella',
    city: 'Nashville',
    text: 'Social Table has been a game-changer for me in my new city. Through their innovative platform, I’ve had the pleasure of attending dinners where I met random girls who share similar interests and backgrounds, making every gathering feel like a reunion with old friends. It’s more than just a dinner; it’s an opportunity to connect with wonderful people and build meaningful friendships in a city where I initially felt like a stranger.',
  },
];

const Testimonial = props => {
  const { name, city, text, ...extra } = props;
  return (
    <Flex bg={lib.colors.primary100} minH="450px" px={7} align="center" justify="center" textAlign="center" {...extra}>
      <Box>
        <Text fontSize={[16, 17]} mb={5} color={lib.colors.primary900} fontWeight="bold" opacity={0.7}>
          "{text}"
        </Text>
        <Text fontSize={[16, 17]} fontWeight="bold" color={lib.colors.primary900} opacity={0.4}>
          {name} ({city})
        </Text>
      </Box>
    </Flex>
  );
};

export const CarouselTestimonials = props => (
  <Carousel
    swiperId="testimonials"
    items={testimonials}
    renderItemWeb={item => <Testimonial {...item} rounded={0} />}
    renderItemMobile={item => <Testimonial {...item} rounded={10} />}
  />
);

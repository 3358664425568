import { images } from '../platform/initPlatform';

export const getInviteText = inviteCode => {
  return `I invited you to SocialTable! To accept, download the app and join with code: ***${inviteCode}***\nhttps://invite.socialtable.app/invite/${inviteCode}`;
};

export const HOW_EVENT_POOLS_WORK = [
  {
    title: `Join the event pool`,
    description: `Create a profile, share your interests, and let us know who you'd like to meet.`,
  },
  {
    title: `Get matched to a group of 4-8 people`,
    description: `SocialTable carefully crafts groups of 4-8 people based on shared interests and preferences. We'll arrange the restaurant reservation for your group, and you'll receive a notification with the event location and guest list.`,
  },
  {
    title: 'Meet at the restaurant',
    description:
      'Simply show up to greet your new friends, and enjoy an evening filled with delicious food and lively conversation. A group chat enables easy communication before and after the event.',
  },
  {
    title: 'Share your experience',
    description:
      "After the event, let us know how it went and which attendees you'd like to connect with or meet again. Your feedback help us fine-tune our group matching process, ensuring even more enjoyable events in the future.",
  },
];

export const FAQ_CONTENT = [
  {
    section: 'faq.general.title',
    questions: [
      ['faq.general.how.question', 'faq.general.how.answer'],
      ['faq.general.plus.question', 'faq.general.plus.answer'],
      ['faq.general.cost.question', 'faq.general.cost.answer'],
      ['faq.general.cancel.question', 'faq.general.cancel.answer'],
    ],
  },
  {
    section: 'faq.matching.title',
    questions: [
      ['faq.matching.choose_who.question', 'faq.matching.choose_who.answer'],
      ['faq.matching.restaurants.question', 'faq.matching.restaurants.answer'],
      ['faq.matching.curated.question', 'faq.matching.curated.answer'],
      ['faq.matching.see_who.question', 'faq.matching.see_who.answer'],
    ],
  },
  {
    section: 'faq.attending.title',
    questions: [
      ['faq.attending.experience.question', 'faq.attending.experience.answer'],
      ['faq.attending.hosted.question', 'faq.attending.hosted.answer'],
      ['faq.attending.how_long.question', 'faq.attending.how_long.answer'],
      ['faq.attending.exchange.question', 'faq.attending.exchange.answer'],
    ],
  },
  {
    section: 'faq.after.title',
    questions: [
      ['faq.after.what.question', 'faq.after.what.answer'],
      ['faq.after.meet_again.question', 'faq.after.meet_again.answer'],
    ],
  },
];

// Also, to bring some intrigue, we'll share a fun fact about everyone in the group -- we won't reveal which fact applies to which person -- you'll just have to guess!
// "Each Sunday we invite you to register for dinner matching for the upcoming week. Just give us the days you're available, and we'll reserve you a seat at an exciting group dinner in the coming week. We'll let you know the details of any dinners you've been set up for by Monday morning.",
// "Yes, the attendee list is revealed on the day of your dinner. This gives dinners an exciting spontaneous feel, while at the same time giving people comfort about who they're meeting.",

export const ETIQUETTE_CONTENT = [
  {
    title: 'etiquette.feels_included.title',
    body: 'etiquette.feels_included.body',
  },
  {
    title: 'etiquette.dont_monopolize.title',
    body: 'etiquette.dont_monopolize.body',
  },
  {
    title: 'etiquette.be_present.title',
    body: 'etiquette.be_present.body',
  },
  {
    title: 'etiquette.good_intentions.title',
    body: 'etiquette.good_intentions.body',
  },
];

export const WHY_POINTS = [
  {
    icon: 'handshake',
    title: 'Build Meaningful Connections',
    // body: 'Move beyond small talk and engage in deeper conversations. Our curated dinners create a comfortable atmosphere for forming genuine social and professional connections.',
    body: 'Move beyond small talk and engage in deeper conversations with the right people. Our curated dinners are designed to create a comfortable atmosphere where attendees can form genuine connections, for both social and professional purposes.',
    image: images.whyConnection,
  },
  {
    icon: 'sparkles',
    title: 'Embrace Serendipity',
    // body: 'Meet people with shared interests but diverse backgrounds and perspectives. Enjoy lively conversations that foster new ideas. You might discover someone or something that changes your life!',
    body: "We'll introduce you to people with shared interests but diverse backgrounds and perspectives, providing the perfect environment for new ideas to flourish through lively conversation. You might just discover someone or something that changes the direction of your life!",
    image: images.whySerendipity,
  },
  {
    icon: 'seedling',
    title: 'Stop Scrolling, Start Living!',
    body: 'Trade endless scrolling for an unforgettable evening of lively conversation, delicious food, and new friendships. Reconnect with the real world and form meaningful connections that go beyond your screen.',
    image: images.whyLive,
  },
];

export const HOST_FEATURES = [
  // {
  //   // icon: 'handshake',
  //   title: '1. Apply to host',
  //   body: 'SocialTable selects people who will attract an audience.',
  //   image: images.whyConnection,
  // },
  {
    // icon: 'handshake',
    title: '1. Choose a conversation topic and event location',
    body: "Select a topic you're passionate about and a location appropriate for a small group dinner.",
    image: images.hostTopic,
  },
  {
    // icon: 'sparkles',
    title: '2. Curate a high quality guestlist with minimal effort',
    body: "SocialTable maintains a network of friendly, fun, interesting guests and hosts -- We'll recommend active members suitable for your dinner. You can also share your event with friends and followers in your network, while those interested can request to join.",
    image: images.hostCurate,
  },
  {
    // icon: 'seedling',
    title: '3. Build community and connect with people who share your passion!',
    body: 'SocialTable is the best place to meet people who share your interests and build community through genuine connection.',
    image: images.hostCommunity,
  },
];

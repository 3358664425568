import { extendTheme, withDefaultColorScheme, theme as base } from '@chakra-ui/react';
import { lib } from '@tlsocial/common';
// import '@fontsource/barlow';
// import '@fontsource/open-sans';

const theme = extendTheme({
  colors: {
    primary: {
      50: lib.colors.primary50,
      100: lib.colors.primary100,
      200: lib.colors.primary200,
      300: lib.colors.primary300,
      400: lib.colors.primary400,
      500: lib.colors.primary500,
      600: lib.colors.primary600,
      700: lib.colors.primary700,
      800: lib.colors.primary800,
      900: lib.colors.primary900,
    },
    bright: {
      // 50: lib.colors.primary50,
      // 100: lib.colors.primary100,
      // 200: lib.colors.primary200,
      300: lib.colors.bright300,
      400: lib.colors.bright400,
      500: lib.colors.bright500,
      600: lib.colors.bright600,
      700: lib.colors.bright700,
      // 800: lib.colors.primary800,
      // 900: lib.colors.primary900,
    },
    light1: lib.colors.light1,
    light2: lib.colors.light2,
    dark1: lib.colors.dark1,
    dark2: lib.colors.dark2,
  },
  fonts: {
    heading: `Lato, sans-serif`,
    body: `Lato, sans-serif`,
  },
  components: {
    Button: {
      sizes: {
        sm: {
          fontSize: 'xs',
        },
        md: {
          fontSize: 'sm',
        },
        lg: {
          fontSize: 'md',
        },
      },
    },
  },
});

export default theme;

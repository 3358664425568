import { Box, LinkBox, LinkOverlay, Link as ChakraLink, Text } from '@chakra-ui/react';
import React from 'react';
import NextLink from 'next/link';
import { getLinkProps } from '@/utils/nextUtils';
import { lib } from '@tlsocial/common';

export const Link = props => {
  const { to, state, color = lib.colors.primary500, hoverColor, ...extra } = props;

  if (props.href) {
    return <ChakraLink color={color} {...extra} />;
  } else if (props.to) {
    return (
      <ChakraLink as={asProps => <NextLink {...asProps} {...getLinkProps(to)} />} color={color} {...extra} />
    );
  } else {
    return <Text {...extra} />;
  }
};

export const MagicLink = props => {
  const { hoverColor, ...extraProps } = props;
  if (props.to) {
    return <LinkArea {...props} />;
  } else if (props.onClick) {
    return <HoverArea {...props} />;
  } else if (props.href) {
    return <Link {...props} />;
  } else {
    return <Box {...extraProps} />;
  }
};

export const LinkInner = props => {
  const { to, state } = props;
  return <LinkOverlay as={asProps => <NextLink {...asProps} {...getLinkProps(to)} />} />;
};

export const LinkArea = props => {
  const { to, state, hoverColor, skipLinkInner, ...extraProps } = props;
  const hoverStyle = { backgroundColor: hoverColor || 'gray.50' };
  return (
    <LinkBox _hover={hoverStyle} {...extraProps}>
      {!skipLinkInner && <LinkInner to={to} state={state} />}
      {props.children}
    </LinkBox>
  );
};

export const HoverArea = props => {
  const { hoverColor, ...extraProps } = props;
  const hoverStyle = { backgroundColor: hoverColor || 'gray.50' };
  return (
    <Box cursor={!props.disable && 'pointer'} _hover={!props.disable && hoverStyle} {...extraProps}>
      {props.children}
    </Box>
  );
};

import React from 'react';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import { links, gs } from '@/constants';
import {
  Tabs,
  TabList,
  Tab,
  Box,
  Flex,
  Button,
  Stack,
  MenuList,
  MenuItem,
  Menu,
  MenuButton,
  Center,
  Badge,
  Text,
  TabPanels,
  TabPanel,
  Wrap,
  IconButton,
} from '@chakra-ui/react';
import { getLinkProps } from '@/utils/nextUtils';
import { lib } from '@tlsocial/common';
import Icon from './Icon';
import { MyNextLink } from './MyNextLink';

const MenuCount = props => {
  const { count } = props;
  return (
    <Center minW="22px" minH="22px" rounded="full" px={1} fontSize="13px" ml="6px" bg="gray.50" color="gray.600">
      {count}
    </Center>
  );
};

export const TabsMenu = props => {
  const { items, exactOnly, ...extraProps } = props;
  const router = useRouter();
  const activeIndex = items.findIndex(
    item =>
      item.forceActive || (!exactOnly && !item.exact ? router.asPath.startsWith(item.to) : router.asPath === item.to),
  );

  return (
    <Tabs colorScheme="cyan" isManual index={activeIndex} {...extraProps}>
      {items.length > 0 && (
        <TabList>
          {items.map((item, key) => {
            const isActive = activeIndex === key;
            return (
              <NextLink key={key} {...getLinkProps(item.to)} passHref>
                <Tab>
                  <Flex height="40px" alignItems="center">
                    <Box fontWeight={isActive && 'bold'} fontSize="14px">
                      {item.label}
                    </Box>
                    {item.count > 0 && <MenuCount count={item.count} />}
                  </Flex>
                </Tab>
              </NextLink>
            );
          })}
        </TabList>
      )}
      {items.length > 0 && (
        <TabPanels>
          {items.map((item, key) => {
            <TabPanel>{item.content}</TabPanel>;
          })}
        </TabPanels>
      )}
    </Tabs>
  );
};

export const ButtonsMenu = props => {
  const { items, buttonProps, noShowActive, exactOnly, ...extraProps } = props;
  const router = useRouter();
  return (
    <Wrap spacing={2} py={1} {...extraProps}>
      {items.map((item, key) => {
        const { isActive, exact, to, href, onClick, label, count, myClickEvent, bg, color, ...itemExtra } = item;
        const isButtonActive =
          isActive || (!noShowActive && (!exactOnly && !exact ? router.asPath.startsWith(to) : router.asPath === to));
        return (
          <MyNextLink key={key} myClickEvent={myClickEvent} {...getLinkProps(to, href)} passHref>
            <Button variant="ghost" isActive={isButtonActive} onClick={onClick} {...buttonProps} {...itemExtra}>
              {label}
              {count > 0 && (
                <Flex
                  position="absolute"
                  top="-1"
                  right="-1"
                  borderRadius="full"
                  w={5}
                  h={5}
                  alignItems="center"
                  justifyContent="center"
                  fontSize={13}
                  bg={bg}
                  color={color}>
                  <Text>{count}</Text>
                </Flex>
              )}
            </Button>
          </MyNextLink>
        );
      })}
    </Wrap>
  );
};

export const DropdownMenu = props => {
  const { items } = props;
  return (
    <Box>
      <Menu>
        <MenuButton
          cursor="pointer"
          as={IconButton}
          rounded={5}
          bg={'white'}
          // color={lib.colors.darkBgText}
          icon={<Icon solid icon="bars" fontSize={18} />}
        />
        <MenuList bg={lib.colors.blackBg} color={lib.colors.darkBgText} border={0}>
          {items.map((item, key) => {
            return (
              <MyNextLink key={key} myClickEvent={item.myClickEvent} {...getLinkProps(item.to, item.href)} passHref>
                <MenuItem onClick={item.onClick} bg={lib.colors.blackBg} fontSize={16} fontWeight="bold" px={4} py={2}>
                  {item.label}
                </MenuItem>
              </MyNextLink>
            );
          })}
        </MenuList>
      </Menu>
    </Box>
  );
};

import { Box, Center, Flex, HStack, StackDivider, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { MaxContainer, MaxContentContainer } from '@/components/shared/MaxContainer';
import { Modal } from '@/components/shared/Modals';
import * as React from 'react';
import Button from '@/components/shared/Button';
import { lib } from '@tlsocial/common';
import { H1 } from '../shared/Headings';
import { Countdown } from '../shared/Countdown';
import { GlowButton, ButtonJoin } from '../shared/Buttons';
import { AppStoreIcons } from '../shared/AppStoreLinks';
import { useVariantString, useVariantBool, useVariant } from '@/hooks';

export const TopBanner = props => {
  const { children, backgroundImage } = props;

  return (
    <Box style={{ ...styles.headerOuterContainer, backgroundImage }} minH={['calc(100vh - 70px)', 'auto']}>
      <Flex background={'linear-gradient(180deg, rgba(255,255,255,1),  rgba(255,200,190,0.9))'}>
        <MaxContainer minH={['calc(100vh - 65px)', 'auto']}>
          <Flex py={['40px', '100px']} justify="center" align="center" minH={['calc(100vh - 65px)', 'auto']}>
            {children}
          </Flex>
        </MaxContainer>
      </Flex>
    </Box>
  );
};

export const HomeBanner = props => {
  const { city, nextEventTime } = props;

  const backgroundImage = `url(${lib.images.above})`;

  const cityName = city?.name || 'Your City';
  const cityTitle = useVariantString(lib.flags.web_home_headline, { cityName });
  const title = city ? cityTitle : lib.getControlString(lib.flags.web_home_headline);
  const emoji = useVariantString(lib.flags.web_home_headline_emoji);
  const subheader = useVariantString(lib.flags.web_home_subheader);
  const question = useVariantString(lib.flags.web_home_question);
  const isCapitalized = useVariantBool(lib.flags.web_home_header_is_capitalized);
  const is900 = useVariantBool(lib.flags.web_home_header_is_900);
  const countdownVariant = useVariant(lib.flags.web_home_countdown);

  const renderCountdown = () => {
    if (nextEventTime) {
      return <Countdown targetTime={nextEventTime} />;
    }
  };

  return (
    <TopBanner {...{ backgroundImage }}>
      <VStack spacing={[7, 7]}>
        {countdownVariant === lib.flagVariants.web_home_countdown.top && renderCountdown()}

        <VStack spacing={4}>
          <H1
            fontSize={['34px', '44px', '52px']}
            maxW="750px"
            mb={0}
            fontWeight={is900 ? '900' : 'bold'}
            align="center"
            px={3}
            textTransform={isCapitalized && 'uppercase'}>
            {title} {emoji}
          </H1>

          {subheader && (
            <Text maxW="700px" fontSize={['16', '17']} opacity={0.8} align="center" px={3}>
              {subheader}
            </Text>
          )}
        </VStack>

        {countdownVariant === lib.flagVariants.web_home_countdown.control && renderCountdown()}

        {question && (
          <Text fontSize={[32, 40]} fontFamily="Satisfy" color={lib.colors.primary400} align="center" fontWeight="600">
            {question}
          </Text>
        )}

        <ButtonJoin glow city={city}  h="60px" />

        <AppStoreIcons />
      </VStack>
    </TopBanner>
  );
};

export const HostBanner = props => {
  const title = 'Host a Dinner on SocialTable';
  const body = `Build community and connect with people who share your passion! ✨`;
  const applyUrl = 'https://socialtable.typeform.com/apply-to-host';

  const backgroundImage = `url(${lib.images.above})`;
  return (
    <TopBanner {...{ title, body, backgroundImage }}>
      <Button solid size="lg" mt={8} minW="200px" to={applyUrl} label="Become a Host" />
    </TopBanner>
  );
};

const styles = {
  headerOuterContainer: {
    backgroundColor: 'white',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // color: '#ffffff',
    borderRadius: 0,
    margin: 0,
    // color: lib.colors.primary900,
  },
};

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { lib, redux } from '@tlsocial/common';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import AppMain from './AppMain';
import Bugsnag from '@bugsnag/js';
import ErrorScreen from './ErrorScreen';
import { ChakraProvider } from '@chakra-ui/react';
import chakraTheme from '@/theme';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import * as c from '@/constants/envConstants';
import PageTemplate from '@/components/main/PageTemplate';
import { Analytics } from '@vercel/analytics/react';
import SocialTableContext from './SocialTableContext';
import MyPosthogProvider from './MyPostHogProvider';

Bugsnag.start({
  apiKey: c.BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  // appVersion: version,
  enabledReleaseStages: ['production', 'staging'],
});

let persistor = persistStore(redux.store);
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const App = ({ Component, pageProps, router }) => {
  const { isCrawler, initialFlags } = pageProps;
  const isAdminDashboard = router.pathname.startsWith('/admin');
  const socialTableContext = { initialFlags, isCrawler, isAdminDashboard };
  const pageNameCity = Component.pageNameCity;
  const pageName = Component.pageName;

  const renderLayout = () => {
    const Page = <Component {...pageProps} />;
    if (Component.getTemplate) {
      return Component.getTemplate(Page, pageProps);
    } else {
      return <PageTemplate pageProps={pageProps}>{Page}</PageTemplate>;
    }
  };

  // NOTE Returning function to PersistGate to fix bug: https://github.com/vercel/next.js/issues/8240#issuecomment-647699316
  return (
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <Provider store={redux.store}>
        <PersistGate loading={null} persistor={persistor}>
          {() => (
            <SocialTableContext.Provider value={socialTableContext}>
              <MyPosthogProvider initialFlags={initialFlags}>
                <ChakraProvider theme={chakraTheme}>
                  <AppMain pageName={pageName} pageNameCity={pageNameCity}>
                    {renderLayout()}
                  </AppMain>
                  <Analytics />
                </ChakraProvider>
              </MyPosthogProvider>
            </SocialTableContext.Provider>
          )}
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;

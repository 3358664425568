import { api, lib } from '@tlsocial/common';
import { addMinutes, compareAsc, isAfter } from 'date-fns';
import { useSelector } from 'react-redux';
import { useUniversalEvent } from './useUniversalEvent';

export const useEventHosting1 = eventStatic => {
  const { data: d_eventsHosting = {} } = api.useGetEventsHostingQuery();
  const eventHosting = d_eventsHosting[eventStatic.id];
  const event = eventHosting || eventStatic;
  return useEvent(event);
};

export const useEventHosting2 = eventStatic => {
  // Use the event from my events if it exists (Because it may have more up to date data)
  const { data: d_eventsHosting = {} } = api.apiEvents.endpoints.getEventsHosting.useQueryState();
  const eventHosting = d_eventsHosting[eventStatic?.id];
  const event = eventHosting || eventStatic;
  return useEvent(event);
};

export const useEvent = event => {
  const { user } = lib.useUser();
  const { city } = lib.useUserCity();
  const currentTime = lib.useCurrentTimeMinutes();
  const xUniversal = useUniversalEvent(event);
  const eventLikesGuest = useSelector(state => state.relations.eventLikesGuest);

  const inCurrentCity = event?.city_id === city?.id;
  const { data: eventCity1 } = api.useGetCityQuery(event?.city_id, {
    skip: !event?.city_id || inCurrentCity,
  });
  const eventCity = !inCurrentCity ? eventCity1 : null;

  if (!event) return {};

  const attendeeCurrent = xUniversal.registration;

  const isAttendee = Boolean(attendeeCurrent);

  // primary host first
  let hosts = [user];
  if (event.profiles) {
    const allHosts = event.profiles.filter(p => event.attendees[p.id]?.is_host);
    hosts = event.primaryHost ? [event.primaryHost, ...allHosts.filter(p => p.id != event.primaryHost.id)] : allHosts;
  }

  const isHostedCreating = !event.id;

  const isPrimaryHost = isHostedCreating || event.primary_host_id === user?.id;

  const isEventHostedDraft = isHostedCreating || event.event_status === lib.EVENT_STATUS.HOST_DRAFT;
  const isEventCancelled = event.event_status === lib.EVENT_STATUS.CANCELLED;
  const isEventLive = event.event_status === lib.EVENT_STATUS.LIVE;
  const isEventDone = event.event_status === lib.EVENT_STATUS.DONE;

  const themes = lib.getEventThemes(event);

  const avatarUsers = lib.getEventProfilesHidden(event, event.profiles);

  const hasStarted = isAfter(new Date(currentTime), new Date(event.time_event));
  const endTime = addMinutes(new Date(event.time_event), event.duration_minutes || 120);
  const hasEnded = isAfter(new Date(currentTime), endTime);

  const userCancelled = attendeeCurrent?.attendee_status === lib.ATTENDEE_STATUS.CANCELLED;

  const isReviewNeeded = isEventDone && attendeeCurrent?.attendee_status === lib.ATTENDEE_STATUS.ATTENDING;

  const eventRequest = {
    ...event.eventRequest,
    status: eventLikesGuest[event.id]?.status || event.eventRequest?.status,
    apply_answer: eventLikesGuest[event.id]?.apply_answer || event.eventRequest?.apply_answer,
  };

  return {
    event,
    ...xUniversal,
    eventCity,
    hosts,
    isHostedCreating,
    isPrimaryHost,
    isEventHostedDraft,
    isEventCancelled,
    isEventLive,
    isEventDone,
    themes,
    avatarUsers,
    hasStarted,
    hasEnded,
    userCancelled,
    isAttendee,
    isReviewNeeded,
    eventRequest,
  };
};

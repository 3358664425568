import React from 'react';
import { lib, api, redux } from '@tlsocial/common';
import LocationItem from '@/components/locations/LocationItem';
import Skeleton from 'react-loading-skeleton';
import { useEffect } from 'react';
import { useAsync } from 'react-use';
import { Box, Flex, GridItem, SimpleGrid, Stack, VStack, StackDivider, Text, Link, Wrap, Show } from '@chakra-ui/react';
import MyHelmet from '@/components/shared/MyHelmet';
import { H1, H2, H3 } from '@/components/shared/Headings';
import 'swiper/css';
import { WebCarousel, MobileCarousel, Carousel } from '@/components/shared/Carousel';
import { MaxContainer } from '@/components/shared/MaxContainer';
import Photo from '../shared/Photo';

const memberPhotos = [
  // lib.images.members1,
  lib.images.members2,
  lib.images.members3,
  lib.images.members4,
  lib.images.members13,
  lib.images.members10,
  lib.images.members6,
  // lib.images.members7,
  lib.images.members8,
  lib.images.members9,
  lib.images.members11,
  lib.images.members12,
];

const MemberPhoto = props => {
  const { src, ...extra } = props;
  return <Photo src={src} h="450px" w="100%" {...extra} />;
};

export const CarouselMembers = props => (
  <Carousel
    swiperId="memberPhotos"
    items={memberPhotos}
    renderItemWeb={src => <MemberPhoto src={src} rounded={0} />}
    renderItemMobile={src => <MemberPhoto src={src} rounded={10} />}
    {...props}
  />
);

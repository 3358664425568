import { constructContactsDataFromAPI, transformProfiles } from './apiConstruct';
import { tags } from './rtkTags';
import { apiSlice } from './apiSlice';

export const apiContacts = apiSlice.injectEndpoints({
  endpoints: build => ({
    getContactsData: build.query({
      query: body => ({ url: `/account/contacts/data`, method: 'PUT', body }),
      transformResponse: constructContactsDataFromAPI,
      providesTags: [tags.CONTACTS],
    }),
    getContactVouches: build.query({
      query: () => ({ url: `/contact_vouches`, method: 'GET' }),
      providesTags: [tags.CONTACT_VOUCHES],
    }),
    createContactVouch: build.mutation({
      query: body => ({ url: `/contact_vouches/create`, method: 'PUT', body }),
      invalidatesTags: [tags.CONTACT_VOUCHES],
    }),
  }),
  overrideExisting: true,
});

export const { useGetContactVouchesQuery, useGetContactsDataQuery, useCreateContactVouchMutation } = apiContacts;

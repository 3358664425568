import React, { useEffect } from 'react';
import { Box, HStack, Modal, ModalContent, ModalOverlay, Stack, Text, VStack, useClipboard } from '@chakra-ui/react';
import { useModal } from '@/hooks';
import Button from './Button';
import { H1, H2, H3 } from './Headings';

export const BaseModal = props => {
  const { isOpen, onClose, title, body, children } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Box p={7}>
          <VStack spacing={5}>
            {title && <H3 textAlign="center">{title}</H3>}
            {body && <Text textAlign="center">{body}</Text>}
            {children}
          </VStack>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export const InfoModal = ({ onClose, options }) => {
  return (
    <>
      <Button onClick={onClose} label="OK" />
    </>
  );
};

export const InvitedModal = ({ onClose, options }) => {
  const { text } = options || {};
  const { onCopy, hasCopied } = useClipboard(text);

  return (
    <Stack spacing={4}>
      <Text>{text}</Text>
      <HStack spacing={3} w="full" justify="center">
        <Button solid label={hasCopied ? 'Copied' : 'Copy'} onClick={onCopy} />
        <Button onClick={onClose} label={'Close'} />
      </HStack>
    </Stack>
  );
};

export const ConfirmModal = ({ onClose, options }) => {
  const { onConfirm, warning, isLoading } = options || {};
  const confirmText = options?.confirmText || 'Yes, Continue';
  const cancelText = options?.cancelText || 'Go Back';

  return (
    <HStack spacing={3} w="full" justify="center">
      <Button variant="ghost" onClick={onClose} label={cancelText} />
      <Button solid warning={warning} isLoading={isLoading} label={confirmText} onClick={onConfirm} />
    </HStack>
  );
};

import { lib, api, redux } from '@tlsocial/common';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const apiFetch = async path => {
  const url = `${lib.env.API_URL}${path}`;
  const response = await fetch(url);
  const result = await response.json();
  return result;
};

export const ScrollToTop = () => {
  const router = useRouter();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


import LoadPlatform from '@/components/main/LoadPlatform';
import '@/theme/styles.css';
import '../i18n.config';
import { useEffect } from 'react';

export default function App(props) {
  console.log('app');

  return <LoadPlatform {...props} />;
  // return <Component {...pageProps} />
}

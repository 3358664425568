export const ev = {
  resume: 'resume',
  nav: 'nav',

  open_modal: 'open_modal',
  open_link: 'open_link',
  open_tab: 'open_tab',

  press_leaderboard_tab: 'press_leaderboard_tab',

  codepush_restart: 'codepush_restart',
  codepush_download_restart: 'codepush_download_restart',
  codepush_download: 'codepush_download',

  app_outdated_open_link: 'app_outdated_open_link',
  app_outdated_skip: 'app_outdated_skip',
  app_notification_foreground: 'app_notification_foreground',
  app_notification_opened_app: 'app_notification_opened_app',

  chat_message_sent: 'chat_message_sent',

  // Auth
  auth_sent_phone_number: 'auth_sent_phone_number',
  auth_signed_out: 'auth_signed_out',

  // USER
  user_profile_updated: 'user_profile_updated',
  user_goals_updated: 'user_goals_updated',
  user_photo_uploaded: 'user_photo_uploaded',
  user_photo_upload_cancelled: 'user_photo_upload_cancelled',
  user_dinner_prefs_updated: 'user_dinner_prefs_updated',
  user_city_changed: 'user_city_changed',
  user_budget_updated: 'user_budget_updated',
  user_account_deleted_start: 'user_account_deleted_start',
  user_account_deleted: 'user_account_deleted',
  user_onboarding_completed: 'user_onboarding_completed',

  // Payments
  cc_add_start: 'cc_add_start',
  cc_add_success: 'cc_add_success',
  cc_deleted: 'cc_deleted',
  cc_set_primary: 'cc_set_primary',

  // POOLS
  pool_registered: 'pool_registered',
  pool_registration_updated: 'pool_registration_updated',
  pool_registration_cancelled_start: 'pool_registration_cancelled_start',
  pool_registration_cancelled: 'pool_registration_cancelled',
  pool_registration_confirmed: 'pool_registration_confirmed',

  pool_waitlist_joined: 'pool_waitlist_joined',
  pool_waitlist_cancelled_start: 'pool_waitlist_cancelled_start',
  pool_waitlist_cancelled: 'pool_waitlist_cancelled',

  // EVENTS
  event_registered: 'event_registered',
  event_cancel_attendance: 'event_cancel_attendance',
  event_cancel_attendance_start: 'event_cancel_attendance_start',

  // EVENT APPLYS
  event_apply_request: 'event_apply_request',
  event_apply_request_cancel: 'event_apply_request_cancel',
  event_apply_waitlist: 'event_apply_waitlist',
  event_apply_waitlist_cancel: 'event_apply_waitlist_cancel',
  event_apply_invite: 'event_apply_invite',
  event_apply_invite_cancel: 'event_apply_invite_cancel',

  //  INVITES

  vouch_sent: 'vouch_sent',

  user_referral_created: 'user_referral_created',

  referral_created: 'referral_created',
  referral_withdrawn: 'referral_withdrawn',
  referral_accepted: 'referral_accepted',

  pair_invite_set_accepting: 'pair_invite_set_accepting',
  pair_invite_created: 'pair_invite_created',
  pair_invite_accepted: 'pair_invite_accepted',
  pair_invite_declined: 'pair_invite_declined',
  pair_invite_withdrawn_start: 'pair_invite_withdrawn_start',
  pair_invite_withdrawn: 'pair_invite_withdrawn',

  profile_passed: 'profile_passed',
  profile_picked: 'profile_picked',
  profile_starred: 'profile_starred',
  profile_blocked: 'profile_blocked',
  profile_reported: 'profile_reported',
  profile_connection_removed: 'profile_connection_removed',
  profile_photo_zoomed: 'profile_photo_zoomed',

  open_location_search: 'open_location_search',

  location_liked: 'location_liked',
  location_unliked: 'location_unliked',
  location_search_added: 'location_search_added',

  club_joined: 'club_joined',
  club_left: 'club_left',

  topic_removed: 'topic_removed',
  topic_liked: 'topic_liked',
  topic_loved: 'topic_loved',
  topic_search_opened: 'topic_search_opened',

  tour_start: 'tour_start',
  tour_step: 'tour_step',
  tour_complete: 'tour_complete',
  tour_back: 'tour_back',
  tour_exit: 'tour_exit',

  // Errors
  error_screen: 'error_screen',
  error_screen_global: 'error_screen_global',
  error_fetch_axios: 'error_fetch_axios',
  error_fetch_custom: 'error_fetch_custom',
  error_fetch_invalid_token: 'error_fetch_invalid_token',
  error_not_authed: 'error_not_authed',
  error_timed_out: 'error_timed_out',
  error_send_phone_number: 'error_send_phone_number',
  error_add_credit_card: 'error_add_credit_card',
  error_platform_pay_start: 'error_platform_pay_start',
  error_platform_pay_confirm: 'error_platform_pay_confirm',
  error_platform_pay_complete: 'error_platform_pay_complete',
  error_registration: 'error_registration',
  error_registration_not_registered: 'error_registration_not_registered',

  web_click_button_join: 'web_click_button_join',
};

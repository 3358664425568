import { constructInvitesFromAPI } from './apiConstruct';
import { tags } from './rtkTags';
import { apiSlice } from './apiSlice';

export const apiInvites = apiSlice.injectEndpoints({
  endpoints: build => ({
    getInvite: build.query({
      query: code => `/invites/code/${code}`,
      transformResponse: constructInvitesFromAPI,
      providesTags: [tags.INVITE],
    }),
    openInvite: build.mutation({
      query: code => ({ url: `/invites/code/${code}/opened`, method: 'PUT' }),
    }),
    addSourceInvite: build.mutation({
      query: code => ({ url: `/invites/code/${code}/add_source_invite`, method: 'PUT' }),
      invalidatesTags: [tags.USER],
    }),
    getActiveReferrals: build.query({
      query: () => `/invites/referrals/active`,
      providesTags: [tags.INVITES_USER_REFERRAL],
    }),
    getPairInvites: build.query({
      query: cityId => `/invites/pair/city/${cityId}`,
      transformResponse: constructInvitesFromAPI,
      providesTags: [tags.INVITES_PAIR],
    }),
    getHostInvites: build.query({
      query: eventId => `/invites/host/${eventId}`,
      transformResponse: constructInvitesFromAPI,
      providesTags: [tags.INVITES_HOST],
    }),
    getEventInvites: build.query({
      query: eventId => `/invites/event/${eventId}`,
      transformResponse: constructInvitesFromAPI,
      providesTags: [tags.INVITES_EVENT],
    }),
    createUserLink: build.mutation({
      query: body => ({ url: `/invites/user_link/create`, method: 'PUT', body }),
      invalidatesTags: [tags.USER],
    }),
    createReferral: build.mutation({
      query: body => ({ url: `/invites/referrals/create`, method: 'PUT', body }),
      invalidatesTags: [tags.INVITES_USER_REFERRAL, tags.USER],
    }),
    createPairInvite: build.mutation({
      query: body => ({ url: `/invites/pair/${body.poolId}/create`, method: 'PUT', body }),
      invalidatesTags: [tags.INVITES_PAIR, tags.INVITE, tags.USER],
    }),
    createHostInvite: build.mutation({
      query: body => ({ url: `/invites/host/${body.event_id}/create`, method: 'PUT', body }),
      invalidatesTags: [tags.INVITES_HOST],
    }),
    createEventInvite: build.mutation({
      query: body => ({ url: `/invites/event/${body.event_id}/create`, method: 'PUT', body }),
      invalidatesTags: [tags.INVITES_EVENT],
    }),
    cancelReferral: build.mutation({
      query: body => ({ url: `/invites/code/${body.code}/cancel_referral`, method: 'PUT' }),
      invalidatesTags: [tags.INVITES_USER_REFERRAL, tags.USER],
    }),
    acceptReferral: build.mutation({
      query: body => ({ url: `/invites/code/${body.code}/accept_referral`, method: 'PUT', body }),
      invalidatesTags: [tags.USER],
    }),
    cancelPairInvite: build.mutation({
      query: body => ({ url: `/invites/code/${body.code}/cancel`, method: 'PUT', body }),
      invalidatesTags: [tags.INVITES_PAIR, tags.INVITE],
    }),
    cancelHostInvite: build.mutation({
      query: body => ({ url: `/invites/code/${body.code}/cancel`, method: 'PUT', body }),
      invalidatesTags: [tags.INVITES_HOST],
    }),
    cancelEventInvite: build.mutation({
      query: body => ({ url: `/invites/code/${body.code}/cancel`, method: 'PUT', body }),
      invalidatesTags: [tags.INVITES_EVENT],
    }),
    acceptPairInvite: build.mutation({
      query: body => ({ url: `/invites/code/${body.code}/accept`, method: 'PUT', body }),
      invalidatesTags: [tags.INVITES_PAIR, tags.INVITE, tags.USER],
    }),
    acceptHostInvite: build.mutation({
      query: body => ({ url: `/invites/code/${body.code}/accept`, method: 'PUT', body }),
      invalidatesTags: [tags.EVENTS_HOSTING, tags.EVENT_REGISTRATIONS, tags.USER],
    }),
    declinePairInvite: build.mutation({
      query: body => ({ url: `/invites/code/${body.code}/decline`, method: 'PUT', body }),
      invalidatesTags: [tags.INVITES_PAIR, tags.INVITE],
    }),
    declineHostInvite: build.mutation({
      query: body => ({ url: `/invites/code/${body.code}/decline`, method: 'PUT', body }),
      invalidatesTags: [tags.INVITE],
    }),
    declineEventInvite: build.mutation({
      query: body => ({ url: `/invites/code/${body.code}/decline`, method: 'PUT', body }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetInviteQuery,

  // Open
  useOpenInviteMutation,
  useAddSourceInviteMutation,

  // Get
  useGetActiveReferralsQuery,
  useGetPairInvitesQuery,
  useGetHostInvitesQuery,
  useGetEventInvitesQuery,

  // Create
  useCreateUserLinkMutation,
  useCreateReferralMutation,
  useCreatePairInviteMutation,
  useCreateHostInviteMutation,
  useCreateEventInviteMutation,

  // Cancel
  useCancelReferralMutation,
  useCancelPairInviteMutation,
  useCancelHostInviteMutation,
  useCancelEventInviteMutation,

  // Accept
  useAcceptReferralMutation,
  useAcceptPairInviteMutation,
  useAcceptHostInviteMutation,
  // useAcceptEventInviteMutation,

  // Decline
  useDeclinePairInviteMutation,
  useDeclineHostInviteMutation,
  useDeclineEventInviteMutation,
} = apiInvites;

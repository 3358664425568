import { lib } from '@tlsocial/common';
import React from 'react';
import { differenceInYears } from 'date-fns';

export const getFirstName = user => {
  if (user.full_name) {
    const array = user.full_name.split(' ');
    if (array.length > 1) return user.full_name.split(' ').slice(0, -1).join(' ');
    else return array[0];
  } else {
    return '';
  }
};

export const listNames = names => {
  let last = names.pop();
  return names.join(', ') + (names.length ? ' and ' : '') + last;
};

export const getMemberLevelData = profile => {
  return lib.MEMBER_TIER_DATA.find(i => i.level === profile.member_tier);
};

export const getAge = user => {
  if (user.dob) {
    return differenceInYears(new Date(), new Date(user.dob));
  }
};

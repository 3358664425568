import { lib, api, redux } from '@tlsocial/common';
import { useUser } from './useUser';

export const useCity = city => {
  const imageSizes = lib.getImageSizes(city?.image_url);
  return { city, ...imageSizes };
};

export const useCityId = cityId => {
  const { data, ...extra } = api.useGetCityQuery(cityId, {
    skip: !cityId,
    pollingInterval: lib.config.POLLING_INTERVAL,
  });

  const city = data;
  const xCity = useCity(city);

  return { ...xCity, ...extra };
};

export const useUserCity = () => {
  const { user } = useUser();
  return useCityId(user?.city_id);
};

export const useCityEventType = (city, eventType) => {

  if (!city || !eventType) {
    return null;
  }

  const typeData = lib.EVENT_TYPE_DATA[eventType];

  const cet = city.cets.find(cet => cet.event_type === eventType);

  const locationText = cet?.text_location || typeData.locationText(city?.name);

  return {
    locationText,
  };
};

import React from 'react';
import * as utils from '@/utils';
import { lib, api, redux } from '@tlsocial/common';
import { H1, H2, H3 } from './Headings';
import { Box, HStack, Text, Circle, Stack } from '@chakra-ui/react';

const HowItWorksContainer = props => {
  const { items, isDarkBg } = props;

  const bgColor = isDarkBg ? lib.colors.whiteAlpha200 : lib.colors.alpha100;

  return (
    <Box>
      <Stack spacing={4}>
        {items.map((item, index) => (
          <Box key={index}>
            <HStack align="flex-start">
              <Circle mr={3} size="40px" bg={bgColor} fontWeight="bold">
                {index + 1}
              </Circle>

              <Box align="flex-start" maxW="700px">
                <Text mb={1} mt="6px" fontWeight="bold" fontSize="18px">
                  {item.title}
                </Text>
                <Text>{item.description}</Text>
              </Box>
            </HStack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default HowItWorksContainer;

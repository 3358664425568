import { constructCityManagersFromAPI } from './apiConstruct';
import { tags } from './rtkTags';
import { apiSlice } from './apiSlice';
import { BASIC_PREFS, arrayToObject, arrayToObjectList, arrayToObjectValuesList } from '../library';

export const constructRelations = (relations, profiles) => {
  const d_profiles = arrayToObject(profiles, 'id');
  return relations.map(r => ({
    ...r,
    profile_user_from: d_profiles[r.user_from_id],
    profile_user_to: d_profiles[r.user_to_id],
  }));
};

export const constructAdminEventData = response => {
  const { events, attendees, profiles } = response;
  const d_attendees = arrayToObject(attendees, 'id');
  const d_profiles = arrayToObject(profiles, 'id');
  return { events, d_attendees, d_profiles };
};

export const apiAdmin = apiSlice.injectEndpoints({
  endpoints: build => ({
    adminGetCities: build.query({ query: () => 'admin/city' }),
    adminGetProfile: build.query({
      query: profileId => `admin/users/${profileId}`,
      providesTags: (result, error, profileId) => (profileId ? [{ type: tags.ADMIN_PROFILE, id: profileId }] : []),
    }),
    adminGetMembersRecent: build.query({
      query: cityId => `admin/users/city/${cityId}/recent`,
      providesTags: [tags.ADMIN_MEMBERS_RECENT],
    }),
    adminGetLeaderboard: build.query({
      query: ({ cityId, leaderboardType }) => `admin/users/city/${cityId}/leaderboards/${leaderboardType}`,
    }),
    adminGetApplicantsToApprove: build.query({
      query: cityId => `admin/users/city/${cityId}/applicants/to_approve`,
      providesTags: [tags.ADMIN_APPLICANTS_TO_APPROVE],
    }),
    adminGetApplicantsRecent: build.query({
      query: cityId => `admin/users/city/${cityId}/applicants/recent`,
      providesTags: [tags.ADMIN_APPLICANTS_RECENT],
    }),
    adminGetApplicantsAccepting: build.query({
      query: cityId => `admin/users/city/${cityId}/applicants/accepting`,
      providesTags: [tags.ADMIN_APPLICANTS_ACCEPTING],
    }),
    adminGetUnratedMembers: build.query({
      query: cityId => `admin/users/city/${cityId}/unrated`,
    }),
    adminSearchUsers: build.query({
      query: params => {
        const urlParams = new URLSearchParams(params).toString();
        console.log('urlParams', urlParams);
        return `admin/users/search?${urlParams}`;
      },
    }),
    adminGetEvent: build.query({
      query: eventId => `admin/events/${eventId}`,
      transformResponse: constructAdminEventData,
      providesTags: (result, error, id) => [{ type: tags.ADMIN_EVENT, id }],
    }),
    adminGetEventsUpcoming: build.query({
      query: cityId => `admin/events/city/${cityId}/upcoming`,
      transformResponse: constructAdminEventData,
      providesTags: [tags.ADMIN_EVENTS_UPCOMING],
    }),
    adminGetEventsPast: build.query({
      query: cityId => `admin/events/city/${cityId}/past`,
      transformResponse: constructAdminEventData,
      providesTags: [tags.ADMIN_EVENTS_PAST],
    }),
    adminGetMatchedEvents: build.query({
      query: cityId => `admin/events/city/${cityId}/matched`,
      transformResponse: constructAdminEventData,
      providesTags: [tags.ADMIN_EVENTS_MATCHED],
    }),
    adminGetPoolsUpcoming: build.query({
      query: cityId => `admin/pools/city/${cityId}/upcoming`,
      providesTags: [tags.ADMIN_POOLS_UPCOMING],
    }),
    adminGetPoolsPast: build.query({
      query: cityId => `admin/pools/city/${cityId}/past`,
      providesTags: [tags.ADMIN_POOLS_PAST],
    }),
    adminGetClubs: build.query({
      query: cityId => `admin/clubs/city/${cityId}`,
      providesTags: [tags.ADMIN_CLUBS],
    }),
    adminGetPool: build.query({
      query: poolId => `admin/pools/${poolId}`,
      providesTags: [tags.ADMIN_POOL],
    }),
    adminGetPoolRegistrations: build.query({
      query: poolId => `admin/registrations/pool/${poolId}`,
      providesTags: (result, error, id) => [{ type: tags.ADMIN_POOL_REGISTRATIONS, id }],
      transformResponse: response => {
        const { registrations, profiles } = response;
        const d_profiles = arrayToObject(profiles, 'id');
        const d_registrations = arrayToObject(registrations, 'user_id');
        return { d_registrations, d_profiles };
      },
    }),
    adminGetPoolEvents: build.query({
      query: poolId => `admin/events/pool/${poolId}`,
      providesTags: (result, error, id) => [{ type: tags.ADMIN_POOL_EVENTS, id }],
      transformResponse: constructAdminEventData,
    }),
    adminGetUserChart: build.query({
      query: ({ cityId, params }) => {
        return { url: `admin/city/${cityId}/user_chart`, params };
      },
    }),
    adminGetAttendeeChart: build.query({
      query: ({ cityId, params }) => {
        return { url: `admin/city/${cityId}/attendee_chart`, params };
      },
    }),
    adminGetReferralsChart: build.query({
      query: ({ cityId, params }) => {
        return { url: `admin/city/${cityId}/referrals_chart`, params };
      },
    }),
    adminGetRatingsChart: build.query({
      query: ({ cityId, params }) => {
        return { url: `admin/city/${cityId}/ratings_chart`, params };
      },
    }),
    adminIsCityManager: build.query({
      query: cityId => `admin/city/${cityId}/is_city_manager`,
    }),
    adminGetCityManagers: build.query({
      query: () => `admin/city_managers`,
      transformResponse: constructCityManagersFromAPI,
    }),
    adminGetLogs: build.query({
      query: ({ objType, objId }) => `admin/logs/${objType}/${objId}`,
      providesTags: (result, error, { objType, objId }) => [{ type: tags.ADMIN_LOGS, objType, objId }],
    }),
    adminGetAllLocations: build.query({
      query: cityId => `admin/locations/city/${cityId}/all`,
      providesTags: [tags.ADMIN_ALL_LOCATIONS],
    }),
    adminGetFeaturedLocations: build.query({
      query: cityId => `admin/locations/city/${cityId}/featured`,
      providesTags: [tags.ADMIN_FEATURED_LOCATIONS],
    }),
    adminGetPoolLocationsData: build.query({
      query: poolId => `admin/locations/pool/${poolId}/data`,
      providesTags: (result, error, id) => [{ type: tags.ADMIN_POOL_LOCATIONS_DATA, id }],
    }),
    adminGetEventLocationsData: build.query({
      query: eventId => `admin/locations/event/${eventId}/data`,
      providesTags: (result, error, id) => [{ type: tags.ADMIN_EVENT_LOCATIONS_DATA, id }],
    }),
    adminGetReservations: build.query({
      query: cityId => `admin/reservations/city/${cityId}`,
      providesTags: [tags.ADMIN_RESERVATIONS],
    }),
    adminGetPoolReservations: build.query({
      query: poolId => `admin/reservations/pool/${poolId}`,
      providesTags: [tags.ADMIN_POOL_RESERVATIONS],
    }),
    adminGetPoolRelations: build.query({
      query: poolId => `admin/relations/pool/${poolId}`,
    }),
    adminGetEventRelations: build.query({
      query: eventId => `admin/relations/event/${eventId}`,
    }),
    adminGetProfileAttendees: build.query({
      query: profileId => `admin/attendees/user/${profileId}`,
    }),
    adminGetReviews: build.query({
      query: cityId => `admin/attendees/city/${cityId}/reviews`,
    }),
    adminGetPoolReviews: build.query({
      query: poolId => `admin/attendees/pool/${poolId}/reviews`,
    }),
    adminGetEventReviews: build.query({
      query: eventId => `admin/attendees/event/${eventId}/reviews`,
    }),
    adminGetUserReviews: build.query({
      query: userId => `admin/attendees/user/${userId}/reviews`,
    }),
  }),

  overrideExisting: true,
});

export const {
  useAdminGetCitiesQuery,
  useAdminGetProfileQuery,
  useAdminGetMembersRecentQuery,
  useAdminGetLeaderboardQuery,
  useAdminGetApplicantsToApproveQuery,
  useAdminGetApplicantsRecentQuery,
  useAdminGetApplicantsAcceptingQuery,
  useAdminGetUnratedMembersQuery,
  useAdminSearchUsersQuery,
  useAdminGetEventQuery,
  useAdminGetEventsUpcomingQuery,
  useAdminGetEventsPastQuery,
  useAdminGetMatchedEventsQuery,
  useAdminGetPoolsUpcomingQuery,
  useAdminGetPoolsPastQuery,
  useAdminGetPoolQuery,
  useAdminGetPoolRegistrationsQuery,
  useAdminGetPoolAttendeesQuery,
  useAdminGetPoolEventsQuery,
  useAdminGetClubsQuery,
  useAdminGetUserChartQuery,
  useAdminGetAttendeeChartQuery,
  useAdminGetReferralsChartQuery,
  useAdminGetRatingsChartQuery,
  useAdminIsCityManagerQuery,
  useAdminGetCityManagersQuery,
  useAdminGetLogsQuery,
  useAdminGetAllLocationsQuery,
  useAdminGetFeaturedLocationsQuery,
  useAdminGetReservationsQuery,
  useAdminGetPoolReservationsQuery,
  useAdminGetPoolRelationsQuery,
  useAdminGetEventRelationsQuery,
  useAdminGetPoolLocationsDataQuery,
  useAdminGetEventLocationsDataQuery,
  useAdminGetProfileAttendeesQuery,
  useAdminGetReviewsQuery,
  useAdminGetPoolReviewsQuery,
  useAdminGetEventReviewsQuery,
  useAdminGetUserReviewsQuery,
} = apiAdmin;

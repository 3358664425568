import React from 'react';
import * as c from '@/constants/envConstants';
import { lib, api, redux } from '@tlsocial/common';
import Skeleton from 'react-loading-skeleton';
import { AspectRatio, Box, Flex, HStack, Image, Show, Stack, Text, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { LinkArea, MagicLink } from '@/components/shared/Links';
import { links, linksTo } from '@/constants';
import * as utils from '@/utils';
import Photo from '../shared/Photo';
import { Avatar } from '../people/Avatar';

export const UniEventItems = props => {
  const { uniEvents, city, isHorizontal = true } = props;
  return (
    <Stack spacing={5} align="stretch" direction={isHorizontal ? 'row' : 'column'}>
      {uniEvents.map((item, i) => (
        <UniEventItem
          key={`${item.is_hosted ? 'proposal' : 'pool'}_${item.id}`}
          isHorizontal={isHorizontal}
          uniEvent={item}
          city={city}
        />
      ))}
    </Stack>
  );
};

export const UniEventItem = props => {
  const { uniEvent, inviteCode, isHorizontal, ...extra } = props;
  const xUniversal = lib.useUniversalEvent(uniEvent);
  const linkRoute = uniEvent.is_pool ? links.POOL(uniEvent) : links.EVENT(uniEvent);
  const fullLink = inviteCode ? `${linkRoute}?inviteCode=${inviteCode}` : linkRoute;
  return (
    <LinkArea
      to={fullLink}
      h="full"
      bg={lib.colors.alpha50}
      hoverColor={lib.colors.alpha100}
      rounded="10"
      overflow="hidden"
      {...extra}>
      <Flex direction={isHorizontal ? 'column' : 'row'}>
        <Box bg="gray.200" w={isHorizontal ? 'full' : ['120px', '180px']}>
          {xUniversal.imageLarge && (
            <Image
              w={'full'}
              h={isHorizontal ? '300px' : ['150px', '180px']}
              objectFit="cover"
              src={xUniversal.imageLarge}
              alt={uniEvent.title || 'Event Image'}
            />
          )}
        </Box>
        <Stack flex="1" px={5} py={5} spacing={'8px'}>
          <Text fontSize={20} fontWeight="bold">
            {uniEvent.title}
          </Text>
          <Text fontSize={14} color={lib.colors.primary500} mt="2px" fontWeight="bold">
            {xUniversal.cityTime && lib.fTime3(xUniversal.cityTime)}
          </Text>
          {uniEvent.primaryHost && (
            <Flex>
              <Avatar size={25} profile={uniEvent.primaryHost} marginRight={2} />
              <Text fontSize={14}>Hosted by {uniEvent.primaryHost.full_name}</Text>
            </Flex>
          )}
          {uniEvent.description && (
            <Text fontSize={15} noOfLines={2} maxW="600px" color="blackAlpha.700">
              {uniEvent.description}
            </Text>
          )}
        </Stack>
      </Flex>
    </LinkArea>
  );
};

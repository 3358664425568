import { lib, api, redux } from '@tlsocial/common';
import { useRouter } from 'next/router';

const GLOBAL_CITY = { id: 0, city_key: 'global', name: 'Global' };

export const useAdminCity = () => {
  const router = useRouter();
  const { cityKey } = router.query;
  const isGlobalCity = cityKey === 'global';
  const skip = !cityKey || isGlobalCity;
  const { data: fetchedCity, isLoading: isLoadingCity } = api.useGetCityFromKeyQuery(cityKey, { skip });
  const city = isGlobalCity ? GLOBAL_CITY : fetchedCity || {};

  const { data: isCityManager } = api.useAdminIsCityManagerQuery(city?.id, { skip: city?.id === undefined });

  return {
    city,
    isCityManager,
    isLoadingCity,
  };
};

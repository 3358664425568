import { lib } from '@tlsocial/common';
import { apiSlice } from '@tlsocial/common/api';
import { store } from '@tlsocial/common/redux';

const endpoints = apiSlice.endpoints;
const dispatch = store.dispatch;

export async function getLocationPaths() {
  return { paths: [], fallback: 'blocking' };
  // const result = await dispatch(endpoints.getLocationsSSR.initiate());
  // const locations = result.data;
  // const paths = locations.map(location => ({
  //   params: { cityKey: location.city_key, slug: lib.toSlug(location.name, location.id) },
  // }));
  // return { paths, fallback: 'blocking' };
}

export async function getActiveCityPaths() {
  const result = await dispatch(endpoints.getActiveCitiesSSR.initiate());
  const cities = result.data;
  const paths = cities.map(city => ({
    params: { cityKey: city.city_key },
  }));
  return { paths, fallback: 'blocking' };
}

export async function getAllCityPaths() {
  const result = await dispatch(endpoints.getAllCitiesSSR.initiate());
  const cities = result.data;
  const paths = cities.map(city => ({
    params: { cityKey: city.city_key },
  }));
  return { paths, fallback: 'blocking' };
}

export async function getPoolPaths() {
  return { paths: [], fallback: 'blocking' };
}

export async function getEventPaths() {
  return { paths: [], fallback: 'blocking' };
}

export async function getClubPaths() {
  return { paths: [], fallback: 'blocking' };
}

export async function getLocationsPaths() {
  return { paths: [], fallback: 'blocking' };
}

export async function getUpcomingPaths() {
  return { paths: [], fallback: 'blocking' };
}

export async function getClubsPaths() {
  return { paths: [], fallback: 'blocking' };
}

export async function getPathsEmpty() {
  return { paths: [], fallback: 'blocking' };
}

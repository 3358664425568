// import { lib } from '@tlsocial/common';

import { MEMBER_TIER } from './libBackendConstants';
import { colors } from './libColors';

export const MEMBER_TIER_DATA = [
  {
    level: MEMBER_TIER.GUEST,
    color: colors.memberLevelGuest,
    title: 'Guest',
    badge: 'Guest',
    unlocks: ['Join any events you have an invite to.'],
  },
  {
    level: MEMBER_TIER.MEMBER,
    color: colors.memberLevelMember,
    title: 'Member',
    badge: 'Member',
    unlocks: ['Join our members-only event pools.', 'Receive invitations to members-only events.'],
  },
  {
    level: MEMBER_TIER.HOST,
    color: colors.memberLevelHost,
    title: 'Host',
    badge: 'Host',
    unlocks: [`Host events where you pick the theme, location, and guest list.`],
  },
  {
    level: MEMBER_TIER.AMBASSADOR,
    color: colors.memberLevelAmbassador,
    title: 'Ambassador',
    badge: 'Ambassador',
    unlocks: [`Enjoy exclusive ambassador events and perks (TBD)`],
  },
];


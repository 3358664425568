import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  suggestedPending: [],
  suggestedRated: [],
  tempUndoRating: null,
  countUndone: 0,
};

const citySuggestedSlice = createSlice({
  name: 'citySuggested',
  initialState,
  reducers: {
    resetSuggestedState: () => {
      console.log('resetSuggestedState');
      return initialState;
    },
    swipeUpdate: (state, { payload }) => {
      state.suggestedPending = state.suggestedPending.filter(u => u.id != payload.profile.id);
      state.suggestedRated = [payload, ...state.suggestedRated.filter(sr => sr.profile.id !== payload.profile.id)];
      state.countUndone = Math.max(0, state.countUndone - 1);
    },
    undoSwipe: (state, { payload }) => {
      const { profile, rating } = state.suggestedRated[0];
      state.suggestedPending = [profile, ...state.suggestedPending.filter(u => u.id != profile.id)];
      state.suggestedRated = [...state.suggestedRated.slice(1)];
      state.tempUndoRating = rating;
      state.countUndone = state.countUndone + 1;
    },
    resetUndoRating: state => {
      state.tempUndoRating = null;
    },
    loadSuggested: (state, { payload }) => {
      const newProfiles = payload;
      const keepCount = Math.max(1, state.countUndone);
      const keepProfiles = state.suggestedPending.slice(0, keepCount);
      const keepIds = keepProfiles.map(p => p.id);
      state.suggestedPending = [...keepProfiles, ...newProfiles.filter(p => !keepIds.includes(p.id))];
    },
    
  },
});

export const { resetSuggestedState, swipeUpdate, undoSwipe, resetUndoRating, loadSuggested } =
  citySuggestedSlice.actions;

export default citySuggestedSlice.reducer;

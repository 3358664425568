import { useDispatch, useSelector } from 'react-redux';
import { lib, api, redux } from '@tlsocial/common';

export const useUser = () => {
  const [updateUser] = api.useUpdateCurrentUserMutation();
  const appToken = useSelector(state => state.local.appToken);
  const { data: user, ...extra } = api.useGetUserQuery(undefined, {
    skip: !appToken,
    pollingInterval: lib.config.POLLING_INTERVAL,
  });

  return { user, updateUser, ...extra };
};

export const useUserData = () => {
  const { user } = useUser();
  const appToken = useSelector(state => state.local.appToken);
  const { data, ...extra } = api.useGetUserDataQuery(undefined, {
    skip: !appToken,
    pollingInterval: lib.config.POLLING_INTERVAL,
  });

  const userTopics = Object.values(data?.d_userTopics || {}).filter(t => !t.is_for_club);
  const userClubTopics = Object.values(data?.d_userTopics || {}).filter(
    t => t.is_for_club && t.level === lib.TOPIC_LEVELS.LOVE,
  );
  const topicSets = {
    clubTopics: new Set(userClubTopics.map(g => g.id)),
    selected: new Set(userTopics.map(g => g.id)),
    love: new Set(lib.getTopicsLove(userTopics).map(g => g.id)),
    like: new Set(lib.getTopicsLike(userTopics).map(g => g.id)),
  };

  const proffessionalGoalId = lib.env.IS_PRODUCTION ? 14 : 14;
  const hasProfessionalGoal = user?.attribute_ids[lib.ATTRIBUTE_TYPES.GOALS]?.includes("14");

  return { ...data, userTopics, userClubTopics, topicSets, hasProfessionalGoal, ...extra };
};

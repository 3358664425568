import { Box, Center, Flex, HStack, IconButton, Img, Show, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { MaxContainer, MaxContentContainer } from '@/components/shared/MaxContainer';
import { Modal } from '@/components/shared/Modals';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { ButtonsMenu, DropdownMenu } from '@/components/shared/Menus';
import { Link, LinkArea } from '@/components/shared/Links';
import Button from '@/components/shared/Button';
import { links } from '@/constants';
import { useTranslation } from 'react-i18next';
import Icon from '../shared/Icon';
import { lib } from '@tlsocial/common';
import { H1 } from '../shared/Headings';
import Photo from '../shared/Photo';
import { useTypeformLink } from '@/hooks/useTypeformLink';
import HomeFeature from './HomeFeature';

const HomeFeatures = props => {
  const { city } = props;
  const typeformUrl = useTypeformLink(city);

  return (
    <>
      <HomeFeature {...lib.WHY_POINTS[0]} />
      <HomeFeature {...lib.WHY_POINTS[1]} reverse={true} />
      <HomeFeature {...lib.WHY_POINTS[2]} />

      {/* <HomeFeature title="Apply to Join" reverse={true}>
        <Button solid size="lg" href={typeformUrl} label="Apply" mt={5} />
      </HomeFeature> */}
    </>
  );
};

const styles = {};

export default HomeFeatures;

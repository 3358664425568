import { useDispatch, useSelector } from 'react-redux';
import { lib, api, redux } from '@tlsocial/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useClub = (city, club, context) => {
  const { trackClub, ev } = lib.useAnalytics();
  const [rateClub] = api.useRateClubMutation();
  const { d_userTopics = {} } = lib.useUserData();

  if (!club) {
    return null;
  }

  const levelSelected = d_userTopics[club.topic_id]?.level ?? lib.TOPIC_LEVELS.NONE;
  const isMember = levelSelected !== lib.TOPIC_LEVELS.NONE;
  const memberCount = club.u_all > 0 ? club.u_all + (isMember ? 1 : 0) : 0;

  const onToggleClub = () => {
    const level = isMember ? lib.TOPIC_LEVELS.NONE : lib.TOPIC_LEVELS.LOVE;
    rateClub({ id: club.topic_id, level, context });
    trackChange(level);
  };

  const trackChange = level => {
    if (level === lib.TOPIC_LEVELS.NONE) trackClub(club, ev.club_left);
    else if (level === lib.TOPIC_LEVELS.LIKE) trackClub(club, ev.club_joined);
    else if (level === lib.TOPIC_LEVELS.LOVE) trackClub(club, ev.club_joined);
  };

  const imageSizes = lib.getImageSizes(club.image_url);
  const avatarSizes = lib.getImageSizes(club.avatar_url);
  const slug = lib.toSlug(club.name, club.id);
  const name = lib.substitutePlaceholders(club.name, { '{city}': city?.name });
  const description = lib.substitutePlaceholders(club.description, { '{city}': city?.name });

  return {
    club,
    slug,
    name,
    description,
    ...imageSizes,
    avatars: avatarSizes,
    isMember,
    onToggleClub,
    memberCount,
  };
};

export const useClubs = cityId => {
  const { data: clubs = [], isLoading } = api.useGetCityClubsQuery(cityId, { skip: !cityId });
  const recommendedClubs = clubs.filter(club => club.is_recommended);
  const otherClubs = clubs.filter(club => !club.is_recommended);
  return { clubs, recommendedClubs, otherClubs, isLoading };
};

// export const useProfileClubs = profile => {
//   const { user } = lib.useUser();
//   const { city } = lib.useUserCity();
//   const { userClubTopics, topicSets } = lib.useUserData();

//   const profileTopics = user.id === profile.id ? userClubTopics : profile.user_topics;
//   const profileTopicIds = profileTopics.map(t => t.id);

//   const { data: cityClubs = [], isLoading } = api.useGetCityClubsQuery(city?.id, { skip: !city?.id });

//   const clubs = cityClubs
//     .filter(club => profileTopicIds.includes(club.topic_id))
//     .sort((a, b) => {
//       const aIndex = topicSets.clubTopics.has(a.topic_id) ? 0 : 1;
//       const bIndex = topicSets.clubTopics.has(b.topic_id) ? 0 : 1;
//       if (aIndex === bIndex) {
//         return a.name < b.name ? -1 : 1;
//       } else {
//         return aIndex > bIndex ? 1 : -1;
//       }
//     });

//   // console.log('useProfileClubs', userClubTopics, profileTopicIds, clubs);
//   return clubs;
// };

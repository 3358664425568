import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from '../library';
import { tags } from './rtkTags';
import { resetAllState } from '../redux/localSlice';
import { store } from '../redux/store';
import { toastError } from '../platform/initPlatform';
import { lib } from '..';

const HYDRATE = '__NEXT_REDUX_WRAPPER_HYDRATE__'; // Normally import { HYDRATE } from 'next-redux-wrapper';

const handleCustomAPIError = responseData => {
  console.log('handleCustomAPIError', responseData);
  const { error_message } = responseData;
  if (error_message && error_message.includes('Invalid token')) {
    // // If there was an invalid token it's a special case
    // Commenting this because we'd also need to signout from Firebase
    // store.dispatch(resetAllState());
    // if (env.IS_REACT_NATIVE) {
    // Signout from Firebase
    // }

    // track(ev.error_fetch_invalid_token);
    toastError('Invalid login. Please sign in again.');
  } else {
    // track(ev.error_fetch_custom);
    toastError(error_message);
  }
  // Need to send the data back through redux.
  // So, for example, we could indicate which form fields had errors
  // return Promise.reject(responseData);
};

const bq = fetchBaseQuery({
  baseUrl: `${env.API_URL}`,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().local.appToken;
    if (token) {
      headers.set('authorization', `Token ${token}`);
    }
    return headers;
  },
});

const customBaseQuery = async (...args) => {
  const result = await bq(...args);

  // console.log('customBaseQuery', result);
  if (result.error) {
    if (result.error.data?.has_error) {
      handleCustomAPIError(result.error.data);
    } else if (result.error?.status === 'FETCH_ERROR') {
      // lib.errorString('FETCH_ERROR');
      console.log('FETCH_ERROR', result.error);
      toastError('Network error');
    } else {
      lib.errorString(JSON.stringify(result.error));
      console.log('Unknown server error', result.error);
      toastError('Unknown server error');
    }
    return result;
  } else {
    return { ...result, data: result.data.data };
  }
};

export const apiSlice = createApi({
  reducerPath: 'api',
  keepUnusedDataFor: 60,
  baseQuery: customBaseQuery,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  tagTypes: Object.values(tags),
  endpoints: build => ({}),
});

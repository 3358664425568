import { Box } from '@chakra-ui/react';
import * as React from 'react';
import Button from '@/components/shared/Button';
import { lib } from '@tlsocial/common';
import { useVariantString, useIsClient } from '@/hooks';
import { useStoreUrl } from './AppStoreLinks';
import { links } from '@/constants';

export const GlowButton = props => {
  return (
    <Box>
      <Box bg={lib.colors.whiteAlpha100} p={'2px'} rounded={'50px'} m={'-8px'}>
        <Box bg={lib.colors.whiteAlpha100} p={'2px'} rounded={'50px'}>
          <Box bg={lib.colors.whiteAlpha100} p={'2px'} rounded={'50px'}>
            <Box bg={lib.colors.whiteAlpha100} p={'2px'} rounded={'50px'}>
              <Button solid size="lg" minW="200px" h="50px" px="40px" fontWeight={900} {...props} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const ButtonJoin = props => {
  const { city, glow, label, ...extra } = props;
  const isClient = useIsClient();
  const appStoreUrl = useStoreUrl();
  const downloadLink = city ? links.CITY_JOIN(city) : links.JOIN;
  const ctaLinkProps = isClient && appStoreUrl ? { href: appStoreUrl } : { to: downloadLink };

  const joinLabel = useVariantString(lib.flags.web_join_button);

  const label2 = label || (city && !city.active ? 'Join the Waitlist' : joinLabel);

  const ButtonComponent = glow ? GlowButton : Button;

  return (
    <ButtonComponent solid label={label2} myClickEvent={lib.ev.web_click_button_join} {...ctaLinkProps} {...extra} />
  );
};

export let toastError;

export const initPlatform = params => {
  toastError = params.toastError;
};

export const images = {
  above: '/assets/img/above.jpeg',
  blankProfile: '/assets/img/blank-profile.png',
  hiddenProfile: '/assets/img/blank-profile.png',
  logoTransparentWhite: '/assets/img/logo-transparent-white.png',
  logoChat: '/assets/img/logo-chat-150.png',
  textLogo: '/assets/img/text-logo.png',
  whyLive: '/assets/img/why-live.jpg',
  whySerendipity: '/assets/img/why-serendipity.jpg',
  whyConnection: '/assets/img/why-connection.jpg',
  googlePayMark: '/assets/img/google-pay-mark.png',
  applePayMark: '/assets/img/apple-pay-mark.png',
  hostTopic: '/assets/img/host-topic.jpg',
  hostCurate: '/assets/img/host-curate.png',
  hostCommunity: '/assets/img/host-community.jpg',
  members1: '/assets/img/members1.jpg',
  members2: '/assets/img/members2.jpg',
  members3: '/assets/img/members3.jpg',
  members4: '/assets/img/members4.jpg',
  members6: '/assets/img/members6.jpg',
  members7: '/assets/img/members7.jpg',
  members8: '/assets/img/members8.jpg',
  members9: '/assets/img/members9.jpg',
  members10: '/assets/img/members10.jpg',
  members11: '/assets/img/members11.jpg',
  members12: '/assets/img/members12.jpg',
  members13: '/assets/img/members13.jpg',
};
